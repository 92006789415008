import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
} from "../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../Loading/Loading";
import { InputNumber, Senhas } from "../../Parametros/styledParametros";
import { ButtonSalvarSenha } from "../../Home/HomeRoutes/Perfil/styledPerfil";
import { ButtonEncerrar } from "../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable";
import Select from "react-select";
import ConfirmarArquivar from "../ModalArquivar/ConfirmarArquivar";

function EditarUsuario({ closeModal, USR_ID, alteracaoFeita }) {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(true);

  const [ven, setVen] = useState([]);
  const [nomeUser, setNomeUser] = useState("");
  const [loginUser, setLoginUser] = useState("");
  const [perId, setPerId] = useState("");
  const [VEN_DADOS, setVEN_DADOS] = useState({});
  const [mudarSenha, setMudarSenha] = useState(false);
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmaNovaSenha, setConfirmaNovaSenha] = useState("");
  const [usrStatus, setUsrStatus] = useState(Boolean);
  const [modalArquivar, setModalArquivar] = useState(false);
  const [selectVendedores, setSelectVendedores] = useState(false);

  const DADOS = {
    USR_ID,
    USR_NOME: nomeUser,
    USR_LOGIN: loginUser,
    ALTERAR_SENHA: mudarSenha,
    USR_SENHA: novaSenha,
    VEN_ID: VEN_DADOS.value?.toString(),
    PER_ID: Number(perId)
  };

  function erroAlteracao(msg) {
    toast.error(`${msg}`, {
      position: "top-right",
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    axios
      .get(`https://kaminocrm.com.br:5001/usuario/buscarUsuario/${USR_ID}`, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // console.log(res);
        // console.log(USR_ID);
        setNomeUser(res.data.USR_NOME);
        setLoginUser(res.data.USR_LOGIN);
        setUsrStatus(res.data.USR_ATIVO);
        setVEN_DADOS({
          value: res.data.VEN_ID,
          label: res.data.VEN_NOME
        })
        setPerId(res.data.PER_ID.toString());
        if (res.data.PER_ID.toString() == "0") {
          setSelectVendedores(true)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    (async () => {
      axios
        .get(`https://kaminocrm.com.br:7778/vendedores/buscarVendedores`, {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        })
        .then((res) => {
          var dataSelect = res.data.map((el) => {
            return {
              value: el.id,
              label: el.nome,
            };
          });
          setVen(dataSelect);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, []);

  const handleSubmit = () => {
    console.log(DADOS)
    axios
      .post(`https://kaminocrm.com.br:5001/usuario/updateUsuarioV2`, DADOS, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        closeModal(false);
        alteracaoFeita();
      })
      .catch((err) => {
        console.log(err);
        const { data } = err.response
        setLoading(false);
        erroAlteracao(data.statusPersonalizado == 401 ? data.message : "Ops! Algo deu errado");
      });
  };


  function checarCampos() {
    if (nomeUser.length === 0 || loginUser.length === 0) {
      return true;
    } else if (mudarSenha && novaSenha.length === 0) {
      return true;
    } else if (novaSenha !== confirmaNovaSenha) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {modalArquivar && (
        <ConfirmarArquivar
          closeModal={setModalArquivar}
          USR_ID={USR_ID}
          USR_LOGIN={loginUser}
          USR_ATIVO={false}
          alteracaoFeita={alteracaoFeita}
        />
      )}

      <BackgroundModal>
        <ModalBoxCliente>
          {loading && <Loading />}
          <ModalHeader>
            <h2>Editar usuário</h2>
            <X onClick={() => closeModal(false)}>
              <FaTimes size={17} />
            </X>
          </ModalHeader>

          <div className="modalBody">
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <div style={{ width: "100%" }}>
                <label htmlFor="nome">Nome de usuário:</label>
                <InputNumber
                  style={{ display: "block", width: "95%", margin: "5px 0" }}
                  disabled={usrStatus ? false : true}
                  type="text"
                  name="nome"
                  value={nomeUser}
                  onChange={(e) => setNomeUser(e.target.value)}
                />
              </div>

              <div style={{ width: "100%" }}>
                <label htmlFor="nome">Login:</label>
                <InputNumber
                  style={{ display: "block", width: "95%", margin: "5px 0" }}
                  disabled={usrStatus ? false : true}
                  type="text"
                  name="nome"
                  value={loginUser}
                  onChange={(e) => setLoginUser(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label style={{ display: "block", margin: "10px 0 5px 0" }}>
                Perfil de Usuário:
              </label>

              <input
                type="radio"
                value="0"
                checked={perId === "0"}
                name="PER_ID"
                onChange={(e) => {
                  setPerId(e.target.value);
                  setSelectVendedores(true);
                }}
              />
              <label>VENDEDOR</label>

              <input
                type="radio"
                value="1"
                checked={perId === "1"}
                name="PER_ID"
                onChange={(e) => {
                  setPerId(e.target.value);
                  setSelectVendedores(false);
                }}
              />
              <label>MASTER</label>

              {selectVendedores && (
                <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", gap: "3px" }}>
                  <label htmlFor="vendedorid">Vendedores disponíveis:</label>
                  <Select
                    options={ven}
                    value={VEN_DADOS}
                    placeholder="Selecione..."
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue
                        ? "Todas as opções foram selecionadas"
                        : "Nenhum vendedor encontrado"
                    }
                    onChange={(e) =>
                      setVEN_DADOS({
                        label: e.label,
                        value: e.value,
                      })
                    }
                  />
                </div>
              )}
            </div>

            <div style={{ display: "block", margin: "20px 0 15px 0" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  style={{ marginRight: "4px" }}
                  disabled={usrStatus ? false : true}
                  value={mudarSenha}
                  onChange={() => {
                    setMudarSenha(!mudarSenha);
                  }}
                  type="checkbox"
                  checked={mudarSenha ? true : false}
                  name="mudar_senha"
                  id="mudar_senha"
                />
                <label htmlFor="mudar_senha">
                  Deseja mudar a senha deste usuário?
                </label>
              </div>
            </div>

            {mudarSenha && (
              <Senhas>
                <div className="item_senha">
                  <label htmlFor="">Nova senha:</label>
                  <InputNumber
                    style={{ width: "95%", margin: "0" }}
                    type="text"
                    value={novaSenha}
                    onChange={(e) => setNovaSenha(e.target.value)}
                  />
                </div>
                <div className="item_senha">
                  <label htmlFor="">Confirmar nova senha:</label>
                  <InputNumber
                    style={{ width: "95%", margin: "0" }}
                    type="text"
                    value={confirmaNovaSenha}
                    onChange={(e) => setConfirmaNovaSenha(e.target.value)}
                  />
                </div>
              </Senhas>
            )}

            <div
              style={{
                padding: "12px 0",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ButtonEncerrar
                  onClick={() => {
                    // setLoading(true)
                    // setUsrStatus(!usrStatus)
                    setModalArquivar(true);
                  }}
                >
                  Arquivar usuário
                </ButtonEncerrar>
              </div>

              <ButtonSalvarSenha
                disabled={checarCampos()}
                onClick={() => {
                  setLoading(true);
                  handleSubmit();
                }}
              >
                Salvar
              </ButtonSalvarSenha>
            </div>
          </div>
        </ModalBoxCliente>
      </BackgroundModal>
    </>
  );
}

export default EditarUsuario;
