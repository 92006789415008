import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment";
import { FaUserAlt } from "react-icons/fa";
import { HiClipboard, HiClipboardCheck } from "react-icons/hi";
import { BiInfoCircle } from "react-icons/bi";
import { handleVistoriasId } from "../../../../../services/vistoriasId";
import { FaCarSide } from "react-icons/fa";
// import { CadastroAssociado } from "../../../../../context/CadastroASSContext";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const DraggableItem = ({ el, index, OptionsButton, setOpenModal, setDataResumo, mask }) => {
  function idColor (element) {
    if (element.OPO_STATUS === 'PENDENTE') {
      if (!element.VEI_PLACA || !element.PLANOS) {
        return 'incompleto'
      }
    } else {
      return element.OPO_STATUS.toLowerCase()
    }
  }
    return (
        <Draggable
          key={el.CLI_ID}
          index={index}
          draggableId={`${el.CLI_ID}`}
        >
          {(provided, snapshot) => {
            // idColor(el)
            return (
              <>
                <div className="btn-wrapper">
                  <div
                    className="options-btn"
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                    }}
                  >
                    <OptionsButton el={el} />
                  </div>
                </div>

                <div
                  className={`card item ${
                    snapshot.isDragging && "dragging"
                  }`}
                  ref={provided.innerRef}
                  id={idColor(el)}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onClick={() => {
                    setOpenModal(true);
                    setDataResumo(el.OPO_ID);
                  }}
                >
                  <span className="item" id="nome">
                    <p className="nome_span">
                      {el.CLI_NOME.toUpperCase()}
                    </p>
                  </span>

                  <span className="item">
                    {el.CLI_TELEFONE
                      ? mask(el.CLI_TELEFONE)
                      : "Não informado."}
                  </span>

                  {el.VEI_PLACA && (
                    <span className="item">
                      {el.VEI_PLACA} |{" "}
                      {el.VEI_ANO_MODELO}
                    </span>
                  )}
                  {el.VEI_MODELO && (
                    <span
                      className="item"
                      id="modelo"
                    >
                      {el.VEI_MODELO}
                    </span>
                  )}
                  <div className="bottom">
                    <span id="vendedor">
                      <span
                        style={{
                          marginRight: "7px",
                        }}
                      >
                        <FaUserAlt size={11} />
                      </span>
                      <span>{el.VEN_NOME}</span>
                    </span>

                    <div className="under-bottom">
                      <span id="data-criacao">{moment(el.OPO_DATA_CADASTRO).format("DD/MM/YYYY")}</span>

                      <div className="icons">
                        {el?.TEM_ID &&
                         <Tippy
                         delay="275"
                         content={
                           el?.TEM_ID
                             ? `VISTORIA ${handleVistoriasId(el?.TEM_ID)}`
                             : ""
                         }
                         placement="bottom"
                       >
                         <span id="origem">
                         <FaCarSide size={17} />
                         </span>
                       </Tippy>}
                      <Tippy
                        delay="275"
                        content={
                          el.ORG_DESCRICAO
                            ? `ORIGEM: ${el.ORG_DESCRICAO}`
                            : "Sem origem definida"
                        }
                        placement="bottom"
                      >
                        <span id="origem">
                          <BiInfoCircle size={16} />
                        </span>
                      </Tippy>

                      {el.TAREFAS ? (
                        <Tippy
                          delay="275"
                          content={`${el.TAREFAS[0]}`}
                          placement="bottom"
                        >
                          <span
                            id="tarefa"
                            style={{
                              color: "#4E8EF1",
                            }}
                          >
                            <HiClipboardCheck
                              size={17}
                            />
                          </span>
                        </Tippy>
                      ) : (
                        <span
                          id="tarefa"
                          style={{ color: "#515c83" }}
                        >
                          <HiClipboard size={16} />
                        </span>
                      )}
                      </div>
                      
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Draggable>
      );
}

export default DraggableItem;