import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "../../colors";

export const ContainerConfig = styled.ul`
  position: absolute;
  right: 40px;
  top: 7px;
  width: 165px;
  max-height: fit-content;
  padding: 10px 5px;
  background-color: ${COLORS.light};
  border-radius: 4px;
  box-shadow: 1px 0px 7px #271c1c3f;
  text-align: center;
  list-style-type: none;
`;

export const ConfigOpcao = styled.li`
  padding: 10px 0px;
  margin: 0px 15px;

  :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const ConfigLink = styled(Link)`
  color: ${COLORS.labelDark};
  font-weight: bolder;
  text-decoration: none;

  :hover {
    color: ${COLORS.dark};
  }
`;
