import React, { useState, useEffect } from "react";
import axios from "axios";
import { KanbanWrapper } from "./style/styledKanban";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import moment from "moment";
import Loading from "../../../../Loading/Loading";
import Vistoria from "../Data Table/Vistoria/Vistoria";
import ResumoTable from "../Data Table/ResumoTable/ResumoTable";
import { FaUserAlt, FaFilter } from "react-icons/fa";
import { HiClipboard, HiClipboardCheck } from "react-icons/hi";
import { BiInfoCircle } from "react-icons/bi";
// import { CadastroAssociado } from "../../../../../context/CadastroASSContext";
import { useNavigate } from "react-router-dom";
import ModalConfirmarPerdida from "./ModalConfirmarPerdida/ModalConfirmarPerdida";
// import { AuthContext } from "../../../../../context/auth";
import { AiFillFilePdf, AiOutlineShareAlt } from "react-icons/ai";
import { BiWindowOpen, BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import Endor from "../Data Table/assets/Endor";
import checarStatusOportunidade from "../../../../../utils/checarStatusOportunidade";
import {
  ButtonInsideWrapper,
  TableButton,
  TableALink,
} from "../Data Table/styles/styledDataTable";
// import enviarPDF from "../../../../../utils/enviarPDF";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { messageWpp } from "../messageWpp";
import { SwitchBotoes } from "../styles/styledOportunidades";
import DraggableItem from "./DraggableItem";
import DroppableWrapper from "./DroppableWrapper";

function Kanban({ FUN_ID, filterStatus }) {
  // const { setCLI_NOME, setCLI_CPF, setCLI_TELEFONE, setCLI_EMAIL } =
  //   useContext(CadastroAssociado);

  // const { PER_ID } = useContext(AuthContext);

  const VEN_ID = sessionStorage.getItem("VEN_ID");
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(true);
  const [vistoria, setVistoria] = useState(false);
  const [dataResumo, setDataResumo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [colunas, setColunas] = useState([]);
  const [cards, setCards] = useState([]);

  const [STATUS_FILTER, setSTATUS_FILTER] = useState("PENDENTE");

  const [modalConfirmaPerdida, setModalConfirmaPerdida] = useState(false);
  const [OPO_ID, setOPO_ID] = useState("");

  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidades-kanban/${VEN_ID}/${FUN_ID}/${filterStatus}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        if (FUN_ID !== "") {
          setLoading(false);
        }
        console.log(res)
        const upperize = (obj) =>
          Object.keys(obj).reduce((acc, k) => {
            acc[k.toUpperCase()] = obj[k];
            return acc;
          }, {});
        // console.log(res.data.col["NUTRIÇÃO DE CONHECIMENTO"].itens.reverse());
        setColunas(upperize(res.data.col));
        var newCards = _.map(res.data.col, (i) => {
          return i.itens;
        });
        setCards(newCards);

      })
      .catch((err) => {
        console.log(err);
        if (FUN_ID !== "") {
          setLoading(false);
        }
      });
  }, [FUN_ID, filterStatus]);

  function checarStatusVistoria() {
    axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res);
        setVistoria(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        MySwal.fire({
          title: "Atenção!",
          text: "Serviço indisponível no momento. Por favor, tente mais tarde.",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((res) => {
          console.log(res);
          if (res) {
            navigate("/home/oportunidades");
          } else {
            // not clicked
          }
        });
      });
  }

  function onDragEnd({ destination, source }) {
    var cardSelecionado = colunas[source.droppableId].itens[source.index];
    setLoading(true);
    if (!destination) {
      setLoading(false);
      return;
    }

    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      setLoading(false);
      return;
    }

    function dropCard() {
      // Copiar item da coluna antes de apagá-lo
      const itemCopia = {
        ...colunas[source.droppableId].itens[source.index],
      };
      setColunas((anterior) => {
        anterior = { ...anterior };
        anterior[source.droppableId].itens.splice(source.index, 1);
        anterior[destination.droppableId].itens.splice(
          destination.index,
          0,
          itemCopia
        );
        return anterior;
      });

      let valorAtualizadoSource = colunas[source.droppableId].itens.reduce((acumulado, objeto) => {
        const valor = objeto.VALOR_PLANO;
      
        if (valor !== null && valor !== undefined) {
          return acumulado + valor;
        } else {
          return acumulado;
        }
      }, 0).toLocaleString("pt-BR", {
        currency: "BRL",
        style: "currency",
        minimumFractionDigits: 2,
      });

      let valorAtualizadoDestination = colunas[destination.droppableId].itens.reduce((acumulado, objeto) => {
        const valor = objeto.VALOR_PLANO;
      
        if (valor !== null && valor !== undefined) {
          return acumulado + valor;
        } else {
          return acumulado;
        }
      }, 0).toLocaleString("pt-BR", {
        currency: "BRL",
        style: "currency",
        minimumFractionDigits: 2,
      });

      setColunas((prev) => {
        prev = { ...prev }
        prev[source.droppableId].valorplanos = valorAtualizadoSource
        prev[destination.droppableId].valorplanos = valorAtualizadoDestination
        return prev
      })

    }

    axios
      .post(
        "https://kaminocrm.com.br:7778/oportunidades/atualizarOportunidadeStatus/",
        {
          destination: destination.droppableId,
          source: source.droppableId,
          fun_id: FUN_ID,
          opo_id: cardSelecionado.OPO_ID,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        dropCard();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function mask(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v.replace(/\s/g, "");
  }

  const OptionsButton = ({ el }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          id={"options"}
          // @ts-ignore
          variant="borderless-dark"
          bsPrefix="no-chevron"
          className="options-btn"
          size="sm"
          disabled={openModal ? true : false}
        >
          <BiDotsVerticalRounded size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ willChange: "transform" }}>
          <TableALink
            className={`dropdown-item ${
              checarStatusOportunidade(el.OPO_STATUS) ||
              (el.PLANOS == null && el.VEI_ID == null)
                ? "not-allowed"
                : ""
            }`}
            disabled={
              checarStatusOportunidade(el.OPO_STATUS) ||
              (el.PLANOS == null && el.VEI_ID == null)
            }
            target="_blank"
            href={`https://kaminocrm.com.br:9898/${ASS_COD}/${el.OPO_ID}`}
            style={{
              pointerEvents: `${
                checarStatusOportunidade(el.OPO_STATUS) ||
                (el.PLANOS == null && el.VEI_ID == null)
                  ? "none"
                  : ""
              }`,
              opacity: `${
                checarStatusOportunidade(el.OPO_STATUS) ||
                (el.PLANOS == null && el.VEI_ID == null)
                  ? "0.6"
                  : "1"
              }`,
            }}
          >
            <ButtonInsideWrapper>
              <AiFillFilePdf size={20} /> <span>Visualizar Proposta</span>
            </ButtonInsideWrapper>
          </TableALink>

          <TableButton
            className="dropdown-item"
            disabled={
              checarStatusOportunidade(el.OPO_STATUS) ||
              (el.PLANOS == null && el.VEI_ID == null)
            }
            onClick={() => {
              window.scrollTo(0, 0);
              setLoading(true);
              setTimeout(function () {
                window.open(
                  `https://wa.me/+55${el.CLI_TELEFONE}?text=${messageWpp(
                    el.EMP_ASSOCIACAO,
                    el.VEN_NOME,
                    ASS_COD,
                    el.OPO_ID
                  )}`,
                  "response",
                  "resizable=yes"
                );
                setLoading(false);
              }, 1000);
            }}
          >
            <ButtonInsideWrapper>
              <AiOutlineShareAlt size={20} />{" "}
              <span style={{ fontSize: "14px" }}>
                Compartilhar oportunidade
              </span>
            </ButtonInsideWrapper>
          </TableButton>

          {/* <TableButton
            className="dropdown-item"
            disabled={
              checarStatusOportunidade(el.OPO_STATUS) || el.OPO_STATUS == "VISTORIAS"
                ? true
                : false
            }
            onClick={() => {
              checarStatusVistoria();
              setLoading(true);
              setDataResumo(OPO_ID);
            }}
          >
            <ButtonInsideWrapper>
              <Endor size={17} /> <span>Gerar Vistoria</span>
            </ButtonInsideWrapper>
          </TableButton> */}

          <TableButton
            className="dropdown-item"
            onClick={() => {
              setOpenModal(true);
              setDataResumo(el.OPO_ID);
              window.scrollTo(0, 0);
            }}
          >
            <ButtonInsideWrapper>
              <BiWindowOpen size={20} />{" "}
              <span style={{ fontSize: "14px" }}>Resumo da oportunidade</span>
            </ButtonInsideWrapper>
          </TableButton>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      {modalConfirmaPerdida && (
        <ModalConfirmarPerdida
          closeModal={setModalConfirmaPerdida}
          OPO_ID={OPO_ID}
          OPO_STATUS={"PERDIDA"}
        />
      )}

      {loading ? <Loading /> : null}
      {vistoria && <Vistoria isVisible={setVistoria} OPO_ID={dataResumo} />}

      {openModal && (
        <ResumoTable closeModal={setOpenModal} OPO_ID={dataResumo} />
      )}

      <KanbanWrapper>
        <div className="container">
          <div className="wrapper">
            <DragDropContext onDragEnd={onDragEnd}>
              {_.map(colunas, (data, key) => {
                return (
                  <DroppableWrapper
                    data={data}
                    keyDrop={key}
                    setColunas={setColunas}
                    OptionsButton={OptionsButton}
                    setOpenModal={setOpenModal}
                    setDataResumo={setDataResumo}
                    mask={mask}
                  />
                );
              })}
              <div id="sentinela"></div>
            </DragDropContext>
          </div>
        </div>
      </KanbanWrapper>
    </>
  );
}

export default Kanban;
