import React, { useState, useContext, useEffect } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import "./style/style.css";

import { NumericFormat } from "react-number-format";
import { BtnSubmit } from "../style/styledModal";
import { CadastroVeiculo } from "../../../../../../context/CadastroVEIContext";

function Implementos() {
  const VEI_ID = sessionStorage.getItem("VEI_ID");

  const DEFAULT_IMP = {
    IMP_PLACA: "",
    IMP_CHASSI: "",
    IMP_TIPO: "",
    IMP_VALOR: "",
    IMP_MENSALIDADE: "",
    VEI_ID: VEI_ID
  };

  const { implementos, setImplementos } = useContext(CadastroVeiculo);

  const [createImp, setCreateImp] = useState(false);
  const [implementoItem, setImplementoItem] = useState(DEFAULT_IMP);


  const handleDisabled = (data) => {
      const { IMP_TIPO, IMP_VALOR, IMP_MENSALIDADE } = data;
      return (
        IMP_TIPO === "" ||
        IMP_VALOR.length <= 6 ||
        IMP_MENSALIDADE.length <= 6
      );
  };

  const addImplemento = (e) => {
    e.preventDefault();
    setImplementos([...implementos, implementoItem]);
    setImplementoItem(DEFAULT_IMP);
  };

  const removeImplemento = (implemento) => {
    const newImplementos = implementos.filter(i => i !== implemento);
    setImplementos(newImplementos);
  };

  return (
    <>
      <div
        className={`imp__btn_criar ${createImp && "form__active"}`}
        onClick={() => setCreateImp(!createImp)}
      >
        <span className="imp__icon">
          <FaPlus size={13} />
        </span>
        <span className="imp__title">Novo implemento</span>
      </div>

      {createImp && (
        <form className="imp__form" onSubmit={addImplemento}>
          <div className="imp__form--only_input">
            <div className="input__form">
              <label htmlFor="tipo_imp">Tipo</label>
              <input
                className="input_valor"
                type="text"
                name="tipo_imp"
                id="tipo_imp"
                value={implementoItem.IMP_TIPO}
                onChange={(e) =>
                  setImplementoItem((prev) => ({
                    ...prev,
                    IMP_TIPO: e.target.value.toUpperCase(),
                  }))
                }
              />
            </div>
          </div>
          <div className="imp__form--double_input">
            <div className="input__form">
              <label htmlFor="placa_imp">Placa</label>
              <input
                className="input_valor"
                type="text"
                name="placa_imp"
                id="placa_imp"
                maxLength={7}
                value={implementoItem.IMP_PLACA}
                onChange={(e) =>
                  setImplementoItem((prev) => ({
                    ...prev,
                    IMP_PLACA: e.target.value.toUpperCase(),
                  }))
                }
              />
            </div>
            <div className="input__form">
              <label htmlFor="chassi_imp">Chassi</label>
              <input
                className="input_valor"
                type="text"
                name="chassi_imp"
                id="chassi_imp"
                maxLength={17}
                value={implementoItem.IMP_CHASSI}
                onChange={(e) =>
                  setImplementoItem((prev) => ({
                    ...prev,
                    IMP_CHASSI: e.target.value.toUpperCase(),
                  }))
                }
              />
            </div>
          </div>
          <div className="imp__form--double_input">
            <div className="input__form">
              <label htmlFor="tipo__imp">Valor</label>
              <NumericFormat
                prefix="R$"
                thousandSeparator="."
                decimalSeparator=","
                className="input_valor"
                value={implementoItem.IMP_VALOR}
                onChange={(e) =>
                  setImplementoItem((prev) => ({
                    ...prev,
                    IMP_VALOR: e.target.value.includes(',') ? e.target.value : `${e.target.value},00`,
                  }))
                }
              />
            </div>
            <div className="input__form">
              <label htmlFor="tipo__imp">Mensalidade</label>
              <NumericFormat
                prefix="R$"
                thousandSeparator="."
                decimalSeparator=","
                className="input_valor"
                value={implementoItem.IMP_MENSALIDADE}
                onChange={(e) =>
                  setImplementoItem((prev) => ({
                    ...prev,
                    IMP_MENSALIDADE: e.target.value.includes(',') ? e.target.value : `${e.target.value},00`,
                  }))
                }
              />
            </div>
          </div>

          <div className="imp__form--footer">
            <button className="imp__form--btn" disabled={handleDisabled(implementoItem)} type="submit">
              <FaPlus size={10} /> Adicionar
            </button>
          </div>
        </form>
      )}

      {implementos?.length > 0 && (
        <div className="imp__lista">
          {implementos?.map((el, index) => {
            return (
              <div className="imp__item" key={index}>
                <button className="imp__item--remove" onClick={() => removeImplemento(el)}>
                  <FaTrashAlt size={15} />
                </button>
                <div className="imp__item--dados">
                  <span id="IMP_TIPO">{el.IMP_TIPO.toUpperCase()}</span> {el.IMP_PLACA && `| ${el.IMP_PLACA}`} {el.IMP_CHASSI && `| ${el.IMP_CHASSI}`}
                </div>
                <div className="imp__item--valores">
                  <span><strong>Valor:</strong> {el.IMP_VALOR} | <strong>Mensalidade:</strong> {el.IMP_MENSALIDADE}</span>
                </div>
              </div>
            );
        })}
        </div>
      )}
    </>
  );
}

export default Implementos;
