import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import {
  BackgroundModal,
  ModalBoxAdicionais,
  ModalHeader,
  X,
  AdicionaisFlexWrapper,
} from "../style/styledModal";
import { Line } from "../../Data Table/Vistoria/styles/styledVistoria";
import Form from "react-bootstrap/Form";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/checkbox.css";
import Loading from "../../../../../Loading/Loading";
import { ToastContainer, toast } from "react-toastify";

function Adicionais({
  closeModal,
  PLAN_ID,
  PLAN_VALOR_MENSALIDADE,
  adicionaisArray,
  novaMensalidade,
  sucesso,
  erro,
}) {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");
  const VEI_SEGMENTO = sessionStorage.getItem("VEI_SEGMENTO");

  const [mensalidade, setMensalidade] = useState(0);
  const [adicionais, setAdicionais] = useState([]);
  const [checked, setChecked] = useState([]);
  const [valoresAMais, setValoresAMais] = useState(0);
  const [loading, setLoading] = useState(true);

  var itensExistentes = adicionaisArray.map((i) => {
    return i;
  });
  var idExiste = itensExistentes.map((i) => {
    return i.PLAN_ID;
  });
  var adicionaisExistem = itensExistentes.map((i) => {
    return i.ADICIONAIS;
  });
  var mensalidadeExiste = itensExistentes.map((i) => {
    return i.NOVA_MENSALIDADE;
  });

  useEffect(() => {
    axios
      .get(
        `https://kaminocrm.com.br:7778/planos/buscarAdicionais?plan_id=${PLAN_ID}&tipo_veiculo=${VEI_SEGMENTO}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setAdicionais(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        erro("Ops! Algo deu errado.");
        setLoading(false);
      });

    setMensalidade(PLAN_VALOR_MENSALIDADE);

    if (adicionaisArray.length > 0) {
      if (idExiste.includes(PLAN_ID)) {
        setMensalidade(Number(mensalidadeExiste[idExiste.indexOf(PLAN_ID)]));
        let itens = {};
        adicionaisExistem[idExiste.indexOf(PLAN_ID)].forEach(
          (element, index, array) => {
            itens = element;
            checked.push(itens);
          }
        );
      }
    }
  }, []);

  const adicionarSelecionados = (e) => {
    var listaAtualizada = [...checked];
    if (e.target.checked) {
      listaAtualizada = [...checked, JSON.parse(e.target.value)];
      setMensalidade(mensalidade + Number(e.target.name.replace(",", ".")));
      setValoresAMais(valoresAMais + Number(e.target.name.replace(",", ".")));
    } else {
      listaAtualizada.splice(checked.indexOf(e.target.value), 1);
      setValoresAMais(valoresAMais - Number(e.target.name.replace(",", ".")));
      if (mensalidade !== PLAN_VALOR_MENSALIDADE) {
        setMensalidade(mensalidade - Number(e.target.name.replace(",", ".")));
      }
    }
    setChecked(listaAtualizada);
  };

  function itemAdicionadoAntes(id) {
    var array = checked.map((i) => {
      return i.item_id;
    });
    if (array.includes(id)) {
      return true;
    }
    return false;
  }

  return (
    <BackgroundModal>
      {loading && <Loading />}
      <ToastContainer />
      <ModalBoxAdicionais>
        <ModalHeader>
          <h2>Adicionais disponíveis</h2>
          <X onClick={() => closeModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <div className="modalBody">
          <AdicionaisFlexWrapper>
            <div className="adicionais">
              {adicionais.map((i) => {
                return (
                  <span className="adicionais_linha" key={i.ITEM_ID}>
                    <span className="itens_valor">
                      <div>
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              <Form.Check
                                type={type}
                                checked={itemAdicionadoAntes(i.ITEM_ID)}
                                name={i.ITEM_VALOR_ADICIONAL}
                                id={i.ITEM_ID}
                                value={JSON.stringify({
                                  item_id: i.ITEM_ID,
                                  item_valor: i.ITEM_VALOR_ADICIONAL,
                                  item_nome: i.ITEM_DESCRICAO,
                                  item_nome_impressao:
                                    i.ITEM_DESCRICAO_IMPRESSAO,
                                })}
                                onChange={(e) => {
                                  adicionarSelecionados(e);
                                }}
                                label={i.ITEM_DESCRICAO}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                      <div className="preco">+ {Number(i.ITEM_VALOR_ADICIONAL.replace(",", ".")).toLocaleString("pt-BR", {
                    currency: "BRL",
                    style: "currency",
                    minimumFractionDigits: 2,
                  })}</div>
                    </span>
                  </span>
                );
              })}
            </div>
            <div className="valores">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  color: "#0f0f0f",
                  alignItems: "flex-end",
                  textAlign: "end",
                }}
              >
                <p className="titulo">Valor do plano</p>
                <h3>
                  {PLAN_VALOR_MENSALIDADE.toLocaleString("pt-BR", {
                    currency: "BRL",
                    style: "currency",
                    minimumFractionDigits: 2,
                  })}
                </h3>
                <Line
                  style={{
                    margin: "0",
                    marginBottom: "0.9rem",
                    backgroundColor: "#3f3f3f",
                    width: "55%",
                  }}
                ></Line>

                <p className="titulo">Valores adicionais</p>
                <h3> 
                  {valoresAMais.toLocaleString("pt-BR", {
                    currency: "BRL",
                    style: "currency",
                    minimumFractionDigits: 2,
                  })}
                </h3>
                <Line
                  style={{
                    margin: "0",
                    marginBottom: "0.9rem",
                    backgroundColor: "#3f3f3f",
                    width: "55%",
                  }}
                ></Line>

                <p className="titulo">Valor atualizado</p>
                <h1>
                  {mensalidade.toLocaleString("pt-BR", {
                    currency: "BRL",
                    style: "currency",
                    minimumFractionDigits: 2,
                  })}
                </h1>
                {/* <h3>{valoresAMais > 0 ? valoresAMais.toFixed(2) : 0}</h3> */}
              </div>

              <button
                className="confirmar-adicionais"
                // disabled={checked.length === 0 ? true : false}
                onClick={() => {
                  closeModal(false);
                  novaMensalidade(mensalidade);
                  if (
                    adicionaisArray.some(
                      (element) => element["PLAN_ID"] == PLAN_ID
                    )
                  ) {
                    adicionaisArray.forEach((element) => {
                      if (element.PLAN_ID == PLAN_ID) {
                        element.ADICIONAIS = checked;
                        element.NOVA_MENSALIDADE = mensalidade.toFixed(2);
                      }
                    });
                    sucesso("Adicionais atualizados com sucesso!");
                  } else {
                    adicionaisArray.push({
                      PLAN_ID: PLAN_ID,
                      NOVA_MENSALIDADE: mensalidade.toFixed(2),
                      ADICIONAIS: checked,
                    });
                  }
                }}
              >
                Confirmar
              </button>
            </div>
          </AdicionaisFlexWrapper>
        </div>
      </ModalBoxAdicionais>
    </BackgroundModal>
  );
}

export default Adicionais;
