import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div style={{
        textAlign: "center"
    }}>
      <h1 style={{
          color: "#1768e4",
          fontSize: "30px"
          }}>
              Erro! Página não existente.
      </h1>
      <Link to="/onboarding" style={{
          color: "#57546b"
      }}>Voltar para o Onboarding</Link>
    </div>
  );
};

export default ErrorPage;
