import styled from "styled-components";
import { COLORS } from "../../../../colors";
import { MultiSelect } from "react-multi-select-component";

export const VenSelect = styled(MultiSelect)`
  color: red;
  background-color: red;
`

export const CardRelatorios = styled.div`
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 5px ${COLORS.lightGray};
  width: 45%;
  margin: 20px auto;
  border-radius: 4px;

  span {
    display: block;
    margin: 7px 0;
    font-weight: 300;
  }

  @media only screen and (max-width: 790px) {
    width: 90%;

  }
`;

export const SelectFiltro = styled.select`
  width: 100%;
  padding: 7px 4px;
  border-radius: 4px;
  border: 1px solid ${COLORS.lightGray};
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ccc;
  font-weight: bolder;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #515054;

  option{
    font-weight: bold;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #515054;
  }

  :focus {
    outline: none;
  }

  /* @media only screen and (max-width: 790px) {
    width: 90%;

  } */
`;
export const SelectFiltroOpo = styled.select`
  width: 28%;
  padding: 7px 18px;
  border-radius: 4px;
  border: 1px solid ${COLORS.lightGray};
  margin-bottom: 10px;
  background-color: white;
  border: 1px solid #ccc;
  font-weight: bolder;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #515054;
  -webkit-appearance: none;
  background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white;

  option{
    font-weight: bold;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #515054;
  }

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 1120px) {
    width: 70%;

  }
  @media only screen and (max-width: 980px) {
    width: 99%;

  }
`;

export const ButtonFiltragem = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 15px;
  background-color: ${COLORS.primary};
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: none;

  :hover{
      background-color: ${COLORS.primaryHover};
      cursor: pointer;
  }

  :disabled{
    background-color: grey;
    cursor: not-allowed
  }
`;
