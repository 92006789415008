import React from "react";
import { ContainerOnboard } from "../style";
import { BsArrowRightShort } from "react-icons/bs";
import logo from "../assets/kamino_logo.svg";
import cloud from "../assets/save.svg";
import copy from "../assets/copy.svg";
import share from "../assets/upload.svg";
import { useNavigate } from "react-router-dom";

function ConclusaoCadastro() {
  const navigate = useNavigate();
  const ASS_COD = sessionStorage.getItem('ASS_COD')

  return (
    <ContainerOnboard>
      <header className="onboarding-header animation_fadedown">
        <img src={logo} alt="logo_kamino" />
      </header>

      <div className="concadastro-body">
        <section className="concadastro-body__data" >
          <div className="concadastro-body__header">
            <h1 className="title">Cadastro Concluído!</h1>
            <p className="subtitle" style={{ maxWidth: "none" }}>
              Seu acesso ao Kamino CRM foi configurado com sucesso!
            </p>
            <p className="subtitle">
              Acesse a Plataforma utilizando o link abaixo:
            </p>
          </div>

          <div className="concadastro-body__card">
            <div className="concadastro-body__card__header">
              <img src={cloud} alt="nuvem" />

              <div className="card__header__icons">
                <img src={copy} alt="copiar" onClick={() => {navigator.clipboard.writeText(`kaminocrm.com.br/${ASS_COD}`)}} />
                <img src={share} alt="compartilhar" onClick={() => {navigator.clipboard.writeText(`kaminocrm.com.br/${ASS_COD}`)}} />
              </div>
            </div>
            <div className="concadastro-body__card__content">
              <span className="card__content__title">
                Endereço de acesso ao Kamino CRM:
              </span>

              <div className="card__content__input">
                <input type="text" disabled value={`kaminocrm.com.br/${ASS_COD}`} />
                <BsArrowRightShort size={35} className="input__icon" onClick={() => navigate(`/${ASS_COD}`)} />
              </div>
            </div>
          </div>

          <span style={{ marginTop: "-5rem", textAlign: "center", color: "#6d6d6d", fontSize: "1rem" }}>
          Após acessar a Plataforma você poderá cadastrar outros usuários e administrar as vendas de sua Associação!
          </span>
        </section>

        <section className="concadastro-body__ui"></section>
      </div>
    </ContainerOnboard>
  );
}

export default ConclusaoCadastro;
