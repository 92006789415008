import React from 'react';
import Endor from '../../pages/Home/HomeRoutes/Oportunidades/Data Table/assets/Endor';
import { FaGoogleDrive } from 'react-icons/fa';
import './styles.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function CardSelectVistoria({
  cardContent,
  vistoriaSelecionada,
  setVistoriaSelecionada,
}) {
  const { icon = 'endor', title = '', content = '' } = cardContent;
  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info('Internal server error, X-121_06', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function selectCategory() {
    await axios
      .patch(`https://kaminocrm.com.br:7778/parametros/configurarVistoria/${icon}`, {},{
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res);
        setVistoriaSelecionada(icon);
        feedback(res.data.message, 'success');
      })
      .catch((err) => {
        console.log(err);
        feedback('Ops! Algo deu errado.', 'error');
      });
  }

  return (
    <div
      className={`CardSelect ${
        vistoriaSelecionada === icon ? 'CardSelect__Selected' : ''
      }`}
      onClick={selectCategory}
    >
      <div className='CardSelect__Container'>
        <header>
          {icon === 'endor' ? (
            <Endor defaultColor='#000' size={50} />
          ) : (
            <FaGoogleDrive size={51} />
          )}
          <div className='CardSelect__HeaderText'>
            <span className='CardSelect__Title'>{title}</span>
          </div>
        </header>
        <div className='CardSelect__Divider'></div>
        <span className='CardSelect__Content'>{content}</span>
      </div>
      <div className='CardSelect__Checkbox'>
        <input
          type='radio'
          checked={vistoriaSelecionada === icon}
          onChange={selectCategory}
        />
      </div>
    </div>
  );
}

export default CardSelectVistoria;
