import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { VistoriaContext } from "../../../../../../../context/VistoriaContext";
import {
  VistoriaBody,
  SectionForm,
  InputWrapper,
  BtnWrapper,
  InputComMascara,
  Line,
} from "../styles/styledVistoria";
import { BtnSubmit, BtnVoltar } from "../../../Modal/style/styledModal";
import Loading from "../../../../../../Loading/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import listaEstados from "../../../../../../../utils/listaEstados.js";

function DadosVeiculo({
  OPO_ID,
  VEI_PLACA,
  VEI_MARCA,
  VEI_MODELO,
  VEI_CHASSI,
  VEI_ANO_FAB,
  VEI_ANO_MODELO,
  VEI_COD_TABELA_FIPE,
  VEI_TIPO,
  VEI_VALOR_FIPE,
  VEI_CODIGO_ANO_MODELO,
  VEI_CODIGO_MARCA,
  VEI_CODIGO_MODELO,
  VEI_CIDADE,
  VEI_UF,
  handleNext,
  handleBack,
  isVisible,
}) {
  const {
    placa,
    setPlaca,
    renavam,
    setRenavam,
    chassi,
    setChassi,
    categoria,
    setCategoria,
    anoFab,
    setAnoFab,
    anoModelo,
    setAnoModelo,
    cor,
    setCor,
    alienado,
    setAlienado,
    estadoVeiculo,
    setEstadoVeiculo,
    cidadeVeiculo,
    setCidadeVeiculo,
    setCidCodigoVeiculo,
    documentoProprietario,
    setDocumentoProprietario,
    nomeProprietario,
    setNomeProprietario,
    marca,
    setMarca,
    modelo,
    setModelo,
    anoModeloCombustivel,
    setAnoModeloCombustivel,
    codigoFipe,
    setCodigoFipe,
    tipo,
    setTipo,
    valorFipe,
    setValorFipe,
    codMarca,
    setCodMarca,
    codModelo,
    setCodModelo,
    dadosVeiculo,
    dadosGerais,
  } = useContext(VistoriaContext);

  const [estadosArray, setEstadosArray] = useState([]);
  const [cidadesArray, setCidadesArray] = useState([]);

  const [loading, setLoading] = useState(true);

  const MySwal = withReactContent(Swal);
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  useEffect(() => {
    if (VEI_UF) {
      setEstadoVeiculo(VEI_UF);
      verCidades(VEI_UF);
    }

    if (VEI_PLACA) {
      setPlaca(VEI_PLACA);
    }
    if (VEI_MARCA) {
      setMarca(VEI_MARCA);
    }
    if (VEI_MODELO) {
      setModelo(VEI_MODELO);
    }
    if (VEI_CHASSI) {
      setChassi(VEI_CHASSI);
    }
    if (VEI_ANO_FAB) {
      setAnoFab(VEI_ANO_FAB);
    }
    if (VEI_ANO_MODELO) {
      setAnoModelo(VEI_ANO_MODELO);
    }
    if (VEI_COD_TABELA_FIPE) {
      setCodigoFipe(VEI_COD_TABELA_FIPE);
    }
    if (VEI_TIPO) {
      setTipo(VEI_TIPO);
    }
    if (VEI_VALOR_FIPE) {
      setValorFipe(VEI_VALOR_FIPE);
    }
    if (VEI_CODIGO_ANO_MODELO) {
      setAnoModeloCombustivel(VEI_CODIGO_ANO_MODELO);
    }
    if (VEI_CODIGO_MARCA) {
      setCodMarca(VEI_CODIGO_MARCA);
    }
    if (VEI_CODIGO_MODELO) {
      setCodModelo(VEI_CODIGO_MODELO);
    }
    // if(VEI_UF){
    //   setEstadoVeiculo(VEI_UF)
    //   console.log(VEI_UF)
    // }
    if (VEI_CIDADE) {
      setCidadeVeiculo(VEI_CIDADE);
      console.log(VEI_CIDADE);
    }

    setEstadoVeiculo("");
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // setVistoria(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        MySwal.fire({
          title: "Atenção!",
          text: "Serviço indisponível no momento. Por favor, tente mais tarde.",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((res) => {
          if (res) {
            isVisible(false);
          } else {
            // not clicked
          }
        });
      });
  }, []);

  function mascaraMoeda(doc) {
    var valor = doc;
    const preco = valor.toLocaleString("pt-BR", {
      currency: "BRL",
      style: "currency",
      minimumFractionDigits: 2,
    });
    return preco.replace("R$", "");
  }

  const mascaraCPFCNPJ = (doc) => {
    doc = doc.replace(/\D/g, "");

    if (doc.length <= 11) {
      doc = doc.replace(/(\d{3})(\d)/, "$1.$2");
      doc = doc.replace(/(\d{3})(\d)/, "$1.$2");
      doc = doc.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      doc = doc.replace(/^(\d{2})(\d)/, "$1.$2");
      doc = doc.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      doc = doc.replace(/\.(\d{3})(\d)/, ".$1/$2");
      doc = doc.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return doc;
  };

  function verCidades(estado) {
    axios
      .get(`https://kaminocrm.com.br:7778/naboo/cidades?uf=${estado}`)
      .then((res) => {
        console.log(res);
        setCidadesArray(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function placaDigitada() {
    if (placa.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const validarCamposVeiculo = () => {
    if (placa.length > 0) {
      var data = dadosVeiculo;
      const v1 = () => {
        for (var key in data) {
          if (data[key] === "") {
            return false;
          }
        }
        return true;
      };

      if (v1()) {
        var desabilitado = false;
      } else {
        var desabilitado = true;
      }
      return desabilitado;
    } else {
      return true;
    }
  };

  var estados = listaEstados.sort(function compare(a, b) {
    if (a.nome < b.nome) {
      return -1;
    }
    if (a.nome > b.nome) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      {loading && <Loading />}
      <VistoriaBody>
        <SectionForm>
          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="placa">Placa</label>
            <input
              type="text"
              name="placa"
              value={placa}
              onChange={(e) => {
                setPlaca(e.target.value);
              }}
            />
          </InputWrapper>

          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="renavam">Renavam</label>
            <input
              type="text"
              name="renavam"
              disabled={placaDigitada()}
              value={renavam}
              onChange={(e) => {
                setRenavam(e.target.value);
              }}
            />
          </InputWrapper>

          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="chassi">Chassi</label>
            <input
              type="text"
              name="chassi"
              disabled={placaDigitada()}
              value={chassi}
              onChange={(e) => {
                setChassi(e.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="categoria">Categoria</label>
            <select
              name="categoria"
              id="categoria"
              disabled={placaDigitada()}
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            >
              <option value="null">Selecione...</option>
              <option value="PARTICULAR">PARTICULAR</option>
              <option value="ALUGUEL">ALUGUEL</option>
              <option value="OFICIAL">OFICIAL</option>
            </select>
          </InputWrapper>
        </SectionForm>

        <SectionForm>
          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="ano-fab">Ano Fabricação</label>
            <input
              type="text"
              name="ano-fab"
              disabled={placaDigitada()}
              value={anoFab}
              onChange={(e) => setAnoFab(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="ano-modelo">Ano Modelo</label>
            <input
              type="text"
              name="ano-modelo"
              disabled={placaDigitada()}
              value={anoModelo}
              onChange={(e) => setAnoModelo(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="cor">Cor</label>
            <input
              type="text"
              name="cor"
              disabled={placaDigitada()}
              value={cor}
              onChange={(e) => setCor(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper className="sec_3-1_placa">
            <label htmlFor="alienado">Alienado</label>
            <select
              name="alienado"
              disabled={placaDigitada()}
              value={alienado}
              onChange={(e) => setAlienado(e.target.value)}
            >
              <option value="null">Selecione...</option>
              <option value={true}>SIM</option>
              <option value={false}>NÃO</option>
            </select>
          </InputWrapper>
        </SectionForm>

        <SectionForm>
          <InputWrapper className="sec_2-3_insc">
            <label htmlFor="estado">Estado</label>
            <select
              name="estado"
              disabled={placaDigitada()}
              value={estadoVeiculo}
              onChange={(e) => {
                setEstadoVeiculo(e.target.value);
                setLoading(true);
                verCidades(e.target.value);
              }}
            >
              <option value="null" disabled hidden>
                Selecione...
              </option>
              {estados.map((i) => {
                return (
                  <option key={i.id} value={i.sigla}>
                    {i.nome.toUpperCase()} ({i.sigla})
                  </option>
                );
              })}
            </select>
          </InputWrapper>

          <InputWrapper className="sec_2-3_raz">
            <label htmlFor="cidade">Cidade</label>
            <select
              name="cidade"
              disabled={placaDigitada()}
              onChange={(e) => {
                const { value } = e.target
                var dados = JSON.parse(value)
                setCidadeVeiculo(dados.nome)
                setCidCodigoVeiculo(dados.codigo)
                // console.table(dados)
              }}
            >
              <option value="null" disabled hidden>
                Selecione a cidade...
              </option>
              {cidadesArray.map((i) => {
              return (
                <option key={i.CID_CODIGO} value={JSON.stringify({nome: i.CID_NOME.toUpperCase(), codigo: i.CID_CODIGO})}>
                  {i.CID_NOME.toUpperCase()}
                </option>
              );
            })}
            </select>
          </InputWrapper>
        </SectionForm>

        <SectionForm>
          <InputWrapper className="sec_2-3_insc">
            <label htmlFor="documento-proprietario">
              CPF/CNPJ do Proprietário
            </label>
            <input
              type="text"
              name="documento-proprietario"
              disabled={placaDigitada()}
              value={mascaraCPFCNPJ(documentoProprietario)}
              maxLength={18}
              onChange={(e) =>
                setDocumentoProprietario(e.target.value.replace(/\D/g, ""))
              }
            />
          </InputWrapper>

          <InputWrapper className="sec_2-3_raz">
            <label htmlFor="nome-proprietario">Nome do Propietário</label>
            <input
              type="text"
              name="nome-proprietario"
              disabled={placaDigitada()}
              value={nomeProprietario}
              onChange={(e) => setNomeProprietario(e.target.value)}
            />
          </InputWrapper>
        </SectionForm>

        <Line></Line>

        <SectionForm>
          <InputWrapper className="sec_2-3_nomeres">
            <label htmlFor="">Tipo de Veículo</label>
            <select
              name=""
              id=""
              disabled={placaDigitada()}
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option value="null">Selecione...</option>
              <option value="CARROS">CARROS</option>
              <option value="MOTOS">MOTOS</option>
              <option value="CAMINHOES">CAMINHÕES</option>
            </select>
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="marca">Marca</label>
            <input
              type="text"
              name="marca"
              disabled={placaDigitada()}
              value={marca}
              onChange={(e) => setMarca(e.target.value)}
            />
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="modelo">Modelo</label>
            <input
              type="text"
              name="modelo"
              disabled={placaDigitada()}
              value={modelo}
              onChange={(e) => setModelo(e.target.value)}
            />
          </InputWrapper>
        </SectionForm>

        <SectionForm>
          <InputWrapper>
            <label htmlFor="ano-modelo">Ano Modelo / Combustível</label>
            <input
              type="text"
              name="ano-modelo"
              disabled={placaDigitada()}
              value={anoModeloCombustivel}
              onChange={(e) => setAnoModeloCombustivel(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="codigo-fipe">Código FIPE</label>
            <input
              type="text"
              name="codigo-fipe"
              disabled={placaDigitada()}
              value={codigoFipe}
              onChange={(e) => setCodigoFipe(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="valor">Valor R$</label>
            <input
              type="text"
              name="valor"
              disabled={placaDigitada()}
              value={mascaraMoeda(Number(valorFipe))}
              onChange={(e) => setValorFipe(e.target.value)}
            />
          </InputWrapper>
        </SectionForm>

        <SectionForm style={{ justifyContent: "flex-end" }}>
          <BtnWrapper>
            <BtnVoltar
              style={{ margin: "0 20px" }}
              onClick={() => {
                handleBack();
              }}
            >
              Voltar
            </BtnVoltar>

            <BtnSubmit
              disabled={validarCamposVeiculo()}
              onClick={() => {
                handleNext();
              }}
            >
              Próximo
            </BtnSubmit>
          </BtnWrapper>
        </SectionForm>
      </VistoriaBody>
    </>
  );
}

export default DadosVeiculo;
