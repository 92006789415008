import React, { useState } from "react";
import {
  BtnSubmit,
  BtnVoltar,
  DoubleInput,
  Input,
} from "../../style/styledModal";
import Select from "react-select";
import { NumericFormat } from "react-number-format";

function Fipe({
  dataCarro,
  setDataCarro,
  zeroKM,
  segmentos,
  navigate,
  buscarPlanosVeiculo,
}) {
  return (
    <div className="modalBody">
      <form>
        <DoubleInput>
          <div className="placa">
            <label>Digite sua placa:</label>
            <Input
              type="text"
              maxLength={7}
              style={{
                textTransform: "uppercase",
              }}
              disabled={zeroKM}
              value={dataCarro.VEI_PLACA}
              onChange={(e) => {
                setDataCarro((prev) => ({
                  ...prev,
                  VEI_PLACA: e.target.value,
                }));
              }}
            />
          </div>
          <div className="chassi">
            <label>Tipo de veículo:</label>

            <Select
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  marginTop: "0.25rem",
                }),
              }}
              options={segmentos}
              onChange={(e) => {

                setDataCarro((prev) => ({
                  ...prev,
                  VEI_TIPO: e.value.toString(),
                }));
              }}
              placeholder="Selecione..."
            />
          </div>
        </DoubleInput>


        <DoubleInput>
          <div style={{ width: "100%" }}>
            <label
              style={{
                display: "block",
                margin: "10px 0",
              }}
            >
              Marca:
            </label>
            <Input
              value={dataCarro.VEI_MARCA}
              onChange={(e) => {
                setDataCarro((prev) => ({
                  ...prev,
                  VEI_MARCA: e.target.value,
                }));
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <label
              style={{
                display: "block",
                margin: "10px 0",
              }}
            >
              Modelo:
            </label>
            <Input
              value={dataCarro.VEI_MODELO}
              onChange={(e) => {
                setDataCarro((prev) => ({
                  ...prev,
                  VEI_MODELO: e.target.value,
                }));
              }}
            />
          </div>
        </DoubleInput>

        <DoubleInput>
          <div style={{ width: "100%" }}>
            <label
              style={{
                display: "block",
                margin: "10px 0",
              }}
            >
              Ano Modelo:
            </label>
            <Input
              value={dataCarro.VEI_ANO_MODELO}
              onChange={(e) => {
                setDataCarro((prev) => ({
                  ...prev,
                  VEI_ANO_MODELO: e.target.value,
                  VEI_ANO_FAB: e.target.value,
                  VEI_CODIGO_ANO_MODELO: e.target.value
                }));
              }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <label
              style={{
                display: "block",
                margin: "10px 0",
              }}
            >
              Valor negociado:
            </label>
            <NumericFormat
              value={dataCarro.VEI_VALOR_ACORDADO}
              prefix="R$"
              thousandSeparator="."
              decimalSeparator=","
              className="input_criar"
              onChange={(e) => {
                setDataCarro((prev) => ({
                  ...prev,
                  VEI_VALOR_ACORDADO: e.target.value,
                  VEI_VALOR_FIPE: e.target.value
                }));
              }}
            />
          </div>
        </DoubleInput>

        <div
          className="modalFooter"
          style={{
            marginTop: "40px",
          }}
        >
          <BtnVoltar
            type="button"
            style={{ margin: "0 20px" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </BtnVoltar>

          <BtnSubmit type="button" onClick={() => buscarPlanosVeiculo()}>
            Avançar
          </BtnSubmit>
        </div>
      </form>
    </div>
  );
}

export default Fipe;
