import React from "react";
import { ContainerOnboard } from "./style";
import { useNavigate } from "react-router-dom";
import Card from "./components/Card/Card";
import logo from "./assets/kamino_logo.svg";
import gear from "./assets/config.svg";

function Onboarding() {

  const navigate = useNavigate()

  function next(){
    navigate("cadastro-codigo")
  }

  return (
    <ContainerOnboard>
      <header className="onboarding-header animation_fadedown">
        <img src={logo} alt="logo_kamino" />
      </header>

      <main className="onboarding-body">
        <div className="onboarding-body__title animation_fadedown">
          <img src={gear} alt="config_gear" />
          <h1>Configuração Inicial</h1>
          <p>
            Clique no botão  <strong>Iniciar</strong> e siga o passo a passo para configurar sua
            Plataforma Online do Kamino CRM
          </p>
        </div>

        <div className="onboarding-body__cards animation_fadedown">
          <Card
            title="Código"
            descrp="Tenha em mão o Código da Associação para dar início a configuração da Plataforma"
          />
          <Card
            title="Usuário"
            descrp="Crie seu usuário para que você possa acessar a plataforma assim que concluir a configuração"
          />
          <Card
            title="Resumo"
            descrp="Ao concluir a configuração será gerado um link para que você possa acessar a plataforma"
          />
        </div>

        <div className="onboarding-body__btn animation_fadedown">
          <button className="btn__avançar" onClick={() => { next() }}>
            Iniciar
          </button>
        </div>
      </main>
    </ContainerOnboard>
  );
}

export default Onboarding;
