import React, { useState, useEffect } from "react";
import axios from "axios";
// import { ButtonSalvarSenha } from "../../Home/HomeRoutes/Perfil/styledPerfil";
import Loading from "../../Loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLORS } from "../../../colors";
import { MdUnarchive, MdEdit } from "react-icons/md";
import { TableButton } from "../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable";
import ModalAlterar from "./ModalAlterar/ModalAlterar";
import ConfirmarDesarquivar from "./ConfirmarDesarquivar/ConfirmarDesarquivar";
import { FunilWrapper } from "../Funis/styledFunis";
import { BtnSubmit } from "../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { FaPlus } from "react-icons/fa";
import Novo from "./Novo/Novo";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function Tarefas() {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(false);

  const [nomeTipoTarefa, setNomeTipoTarefa] = useState("");
  const [tiposTarefa, setTiposTarefa] = useState([]);
  const [erroNome, setErroNome] = useState(false);
  const [openModalAlterar, setOpenModalAlterar] = useState(false);
  const [openModalDesarquivar, setOpenModalDesarquivar] = useState(false);
  const [idTipoTarefa, setIdTipoTarefa] = useState("");

  const [modalNovo, setModalNovo] = useState(false);

  useEffect(() => {
    axios
      .get("https://kaminocrm.com.br:7778/parametros/listarTarefas", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setTiposTarefa(res.data.tarefas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function feedback(msg, type) {
    if (type === "success") {
      toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === "error") {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  // loading, toast, erro geral, erro nome existente
  async function criarFunil() {
    await axios
      .post(
        "https://kaminocrm.com.br:7778/parametros/criarTarefa",
        { TAREFA_NOME: nomeTipoTarefa },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log(res);
        feedback("Tarefa criada com sucesso!", "success");
        setTimeout(function () {
          setLoading(false);
        }, 1000);
        if (!loading) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.message);
        const { data } = err.response;
        if (data.statusCode === 400) {
          setErroNome(true);
        } else {
          feedback("Algo deu errado!", "error");
        }
        setTimeout(function () {
          setLoading(false);
        }, 1000);
      });
  }

  function checarStatus(TAREFA_ATIVA) {
    if (TAREFA_ATIVA) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: "2px 10px",
            borderRadius: "10px",
            color: "white",
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          ATIVA
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: "2px 7px",
            borderRadius: "10px",
            color: "white",
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          ARQUIVADA
        </span>
      );
    }
  }
  return (
    <>
      {/* <ToastContainer /> */}
      {modalNovo && <Novo openModal={setModalNovo} />}

      <FunilWrapper>
        {openModalAlterar && (
          <ModalAlterar
            openModal={setOpenModalAlterar}
            TAREFA_ID={idTipoTarefa}
            TAREFA_NOME={nomeTipoTarefa}
          />
        )}
        {openModalDesarquivar && (
          <ConfirmarDesarquivar
            openModal={setOpenModalDesarquivar}
            TAREFA_ID={idTipoTarefa}
          />
        )}
        {loading && <Loading />}
        <div className="criar">
          <BtnSubmit onClick={() => setModalNovo(true)}>
            <FaPlus size={11} /> <span>Criar tipo de tarefa</span>
          </BtnSubmit>
        </div>

        <h3>Gerenciar tipos</h3>
        <div className="funis_existem">
          <table>
            <thead>
              <tr>
                <th className="col_nome">Tipo</th>
                <th className="col_status">Status</th>
                <th className="col_acoes">Ações</th>
              </tr>
            </thead>
            <tbody>
              {tiposTarefa.map((i) => {
                return (
                  <tr key={i.TAREFA_ID}>
                    <td className="col_nome">{i.TAREFA_NOME}</td>
                    <td className="col_status">
                      {checarStatus(i.TAREFA_ATIVA)}
                    </td>
                    <td className="col_acoes">
                      {i.TAREFA_ATIVA ? (
                        <Tippy
                          content="Editar tarefa"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalAlterar(true);
                              setIdTipoTarefa(i.TAREFA_ID);
                              setNomeTipoTarefa(i.TAREFA_NOME)
                              window.scrollTo(0, 0);
                            }}
                          >
                            <MdEdit size={20} />
                          </TableButton>
                        </Tippy>
                      ) : (
                        <Tippy
                          content="Desarquivar tarefa"
                          delay="275"
                          placement="bottom"
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalDesarquivar(true);
                              setIdTipoTarefa(i.TAREFA_ID);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <MdUnarchive size={22} />
                          </TableButton>
                        </Tippy>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </FunilWrapper>
    </>
  );
}

export default Tarefas;
