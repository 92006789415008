import React, { useState } from "react";
import {
  BtnSubmit,
  BtnVoltar,
  DoubleInput,
  Input,
} from "../../style/styledModal";
import Select from "react-select";
import { NumericFormat } from "react-number-format";

function Manual({
  dataCarro,
  setDataCarro,
  zeroKM,
  marcasVei,
  modelosVei,
  anoModeloVei,
  segmentos,
  valorCodigo,
  valorVeiculo,
  consultarMarcas,
  consultarModelos,
  consultarAnos,
  marcaVeiVisivel,
  modeloVeiVisivel,
  anoVeiVisivel,
  setModeloVeiVisivel,
  setAnoVeiVisivel,
  setValorCodigo,
  setLoading,
  navigate,
  buscarPlanosVeiculo,
  consultarComTodosOsParametros,
}) {
  return (
    <div className="modalBody">
      <form>
        <DoubleInput>
          <div className="placa">
            <label>Digite sua placa:</label>
            <Input
              type="text"
              maxLength={7}
              style={{
                textTransform: "uppercase",
              }}
              disabled={zeroKM}
              value={dataCarro.VEI_PLACA}
              onChange={(e) => {
                setDataCarro((prev) => ({
                  ...prev,
                  VEI_PLACA: e.target.value,
                }));
              }}
            />
          </div>
          <div className="chassi">
            <label>Tipo de veículo:</label>

            <Select
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  marginTop: "0.25rem",
                }),
              }}
              options={segmentos}
              onChange={(e) => {
                consultarMarcas(e.value);
                setModeloVeiVisivel(false);
                setAnoVeiVisivel(false);
                setValorCodigo(false);
                setLoading(true);
              }}
              placeholder="Selecione..."
            />
          </div>
        </DoubleInput>

        {marcaVeiVisivel && (
          <>
            <label
              style={{
                display: "block",
                margin: "10px 0",
              }}
            >
              Marca:
            </label>
            <Select
              options={marcasVei}
              onChange={(e) => {
                consultarModelos(e.value);
                setLoading(true);
                setAnoVeiVisivel(false);
                setValorCodigo(false);
              }}
              placeholder="Selecione..."
            />

            {modeloVeiVisivel && (
              <>
                <label
                  style={{
                    display: "block",
                    margin: "10px 0",
                  }}
                >
                  Modelo:
                </label>
                <Select
                  options={modelosVei}
                  onChange={(e) => {
                    consultarAnos(e.value);
                    setValorCodigo(false);
                    setLoading(true);
                  }}
                  placeholder="Selecione..."
                />

                {anoVeiVisivel && (
                  <>
                    <label
                      style={{
                        display: "block",
                        margin: "10px 0",
                      }}
                    >
                      Ano Modelo:
                    </label>
                    <Select
                      options={anoModeloVei}
                      onChange={(e) => {
                        consultarComTodosOsParametros(e.value);
                        setLoading(true);
                      }}
                      placeholder="Selecione..."
                    />
                  </>
                )}
              </>
            )}
          </>
        )}

        {valorCodigo && (
          <div className="fipeDados">
            <div className="fipeDados__valor">
              <label>Valor FIPE</label>
              <Input value={valorVeiculo} disabled />
            </div>
            <div className="fipeDados__codigo">
              <label>Valor negociado</label>
              <NumericFormat
                value={dataCarro.VEI_VALOR_ACORDADO}
                prefix="R$"
                thousandSeparator="."
                decimalSeparator=","
                className="input_criar"
                onChange={(e) => {
                  setDataCarro((prev) => ({
                    ...prev,
                    VEI_VALOR_ACORDADO: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        )}

        <div
          className="modalFooter"
          style={{
            marginTop: "40px",
          }}
        >
          <BtnVoltar
            type="button"
            style={{ margin: "0 20px" }}
            onClick={() => navigate(-1)}
          >
            Voltar
          </BtnVoltar>

          <BtnSubmit type="button" onClick={() => buscarPlanosVeiculo()}>
            Avançar
          </BtnSubmit>
        </div>
      </form>
    </div>
  );
}

export default Manual;
