import React, { createContext, useState, useEffect } from 'react';

export const SiteContext = createContext();

const SiteContextProvider = ({ children }) => {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");
  const USR_ID = sessionStorage.getItem("USR_ID");

  const [siteState, setSiteState] = useState({
    settingsTab: 'usuarios',
    usrData: {
      login: LOGIN,
      token: JWT_TOKEN,
      enterpriseCode: ASS_COD,
      id: USR_ID
    }
  });

  useEffect(() => {
    let currentTab = sessionStorage.getItem('settingsTab');
    if (siteState.settingsTab !== currentTab && currentTab !== null) {
      setSiteState((prev) => ({
        ...prev,
        settingsTab: currentTab,
      }));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('settingsTab', siteState.settingsTab)
  }, [siteState.settingsTab])

  return (
    <SiteContext.Provider value={{ siteState, setSiteState }}>
      {children}
    </SiteContext.Provider>
  );
};

export default SiteContextProvider;
