import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

import { ModalHeader } from '../Home/HomeRoutes/Oportunidades/Modal/style/styledModal';
import { HiTrash } from 'react-icons/hi';
import { MdVisibility } from 'react-icons/md';
import { Container } from '../Login/style/styledLogin';

import {
  CardParametros,
  InputNumber,
  BotaoDeletarImagem,
} from './styledParametros';
import {
  ButtonSalvarSenha,
  Line,
} from '../Home/HomeRoutes/Perfil/styledPerfil';
import Loading from '../Loading/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from 'react-multi-select-component';
import Tippy from '@tippyjs/react';
import ModalImagem from './ModalImagem/ModalImagem';
import Funis from './Funis/Funis';
import Tarefas from './Tarefas/Tarefas';
import Origem from './Origem/Origem';
import Vistorias from './Vistorias/Vistorias';
import TabelaDescontos from './TabelaDescontos/TabelaDescontos';
import Templates from './Templates/Templates.jsx';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';
import { LoadingNumbered } from '../../components/LoadingNumbered/LoadingNumbered';
import Header from '../../components/Header/Header';
import Usuarios from '../Usuarios/Usuarios';
import Automacoes from './Automacoes/Automacoes';


function Parametros() {
  const { logout, PER_ID, versaoSistema } = useContext(AuthContext);
  const handleLogout = () => {
    logout();
  };

  const navigate = useNavigate();

  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  const [loading, setLoading] = useState(true);
  const [loadingNumbered, setLoadingNumbered] = useState(false);
  const [progress, setProgress] = useState(0);

  const [extenderNav, setExtenderNav] = useState(false);
  const [config, setConfig] = useState(false);

  const [qtdeDiasVencimento, setQtdeDiasVencimento] = useState('');
  const [vencerOpo, setVencerOpo] = useState(false);

  // const [perfil, setPerfil] = useState([]);
  // const [graficos, setGraficos] = useState([]);

  // const [perfilSelecionado, setPerfilSelecionado] = useState([]);
  // const [graficoSelecionado, setGraficoSelecionado] = useState([]);

  const [checkboxCapa, setCheckboxCapa] = useState(false);
  const [checkboxContraCapa, setCheckboxContraCapa] = useState(false);
  const [contraCapaEnviada, setContraCapaEnviada] = useState(null);
  const [capaEnviada, setCapaEnviada] = useState(null);
  const [enableImg, setEnableImg] = useState(true);
  const [linkCapaExiste, setLinkCapaExiste] = useState('');
  const [linkContraCapaExiste, setLinkContraCapaExiste] = useState('');
  const [modalImagemCapa, setModalImagemCapa] = useState(false);
  const [modalImagemContraCapa, setModalImagemContraCapa] = useState(false);

  const [key, setKey] = useState('config_sistema');

  const DADOS_VENCIMENTO = {
    PAR_NOME: 'DATA_VENCIMENTO_OPORTUNIDADE',
    PAR_ATIVO: vencerOpo,
    PAR_CONFIG: qtdeDiasVencimento,
  };

  function sucessoMsg(msg) {
    toast.success(`${msg}`, {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function erroAtualizarVencimento() {
    toast.error('Ops! Algo deu errado.', {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    let currentKey = sessionStorage.getItem('key');

    if (key !== currentKey && currentKey !== null) {
      setKey(currentKey);
    }

    axios
      .get('https://kaminocrm.com.br:7778/parametros/buscarParametros', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        const api = res.data[0];
        setVencerOpo(api.PAR_ATIVO);
        setQtdeDiasVencimento(api.PAR_CONFIG);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // axios
    //   .get("https://kaminocrm.com.br:7778/parametros/buscarPerfilDeAcesso", {
    //     headers: {
    //       Authorization: `Bearer ${JWT_TOKEN}`,
    //       user_login: LOGIN,
    //       ass_cod: ASS_COD,
    //     },
    //   })
    //   .then((res) => {
    //     setPerfil(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // axios
    //   .get("https://kaminocrm.com.br:7778/parametros/buscarGraficosDoSistema", {
    //     headers: {
    //       Authorization: `Bearer ${JWT_TOKEN}`,
    //       user_login: LOGIN,
    //       ass_cod: ASS_COD,
    //     },
    //   })
    //   .then((res) => {
    //     setGraficos(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get('https://kaminocrm.com.br:7778/parametros/buscarImagensDoSistema', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        let CAPA_ASSOCIACAO = res.data.filter((value) => {
          return value.IMG_DESCRICAO === 'CAPA_ASSOCIACAO';
        });
        let CONTRA_CAPA_ASSOCIACAO = res.data.filter((value) => {
          return value.IMG_DESCRICAO === 'CONTRA_CAPA_ASSOCIACAO';
        });

        setCheckboxCapa(CAPA_ASSOCIACAO[0].ATIVO);
        setCheckboxContraCapa(CONTRA_CAPA_ASSOCIACAO[0].ATIVO);
        setLinkCapaExiste(CAPA_ASSOCIACAO[0].IMG_URL);
        setLinkContraCapaExiste(CONTRA_CAPA_ASSOCIACAO[0].IMG_URL);

        // if (linkCapaExiste === "" && capaEnviada === null) {
        //   console.log("capa vazia")
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    sessionStorage.setItem('key', key);
  }, [key]);

  const enviarVencimento = () => {
    if (
      linkCapaExiste?.length > 0 &&
      capaEnviada === null &&
      linkContraCapaExiste?.length > 0 &&
      contraCapaEnviada === null
    ) {
      setLoading(true);
    }
    axios
      .post(
        'https://kaminocrm.com.br:7778/parametros/atualizarParametros',
        DADOS_VENCIMENTO,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then(() => {
        sucessoMsg('Dados atualizados com sucesso!');
        if (
          linkCapaExiste?.length > 0 &&
          capaEnviada === null &&
          linkContraCapaExiste?.length > 0 &&
          contraCapaEnviada === null
        ) {
          setTimeout(function () {
            setLoading(false);
          }, 4000);
          if (!loading) {
            window.location.reload();
          }
        }
      })
      .catch(() => {
        erroAtualizarVencimento();
        setLoading(false);
      });
  };

  function addProgress(event) {
    let progressLoad = Math.round((event.loaded * 100) / event.total);
    setLoadingNumbered(true);
    setProgress(progressLoad);
    return progressLoad;
  }

  const enviarImagemCapa = async (apagar) => {
    if (linkCapaExiste?.length > 0 && capaEnviada === null && !apagar) {
      return;
    } else if (capaEnviada || apagar) {
      await axios
        .post(
          'https://kaminocrm.com.br:7778/parametros/configurarCapaAssociacao',
          { BASE_64: capaEnviada, ATIVO: checkboxCapa },
          {
            onUploadProgress: (event) => {
              addProgress(event);
            },
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setLoading(false);
          setTimeout(function () {
            setLoadingNumbered(false);
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const enviarImagemContraCapa = async (apagar) => {
    if (
      linkContraCapaExiste?.length > 0 &&
      contraCapaEnviada === null &&
      !apagar
    ) {
      return;
    } else if (contraCapaEnviada || apagar) {
      await axios
        .post(
          'https://kaminocrm.com.br:7778/parametros/configurarContraCapaAssociacao',
          { BASE_64: contraCapaEnviada, ATIVO: checkboxContraCapa },
          {
            onUploadProgress: (event) => {
              addProgress(event);
            },
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setTimeout(function () {
            setLoadingNumbered(false);
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  // function graficosJaRegistrados(PER_ID) {
  //   axios
  //     .post(
  //       "https://kaminocrm.com.br:7778/parametros/buscarGraficosPorPerfilDeAcesso",
  //       { PER_ID: PER_ID },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${JWT_TOKEN}`,
  //           user_login: LOGIN,
  //           ass_cod: ASS_COD,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setGraficoSelecionado(res.data.graficosIdsENomes);
  //     })
  //     .catch((err) => {
  //       setGraficoSelecionado([]);
  //     });
  // }

  function pegarImagem(ev) {
    var img = ev.target.files[0];
    if (img.size <= 10 * 1024 * 1024) {
      function imgB64(element) {
        var reader = new FileReader();
        reader.onloadend = function () {
          if (ev.target.name === 'capa-file') {
            setCapaEnviada(reader.result);
          }
          if (ev.target.name === 'contra_capa-file') {
            setContraCapaEnviada(reader.result);
          }
        };
        reader.readAsDataURL(element);
      }
      imgB64(img);
      setEnableImg(true);
    } else {
      setEnableImg(false);
    }
  }

  const enviarAlteracoes = () => {
    if (vencerOpo) {
      enviarVencimento();
    }
    enviarImagemCapa(!checkboxCapa);
    enviarImagemContraCapa(!checkboxContraCapa);
  };

  return (
    <Container>
      {loading ? <Loading /> : null}
      {loadingNumbered && <LoadingNumbered progress={progress} />}

      <Header />

      {PER_ID ? (
        <>
          {modalImagemCapa ? (
            <ModalImagem
              closeModal={setModalImagemCapa}
              imagem={linkCapaExiste}
            />
          ) : (
            ''
          )}
          {modalImagemContraCapa ? (
            <ModalImagem
              closeModal={setModalImagemContraCapa}
              imagem={linkContraCapaExiste}
            />
          ) : (
            ''
          )}

          <ToastContainer />
          {/* body */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
              marginTop: '-15px ',
              backgroundImage:
                'linear-gradient(transparent, #f8f8f8, #f3f3f3, #f8f8f8, white)',
              zIndex: '-999',
            }}
          >
            <CardParametros>
              <ModalHeader style={{ border: '0', padding: '0' }}>
                <h2>Ajustes</h2>
              </ModalHeader>

              <Tabs
                defaultActiveKey='config_sistema'
                transition={false}
                activeKey={key}
                onSelect={(k) => setKey(k)}
                id='fill-tab-example'
                // fill
              >
                <Tab eventKey='config_sistema' title='Configurações do sistema'>
                  <TabContent>
                    {/* //// VENCIMENTO //// */}
                    <h3>Oportunidades geradas</h3>
                    <div className='child-card'>
                      <div className='exp-opo'>
                        <input
                          type='checkbox'
                          name='exp-opo'
                          id='exp-opo'
                          checked={vencerOpo ? true : false}
                          value={vencerOpo}
                          style={{ marginRight: '6px' }}
                          onChange={() => {
                            setVencerOpo(!vencerOpo);
                          }}
                        />{' '}
                        <label htmlFor='exp-opo'>
                          Definir expiração para as oportunidades pendentes
                        </label>
                      </div>

                      {vencerOpo && (
                        <div style={{ marginTop: '15px' }}>
                          <label style={{ marginRight: '6px' }}>
                            Mudar para{' '}
                            <span style={{ color: '#dc1c4c' }}>PERDIDA</span>{' '}
                            após
                          </label>
                          <InputNumber
                            type='number'
                            min='1'
                            value={qtdeDiasVencimento}
                            style={{ width: '5%' }}
                            onChange={(e) => {
                              setQtdeDiasVencimento(e.target.value);
                            }}
                          />{' '}
                          <label htmlFor=''> dias.</label>
                        </div>
                      )}
                    </div>

                    <Line style={{ backgroundColor: '#efefef' }}></Line>

                    {/* //// GRÁFICOS //// */}
                    {/* <h3>Gráficos por permissão de usuário</h3>
                    <div className="child-card">
                      <div className="grap-p-usr">
                        <div style={{ marginRight: "10px" }}>
                          <label htmlFor="perfil-usuario">
                            Perfil de usuário
                          </label>
                          <select
                            name="perfil-usuario"
                            id="perfil-usuario"
                            value={perfilSelecionado}
                            onChange={(e) => {
                              setPerfilSelecionado(e.target.value);
                              graficosJaRegistrados(e.target.value);
                            }}
                          >
                            <option value="">Selecione...</option>
                            {perfil.map((i) => {
                              return <option value={i.value}>{i.name}</option>;
                            })}
                          </select>
                        </div>

                        <div>
                          <label htmlFor="perfil-usuario">
                            Gráficos disponíveis
                          </label>
                          <MultiSelect
                            options={graficos}
                            value={graficoSelecionado}
                            onChange={setGraficoSelecionado}
                            labelledBy="Selecione..."
                            className="rmsc"
                            overrideStrings={{
                              allItemsAreSelected:
                                "Todos os itens selecionados.",
                              clearSearch: "Limpar pesquisa...",
                              clearSelected: "Limpar selecionados...",
                              noOptions: "Sem opções disponíveis...",
                              search: "Pesquise...",
                              selectAll: "Selecionar todos",
                              selectAllFiltered: "Selecionar todos (PESQUISA)",
                              selectSomeItems: "Selecione...",
                              create: "Create",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <Line style={{ backgroundColor: "#efefef" }}></Line> */}

                    {/* //// PDF //// */}
                    <h3>Impressão de oportunidades</h3>
                    <div className='child-card'>
                      <div className='impressao-opo'>
                        <div>
                          <input
                            type='checkbox'
                            name='imp-capa'
                            id='imp-capa'
                            value={checkboxCapa}
                            checked={checkboxCapa ? true : false}
                            onChange={() => {
                              setCheckboxCapa(!checkboxCapa);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <label htmlFor='imp-capa'>Imprimir capa</label>
                        </div>
                        {checkboxCapa ? (
                          <div>
                            {linkCapaExiste ? (
                              ''
                            ) : (
                              <input
                                type='file'
                                name='capa-file'
                                id='capa-file'
                                accept='image/png, image/jpeg'
                                onChange={(ev) => pegarImagem(ev)}
                              />
                            )}

                            {linkCapaExiste ? (
                              <>
                                <Tippy content='Visualizar imagem' delay='275'>
                                  <BotaoDeletarImagem
                                    onClick={() => {
                                      setModalImagemCapa(true);
                                    }}
                                  >
                                    <MdVisibility size={18} />
                                  </BotaoDeletarImagem>
                                </Tippy>

                                <Tippy
                                  content='Deletar imagem atual'
                                  delay='275'
                                >
                                  <BotaoDeletarImagem
                                    onClick={() => {
                                      setCapaEnviada('');
                                      enviarImagemCapa(true);
                                      // setLoading(true);
                                    }}
                                  >
                                    <HiTrash size={18} />
                                  </BotaoDeletarImagem>
                                </Tippy>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='impressao-opo'>
                        <div>
                          <input
                            type='checkbox'
                            name='imp-contra_capa'
                            id='imp-contra_capa'
                            value={checkboxContraCapa}
                            checked={checkboxContraCapa ? true : false}
                            onChange={() => {
                              setCheckboxContraCapa(!checkboxContraCapa);
                            }}
                            style={{ marginRight: '6px' }}
                          />
                          <label htmlFor='imp-contra_capa'>
                            Imprimir contra-capa
                          </label>
                        </div>
                        {checkboxContraCapa ? (
                          <div>
                            {linkContraCapaExiste ? (
                              ''
                            ) : (
                              <input
                                type='file'
                                name='contra_capa-file'
                                id='contra_capa-file'
                                accept='image/png, image/jpeg'
                                onChange={(ev) => pegarImagem(ev)}
                              />
                            )}

                            {linkContraCapaExiste ? (
                              <>
                                <Tippy content='Visualizar imagem' delay='275'>
                                  <BotaoDeletarImagem
                                    onClick={() =>
                                      setModalImagemContraCapa(true)
                                    }
                                  >
                                    <MdVisibility size={18} />
                                  </BotaoDeletarImagem>
                                </Tippy>

                                <Tippy
                                  content='Deletar imagem atual'
                                  delay='275'
                                >
                                  <BotaoDeletarImagem
                                    onClick={() => {
                                      setContraCapaEnviada(null);
                                      enviarImagemContraCapa(true);
                                      // setLoading(true);
                                    }}
                                  >
                                    <HiTrash size={18} />
                                  </BotaoDeletarImagem>
                                </Tippy>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      {!enableImg ? (
                        <span
                          style={{
                            color: 'red',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            fontSize: '13px',
                            fontWeight: '600',
                          }}
                        >
                          Uma das imagens é maior que 10MB!
                        </span>
                      ) : (
                        ''
                      )}
                    </div>

                    <div
                      style={{
                        padding: '5px 12px',
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      <ButtonSalvarSenha
                        onClick={() => {
                          // valoresGraficos();
                          enviarAlteracoes();
                          // setLoading(true);
                        }}
                        disabled={enableImg ? false : true}
                      >
                        Salvar alterações
                      </ButtonSalvarSenha>
                    </div>
                  </TabContent>
                </Tab>

                <Tab eventKey='usuarios' title='Usuários'>
                  <TabContent>
                    <Usuarios />
                  </TabContent>
                </Tab>

                <Tab eventKey='vistorias' title='Vistorias'>
                  <TabContent>
                    <Vistorias />
                  </TabContent>
                </Tab>

                <Tab eventKey='tarefas' title='Tarefas'>
                  <TabContent>
                    <Tarefas />
                  </TabContent>
                </Tab>
                <Tab eventKey='origem' title='Origem do Lead'>
                  <TabContent>
                    <Origem />
                  </TabContent>
                </Tab>

                <Tab eventKey='tabela_descontos' title='Tabela de Descontos'>
                  <TabContent>
                    <TabelaDescontos />
                  </TabContent>
                </Tab>

                <Tab eventKey='modelos' title='Modelos'>
                  <TabContent>
                    <Templates />
                  </TabContent>
                </Tab>

                <Tab eventKey='automacoes' title='Automações'>
                  <TabContent>
                    <Automacoes />
                  </TabContent>
                </Tab>

                <Tab eventKey='funis' title='Funil de vendas'>
                  <TabContent>
                    <Funis />
                  </TabContent>
                </Tab>
              </Tabs>
            </CardParametros>
          </div>
        </>
      ) : (
        navigate('/home/dashboard')
      )}
    </Container>
  );
}

export default Parametros;
