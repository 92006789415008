import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BackgroundModal,
  BtnSubmit,
  ModalBoxCliente,
  BtnRecusar,
  ModalHeader,
  X,
} from "../../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { ButtonEncerrar } from "../../../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import ConfirmarArquivar from "../ConfirmarArquivar/ConfirmarArquivar";

function NovaFiliacao({
  openModal,
  feedback,
  filId,
  setFilId,
  setLoading,
  handleArchive,
}) {
  const DEFAULT_VALUES = {
    FIL_NOME: "",
    FIL_PORCENTAGEM: 0,
    FIL_VEN_IDS: [],
    FIL_TIPO_VEICULO: {},
  };

  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [filiacaoDados, setFiliacaoDados] = useState(DEFAULT_VALUES);
  const [tiposVeiculo, setTiposVeiculo] = useState([]);
  const [vendedores, setVendedores] = useState([]);

  async function requestsAPI() {
    await axios
      .get("https://kaminocrm.com.br:7778/veiculos/buscarTiposDeVeiculos", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setTiposVeiculo(res.data.tipos);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get("https://kaminocrm.com.br:7778/parametros/listarVendedores", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setVendedores(res.data);
      })
      .catch((err) => console.error(err));

    if (filId !== "") {
      setLoading(true);
      await axios
        .get(
          `https://kaminocrm.com.br:7778/parametros/buscarDadosDaTabelasDeFiliacao?fil_id=${filId}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          setFiliacaoDados({
            FIL_NOME: res.data?.dados[0].FIL_NOME,
            FIL_PORCENTAGEM: Number(res.data?.dados[0].FIL_PORCENTAGEM),
            FIL_VEN_IDS: res.data?.dados[0].FIL_VEN_IDS,
            FIL_TIPO_VEICULO: res.data?.dados[0].FIL_TIPO_VEICULO,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          feedback("Ops, algo deu errado!", "error");
          openModal(false);
        });
    }
  }

  useEffect(() => {
    requestsAPI();
  }, []);

  const isFormValid = () => {
    const { FIL_NOME, FIL_PORCENTAGEM, FIL_TIPO_VEICULO, FIL_VEN_IDS } =
      filiacaoDados;
    return (
      FIL_NOME !== "" &&
      FIL_PORCENTAGEM !== 0 &&
      FIL_PORCENTAGEM <= 100 &&
      Object.keys(FIL_TIPO_VEICULO).length !== 0 &&
      FIL_VEN_IDS.length !== 0
    );
  };

  function closeModal() {
    openModal(false);
    setFilId("");
    setFiliacaoDados(DEFAULT_VALUES);
  }

  async function handleSubmit() {
    if (filId === "") {
      await axios
        .post(
          "https://kaminocrm.com.br:7778/parametros/criarTabelaDeFiliacao",
          filiacaoDados,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          feedback("Filiação criada com sucesso", "success");
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data);
          feedback(err.response.data.message, "error");
        });
    } else {
      const dataEdit = { ...filiacaoDados, FIL_ID: filId };
      await axios
        .post(
          "https://kaminocrm.com.br:7778/parametros/editarTabelaDeFiliacao",
          dataEdit,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          feedback("Filiação editada com sucesso", "success");
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          feedback(err.response.data.message, "error");
        });
    }
  }

  return (
    <BackgroundModal>
      {openArchiveModal && (
        <ConfirmarArquivar
          openArchiveModal={setOpenArchiveModal}
          handleArchive={handleArchive}
          filId={filId}
          setLoading={setLoading}
        />
      )}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Criar novo desconto de filiação</h2>
          <X onClick={() => closeModal()}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="criar--body">
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="descricao_filiacao">Descrição</label>
              <input
                type="text"
                className="input_criar"
                name="descricao_filiacao"
                id="descricao_filiacao"
                value={filiacaoDados.FIL_NOME}
                onChange={(e) =>
                  setFiliacaoDados((prev) => ({
                    ...prev,
                    FIL_NOME: e.target.value,
                  }))
                }
              />
            </div>
            <div className="input_container">
              <label htmlFor="porcentagem_filiacao">Porcentagem máxima</label>
              <input
                type="text"
                pattern="\d*"
                className="input_criar"
                value={filiacaoDados.FIL_PORCENTAGEM}
                onChange={(e) => {
                  const { value } = e.target;
                  const pattern = /^\d*$/;
                  const maxLength = 3;

                  if (pattern.test(value) && value.length <= maxLength) {
                    setFiliacaoDados((prev) => ({
                      ...prev,
                      FIL_PORCENTAGEM: Number(e.target.value),
                    }));
                  } else {
                    return;
                  }
                }}
              />
            </div>
          </div>
          <div className="criar--inputs">
            <div className="input_container">
              <label htmlFor="">Por vendedores</label>
              <MultiSelect
                options={vendedores}
                value={filiacaoDados.FIL_VEN_IDS}
                onChange={(e) => {
                  setFiliacaoDados((prev) => ({ ...prev, FIL_VEN_IDS: e }));
                }}
                labelledBy="Selecione..."
                className="multiselect_criar"
                overrideStrings={{
                  allItemsAreSelected: "Todos os itens selecionados.",
                  clearSearch: "Limpar pesquisa...",
                  clearSelected: "Limpar selecionados...",
                  noOptions: "Sem opções disponíveis...",
                  search: "Pesquise...",
                  selectAll: "Selecionar todos",
                  selectAllFiltered: "Selecionar todos (PESQUISA)",
                  selectSomeItems: "Selecione...",
                  create: "Create",
                }}
              />
            </div>
            <div className="input_container">
              <label htmlFor="">Por tipo de veículo</label>
              <Select
                options={tiposVeiculo}
                className="select_criar"
                value={filiacaoDados.FIL_TIPO_VEICULO}
                placeholder="Selecione..."
                onChange={(e) =>
                  setFiliacaoDados((prev) => ({
                    ...prev,
                    FIL_TIPO_VEICULO: e,
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="criar--footer">
          {filId !== '' && <BtnRecusar onClick={() => setOpenArchiveModal(true)}>Arquivar filiação</BtnRecusar>}
          <BtnSubmit onClick={() => handleSubmit()} disabled={!isFormValid()}>
            Salvar
          </BtnSubmit>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  );
}

export default NovaFiliacao;
