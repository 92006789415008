import React from 'react'
import { LoadingScreen } from './styledLoading'
// import { ClipLoader } from "react-spinners"
import loading from './assets/loading.gif'

function Loading() {
  return (
    <LoadingScreen>
      <img src={loading} alt="loading" />
    </LoadingScreen>
  )
}

export default Loading