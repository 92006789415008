import React from "react";
import IconAlerta from "../../assets/alerta.svg";
import { useNavigate } from "react-router-dom";

import "./style.css";

function OnboardingAlert({ type, setErrorDisplay }) {
  const navigate = useNavigate();
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  if (type === 'exist') {
    return (
        <div className="Overlay">
          <div className="ModalAlert__Container">
            <div className="OnboardingAlerts__Container">
              <img src={IconAlerta} alt="icone de alerta" />
              <h1 className="OnboardingAlerts__Title">Atenção</h1>
              <p className="OnboardingAlerts__Subtitle">
              Já existe plataforma configurada para o código informado! Clique no botão abaixo para acessar
              </p>
              <button onClick={() => navigate(`/${ASS_COD}`)}>Acessar</button>
            </div>
          </div>
        </div>
      )
  }

  if (type === 'invalid') {
    return (
        <div className="Overlay">
          <div className="ModalAlert__Container">
            <div className="OnboardingAlerts__Container">
              <img src={IconAlerta} alt="icone de alerta" />
              <h1 className="OnboardingAlerts__Title">Atenção</h1>
              <p className="OnboardingAlerts__Subtitle">
              O Código informado é inválido! Verifique o Código e tente novamente!
              </p>
              <button onClick={() => setErrorDisplay(false)}>OK</button>
            </div>
          </div>
        </div>
      )
  }

  
}

export default OnboardingAlert;
