import React, { useContext, useState } from 'react'
import ImgAvatar from '../../pages/Home/assets/icon-avatar-preferences.svg';
import './styles.css'
import { AuthContext } from '../../context/auth';

function ModalUserMenu() {
  const LOGIN = sessionStorage.getItem('LOGIN');
  const { PER_ID, logout } = useContext(AuthContext)

  return (
    <div className="DashboardUserMenu">
      <div className="DashboardUserMenu__Header">
        <img
          className="DashboardUserMenu__Img"
          src={ImgAvatar}
          loading="lazy"
        />
        <div className="DashboardUserMenu__Strings">
          <span className="DashBoardUserMenu__Header-Title">{LOGIN.toUpperCase()}</span>
          <span className="DashBoardUserMenu__Header-Subtitle">
            {PER_ID == 0 ? "VENDEDOR" : "ADMIN"}
          </span>
        </div>
      </div>
      <div className="DashboardUserMenu__Divider"></div>
      <div className="DashboardUserMenu__Content">
        {/* <button onClick={handleOpenModal}>Preferências</button> */}
        <button
          className="DashboardUserMenu__ButtonLeave"
          onClick={() => logout()}
        >
          SAIR
        </button>
      </div>
    </div>
  );
}

export default ModalUserMenu