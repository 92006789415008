import React, { useState, useEffect } from "react";
import axios from "axios";
import { FunilWrapper } from "../styledFunis";
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
} from "../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { IoMdClose } from "react-icons/io";
import Loading from "../../../Loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonSalvarSenha } from "../../../Home/HomeRoutes/Perfil/styledPerfil";
import { MultiSelect } from "react-multi-select-component";
import "../../../Home/HomeRoutes/Relatorios/multiselect.css";

function Novo({ openModal }) {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(false);
  const [nomeFunil, setNomeFunil] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [vendedoresEscolhidos, setVendedoresEscolhidos] = useState([]);
  const [erroNome, setErroNome] = useState(false);

  useEffect(() => {
    axios
      .get("https://kaminocrm.com.br:7778/parametros/listarVendedores", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setVendedores(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  function feedback(msg, type) {
    if (type === "success") {
      toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === "error") {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  // loading, toast, erro geral, erro nome existente
  async function criarFunil() {
    await axios
      .post(
        "https://kaminocrm.com.br:7778/parametros/criarFunil",
        { FUN_NOME: nomeFunil, VEN_IDS: vendedoresEscolhidos },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log(res);
        feedback("Funil criado com sucesso!", "success");
        setTimeout(function () {
          setLoading(false);
          openModal(false);
        }, 1000);
        if (!loading) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.message);
        const { data } = err.response;
        if (data.statusCode === 400) {
          setErroNome(true);
        } else {
          feedback("Algo deu errado!", "error");
        }
        setTimeout(function () {
          setLoading(false);
        }, 1000);
      });
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Criar novo funil</h2>
          <X onClick={() => openModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <FunilWrapper>
          <div className="wrapper">
            <div className="novo_funil_duo">
              <label htmlFor="nome_funil">Nome do funil</label>
              <input
                type="text"
                name="nome_funil"
                id="nome_funil"
                className="input"
                value={nomeFunil}
                onChange={(e) => {
                  setNomeFunil(e.target.value);
                }}
                onFocus={() => {
                  if (erroNome) {
                    setErroNome(false);
                  }
                }}
              />
              {erroNome ? (
                <span
                  style={{ color: "red", fontSize: "12px", fontWeight: "600" }}
                >
                  Nome já utilizado!
                </span>
              ) : (
                ""
              )}
              <div className="novo_funil-footer"></div>
            </div>
            <div className="novo_funil_duo">
              <label htmlFor="nome_funil">Usuários com acesso</label>
              <MultiSelect
                options={vendedores}
                value={vendedoresEscolhidos}
                onChange={setVendedoresEscolhidos}
                labelledBy="Selecione..."
                className="rmsc"
                overrideStrings={{
                  allItemsAreSelected: "Todos os itens selecionados.",
                  clearSearch: "Limpar pesquisa...",
                  clearSelected: "Limpar selecionados...",
                  noOptions: "Sem opções disponíveis...",
                  search: "Pesquise...",
                  selectAll: "Selecionar todos",
                  selectAllFiltered: "Selecionar todos (PESQUISA)",
                  selectSomeItems: "Selecione...",
                  create: "Create",
                }}
              />
                
              <div className="novo_funil-footer">
                <ButtonSalvarSenha
                  onClick={async () => {
                    await criarFunil();
                    setLoading(true);
                  }}
                  disabled={nomeFunil.length === 0 || vendedoresEscolhidos.length === 0 ? true : false}
                >
                  Salvar
                </ButtonSalvarSenha>
              </div>
            </div>
            
          </div>
        </FunilWrapper>
      </ModalBoxCliente>
    </BackgroundModal>
  );
}

export default Novo;
