import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContainerOnboard } from "../style";
import logo from "../assets/kamino_logo.svg";
import axios from "axios";
import OnboardingAlert from "../components/OnboardingAlert/OnboardingAlert";
import { EmpresaCadastroContext } from "../../../context/EmpresaCadastroContext";


function CodigoAssociacao() {
  const navigate = useNavigate();
  const { setEmpresaData } = useContext(EmpresaCadastroContext);
  const [associacaoCodigo, setAssociacaoCodigo] = useState("");
  const [typeError, setTypeError] = useState("");
  const [errorDisplay, setErrorDisplay] = useState(false);

  async function next(e, code) {
    e.preventDefault();
    await axios
      .get(
        `https://kaminocrm.com.br:8000/empresa/validarEmpresa?ass_cod=${code}`
      )
      .then((res) => {
        const { dados, status, message } = res.data;
        // console.log(dados[0]);
        // console.log(empresaData)
        if (status === 200 && message === "Empresa válida!") {
          setEmpresaData((prev) => ({
            ...prev,
            EMP_CNPJ: dados[0].CNPJ,
            EMP_ADICIONAIS: dados[0].EMP_ADICIONAIS,
            EMP_COD: dados[0].EMP_COD,
            EMP_ENDOR: dados[0].EMP_ENDOR,
            EMP_FONE: dados[0].EMP_FONE,
            EMP_NOME: dados[0].EMP_NOME,
            EMP_PLANOS: dados[0].EMP_PLANOS,
            EMP_VENDEDORES_URL: dados[0].EMP_VENDEDORES_URL,
            NABOO_URL: dados[0].NABOO_URL
          }))
          navigate("/onboarding/registro-usuario");
        }
      })
      .catch((err) => {
        const { status, message } = err.response.data;

        if (status === 400) {
          setTypeError("invalid");
        }

        if (status === 401 && message === "Empresa já cadastrada!") {
          setTypeError("exist");
          sessionStorage.setItem("ASS_COD", code);
        }

        if (status === 401 && message === "Empresa não válida!") {
          setTypeError("invalid");
        }

        setErrorDisplay(true);
      });
  }

  return (
    <ContainerOnboard>
      {errorDisplay && (
        <OnboardingAlert type={typeError} setErrorDisplay={setErrorDisplay} />
      )}

      <header className="onboarding-header animation_fadedown">
        <img src={logo} alt="logo_kamino" />
      </header>

      <main className="cadcodigo-body">
        <section className="cadcodigo-body__code">
          <div className="cadcodigo-form__header animation_fadeleft">
            <h1 className="title">Código da Associação</h1>
            <p className="subtitle">
              Insira abaixo o Código da Associação. O código é formado por 6
              caracteres!
            </p>
          </div>
          <form
            className="cadcodigo-form__body animation_fadetop"
            onSubmit={(e) => next(e, associacaoCodigo)}
          >
            <input
              type="text"
              className="code__input"
              placeholder="XXXXXX"
              maxLength="6"
              value={associacaoCodigo}
              onChange={(e) => setAssociacaoCodigo(e.target.value)}
            />
            {/* <span className="erro__codigo">Este campo é obrigatório.</span> */}
            <button
              className="btn__avançar"
              type="submit"
              style={{ marginTop: "3rem" }}
              disabled={associacaoCodigo.length < 6}
            >
              Avançar
            </button>
          </form>
        </section>
        <section className="cadcodigo-body__ui"></section>
      </main>
    </ContainerOnboard>
  );
}

export default CodigoAssociacao;
