import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import setCookie from "../hooks/cookies/setCookie";
import removeCookie from "../hooks/cookies/removeCookie";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [LOGIN, setLOGIN] = useState(null);
  const [loading, setLoading] = useState(Boolean);

  // permissão de usuário
  const [PER_ID, setPER_ID] = useState();
  // nome completo do associado
  const [USR_NOME, setUSR_NOME] = useState("");
  const [USR_LOGIN, setUSR_LOGIN] = useState("");

  const [FUN_ID, setFUN_ID] = useState('');

  const [alterarSenhaLogin, setAlterarSenhaLogin] = useState("");
  const [ip, setIP] = useState("");

  const [erroUserArquivado, setErroUserArquivado] = useState(false);
  const [erroLoginIncorreto, setErroLoginIncorreto] = useState(false);
  const [erroEmpresa, setErroEmpresa] = useState(false);

  const api = axios.create({
    baseURL: "https://kaminocrm.com.br:5001",
  });

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  const createSession = async (ASS_COD, LOGIN, SENHA) => {
    const res = await api
      .post("/usuario/login", { ASS_COD, LOGIN, SENHA }, { timeout: 10 * 1000 })
      .then((res) => {
        removeCookie(ASS_COD);
        setCookie("ASS_COD", ASS_COD);
        setLoading(false);
        return res;
      })
      .catch((err) => {
        if (err.response?.data) {
          const { data } = err?.response;
          let erroAPI = data?.message;
          if (erroAPI === "Usuário não está ativo!") {
            setErroUserArquivado(true);
          }
          if (erroAPI === "Senha inválida!") {
            setErroLoginIncorreto(true);
          }
          if (erroAPI === "Usuário não encontrado!") {
            setErroLoginIncorreto(true);
          }
          if (erroAPI === "Nehuma empresa encontrada") {
            setErroEmpresa(true);
          }
        }
        setLoading(false);
        return err;
      });
    return res;
  };

  useEffect(() => {
    const data = sessionStorage.getItem("PER_ID");
    if (data != null) setPER_ID(data);
    sessionStorage.removeItem("PER_ID");

    const user = sessionStorage.getItem("USR_NOME");
    if (user != null) setUSR_NOME(user);
    sessionStorage.removeItem("USR_NOME");

    const ipUser = sessionStorage.getItem("IP");
    if (ipUser != null) setIP(ipUser);
    sessionStorage.removeItem("IP");

    const usrLog = sessionStorage.getItem("USR_LOGIN");
    if (usrLog != null) setUSR_LOGIN(usrLog);
    sessionStorage.removeItem("USR_LOGIN");

    // const funil = sessionStorage.getItem('FUN_ID')
    // if (funil != null) setFUN_ID(funil);
    // sessionStorage.removeItem("FUN_ID");

    getData();

    let recoveredUser = sessionStorage.getItem("LOGIN");

    if (recoveredUser) {
      recoveredUser = JSON.stringify(recoveredUser);
      setLOGIN(JSON.parse(recoveredUser));
    }

    setLoading(false);
  }, []);

  const login = async (ASS_COD, LOGIN, SENHA) => {
    const response = await createSession(ASS_COD, LOGIN, SENHA);
    const loggedUser = response.data.dados_usuario.USR_LOGIN;
    const token = response.data.jwt;
    const vendedorId = response.data.dados_usuario.VEN_ID;
    const userId = response.data.dados_usuario.USR_ID;
    const senha = response.data.dados_usuario.USR_MUDAR_SENHA;

    setUSR_LOGIN(response.data.dados_usuario.USR_LOGIN);
    setPER_ID(response.data.dados_usuario.PER_ID);
    setUSR_NOME(response.data.dados_usuario.USR_NOME);
    setAlterarSenhaLogin(sessionStorage.setItem("ALTERAR_SENHA", senha));

    sessionStorage.setItem("LOGIN", `${loggedUser}`);
    sessionStorage.setItem("JWT", token);
    sessionStorage.setItem("ASS_COD", ASS_COD);
    sessionStorage.setItem("VEN_ID", vendedorId);
    sessionStorage.setItem("USR_ID", userId);
    sessionStorage.setItem("ALTERAR_SENHA", senha);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setLOGIN(loggedUser);
    setLoading(false);
    navigate("/home/dashboard");

    document.cookie = `ass_cod=${ASS_COD}`;
  };

  const logout = () => {
    const param = sessionStorage.getItem("ASS_COD");
    sessionStorage.clear();
    localStorage.clear();
    api.defaults.headers.Authorization = null;
    setLOGIN(null);
    setLoading(false);
    navigate(`/${param}`);
  };

  window.onbeforeunload = function () {
    sessionStorage.setItem("f5", true);
    sessionStorage.setItem("PER_ID", PER_ID);
    sessionStorage.setItem("USR_NOME", USR_NOME);
    sessionStorage.setItem("IP", ip);
    sessionStorage.setItem("USR_LOGIN", USR_LOGIN);
    // sessionStorage.setItem('FUN_ID', FUN_ID)
  };

  const versaoSistema = "0.0.2";

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!sessionStorage.getItem("LOGIN"),
        LOGIN,
        loading,
        setLoading,
        login,
        logout,
        PER_ID,
        USR_NOME,
        versaoSistema,
        erroUserArquivado,
        setErroUserArquivado,
        erroLoginIncorreto,
        setErroLoginIncorreto,
        erroEmpresa,
        setErroEmpresa,
        ip,
        USR_LOGIN,
        alterarSenhaLogin,
        setAlterarSenhaLogin,
        FUN_ID,
        setFUN_ID
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
