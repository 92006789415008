import styled from "styled-components";

export const CardParametros = styled.div`
  margin: 20px auto;
  padding: 27px;
  border-radius: 7px;
  width: 90%;
  background-color: #fefefe;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  display: block;

  h3 {
    margin: 1.5rem 0 1rem 0;
  }

  .child-card {
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 1.5rem;

    .exp-opo {
      display: flex;
      align-items: center;
    }

    .grap-p-usr {
      display: flex;
      justify-content: flex-start;
      align-items: center;


      @media only screen and (max-width: 610px) {
        display: block;
      }
    }

    .grap-p-usr div {
      width: 100%;
    }

    .grap-p-usr div label,
    .grap-p-usr div select {
      display: block;
    }

    .grap-p-usr div select {
      width: 100%;
      padding: 0 10px;
      border-radius: 4px;
      height: 30px;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;
      outline: 0;
      background-color: white;
      color: #000;
       font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 14px;
      -webkit-appearance: none;
      background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white;


      :focus-within {
        box-shadow: #4285f4 0 0 0 1px;
        border-color: #4285f4;
      }
    }

    .impressao-opo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 610px) {
        display: block;
      }
    }

    .impressao-opo div {
      margin: 0.25rem 0;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 610px) {
        display: block;
      }

      input[type="file"] {
        font-size: 13px;
         font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        margin-right: 0.5rem;
      }
    }
  }

  @media only screen and (max-width: 999px) {
    width: 90%;
  }

  @media only screen and (max-width: 610px) {
    width: 85%;
  }
`;

export const Senhas = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media only screen and (max-width: 999px) {
    flex-direction: column;
  }

  .item_senha{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  `

export const InputNumber = styled.input`
  padding: 3px 6px;
  border-radius: 4px;
  margin-left: 0.5rem;
  border: 1px solid #ccc;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  width: 15%;

  :hover {
    border: 1px solid lightblue;
  }

  :focus {
    outline: none;
  }
`;

export const BotaoDeletarImagem = styled.button`
  background-color: white;
  padding: 0.25rem;
  border-radius: 4px;
  width: 2rem;
  border: none;
  color: gray;

  :hover {
    color: #505050;
    background-color: #f5f5f5;
  }
`;
