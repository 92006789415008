import styled from "styled-components";

export const CarrosselContainer = styled.div`
  max-width: 100%;
  display: none;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    button{
        background-color: transparent;
        border: none;
    }
  }

  @media only screen and (max-width: 590px) {
    display: block;
  }
`;

export const Carrossel = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  max-height: 500px;
  
  .item {
    margin: 10px;
    width: 220px;
    flex: none;  
  }

  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    display: none;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;
    display: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    display: none;
  }
`;

export const CardValorPlanoCarrossel = styled.div`
  padding: 70px 10px 0px 10px;
  height: 100%;
  border: 1px solid #1768e4;
  border-radius: 10px;

  ul {
    min-height: fit-content;
    max-height: 100%;
    list-style-type: none;
    padding-inline-start: 0;
    overflow-y: scroll;
    margin: 9px;

    /* width */
    ::-webkit-scrollbar {
      width: 2px;
      display: none;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #eee;
      display: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ddd;
      display: none;
    }
  }

  ul li {
    margin: 8px 3px 15px 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid #efefef;
    font-size: 13px;
  }
`;
