import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "./style/index.css"

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
