import styled from "styled-components";
import { COLORS } from "../../../../../../../colors";
import InputMask from "react-input-mask";

export const VistoriaContainer = styled.div`
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  min-height: 200vh;
  max-height: fit-content;
  width: 100%;
  max-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  z-index: 99;

  @media only screen and (max-width: 610px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 30em 0 50em 0;
  }

  @media only screen and (max-width: 324px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 20em 0 50em 0;
  }
`;

export const VistoriaScreen = styled.div`
  background-color: ${COLORS.light};
  padding: 40px;
  width: 95vw;
  min-height: 100vh;
  max-height: fit-content;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 610px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
  }
`;

export const VistoriaBody = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 15px;
  margin: 0 90px;
  padding: 0 60px;
  /* background-color: red; */

  @media only screen and (max-width: 999px) {
    margin: 0;
    padding: 0;
  }

`;

export const VistoriaSectionForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .card-resumo {
    padding: 2.3rem;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 0px 0px 7px #ddd;
  }

  span {
    margin-right: 15px;
  }

  p {
    margin: 0;
  }

  .vist-ven {
    input {
      width: 99%;
    }
    select {
      width: 100%;
    }
  }

  @media only screen and (max-width: 999px) {
    display: block;
    width: 85%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 620px) {
    display: block;
    width: 85%;
    margin: 0;
  }
`;

export const SectionForm = styled.div`
  display: flex;
  width: 99%;

  @media only screen and (max-width: 999px) {
    display: block;
  }
`;

export const InputWrapper = styled.div`
  width: 50%;
  padding: 0 0.5rem;

  .error_input{
    border: 1px solid red;
    transition: 0.6s;
  }

  .error{
    color: red;
    font-weight: 600;
    font-size: 12px;
  }

  @media only screen and (max-width: 999px) {
    width: 100%;
    padding: 0;
  }

  label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #5e5e5e;
  }

  input,
  input[type="number"] {
    padding: 4px 0 4px 8px;
    border-radius: 2px;
    border: 1px solid #ccc;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 99%;
    display: block;
    text-transform: uppercase;

    :focus {
      outline: none;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    padding: 4px 0 4px 8px;
    border-radius: 2px;
    border: 1px solid #ccc;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    resize: none;
    display: block;
    text-transform: uppercase;

    :focus {
      outline: none;
    }
  }

  select {
    padding: 0.1rem 1rem 0.3rem 1rem;
    margin: 0;
    border-radius: 2px;
    border: 1px solid #ccc;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    display: block;
    background-color: white;
    color: #000;
    background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white;
    -webkit-appearance: none;

    :focus {
      outline: none;
    }
  }
`;

export const Line = styled.div`
  height: 1px;
  border-radius: 20px;
  display: block;
  width: 100%;
  background-color: #eee;
  margin: 25px 0 15px 0;
`;

export const InputComMascara = styled(InputMask)`
  padding: 4px 0 4px 8px;
  border-radius: 2px;
  border: 1px solid #ccc;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  width: 100%;
  display: block;

  :focus {
    outline: none;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-content: center;
  padding: 30px 7px;
`;

export const PlanosLista = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;

  li {
    padding-inline-start: 0;
    padding: 4px 0;
    display: flex;
    align-items: center;

    .icon{
      margin-right: 0.3rem;
      padding: 0;
    }
  }
`;

export const TokenWrapper = styled.div`
  text-align: center;

  h1 {
    margin: none;
  }
`;
