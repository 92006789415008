import axios from 'axios';
import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  X,
} from '../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal';
import { ButtonSalvarSenha } from '../../../Home/HomeRoutes/Perfil/styledPerfil';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../Loading/Loading';
import { ButtonEncerrar } from '../../../Home/HomeRoutes/Oportunidades/Data Table/ResumoTable/styledResumoTable';

function ConfirmarArquivar({
  setOpenModalArchive,
  setOpenModalCreate,
  MENSAGEM_ID,
}) {
  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');
  const [loading, setLoading] = useState(false);

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function arquivarTemplate() {
    await axios
      .post(
        `https://kaminocrm.com.br:7778/parametros/arquivarOuDesarquivarMensagem?mensagem_id=${MENSAGEM_ID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log(res);
        feedback('Mensagem arquivado com sucesso', 'success');
        setOpenModalArchive(false);
        setOpenModalCreate(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        feedback('Ops! Algo deu errado.', 'error');
        setOpenModalArchive(false);
        setOpenModalCreate(false);
        setLoading(false);
      });
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Deseja arquivar?</h2>
          <X
            onClick={() => {
              setOpenModalArchive(false);
            }}
          >
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className='modalBody'>
          <p>
            Deseja arquivar este modelo? Automações futuras não poderão
            utilizar este modelo.
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px',
              gap: '0.5rem',
            }}
          >
            <button
              className='btnClose'
              // style={{
              //   padding: "0.5rem 0.75rem",
              //   fontSize: "14px",
              //   marginBottom: "0",
              // }}
              onClick={() => {
                setOpenModalArchive(false);
              }}
            >
              Cancelar
            </button>
            <ButtonEncerrar
              style={{ marginBottom: '0' }}
              onClick={() => {
                setLoading(true);
                arquivarTemplate();
              }}
            >
              Arquivar
            </ButtonEncerrar>
          </div>
        </div>
      </ModalBoxConfirmar>
    </BackgroundModal>
  );
}

export default ConfirmarArquivar;
