import React from 'react'
import "./style.css"
import code from "../../assets/codigo.svg"
import user from "../../assets/usr.svg"
import res from "../../assets/save.svg"

function Card({ title, descrp }) {

    function icon(name){
        if(name === "Código"){
            return code
        } else if (name === "Usuário"){
            return user
        } else {
            return res
        }
    }
  return (
    <div className="card_onboard">
        <img src={icon(title)} alt="codigo" />
        <span className='span__title'>{title}</span>
        <span className='span__content'>{descrp}</span>
    </div>
  )
}

export default Card