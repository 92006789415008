import React, { useEffect, useState } from "react";
import {
  BackgroundModal,
  ModalHeader,
  ModalBoxCliente,
  X,
} from "../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { IoMdClose } from "react-icons/io";
import { ClipLoader } from "react-spinners";

function ModalImagem({ closeModal, imagem }) {
  const [loaded, setLoaded] = useState(false);

  const handleLoadImg = () => {
    setLoaded(true)
  }

  return (
    <BackgroundModal>
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Imagem</h2>
          <X onClick={() => closeModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <img src={imagem} alt="img_capa" onLoad={handleLoadImg}/>

        {!loaded && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "25px 0",
            }}
          >
            <ClipLoader color={"#cccc"} size={30} />
          </div>
        )}

      </ModalBoxCliente>
    </BackgroundModal>
  );
}

export default ModalImagem;
