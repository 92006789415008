import React, { useState, createContext } from 'react'

export const VistoriaContext = createContext()

const VistoriaContextProvider = ({ children }) => {

  //Dados do Plano
  const [planoEscolhido, setPlanoEscolhido] = useState("")
  const [adicionaisPlanos, setAdicionaisPlanos] = useState([])

  // Dados do Cliente
  const [nomeCliente, setNomeCliente] = useState("")
  const [cpfCliente, setCPFCliente] = useState("")

  const [cep, setCEP] = useState("")
  const [endereco, setEndereco] = useState("")
  const [numero, setNumero] = useState("")
  const [complemento, setComplemento] = useState("")
  const [bairro, setBairro] = useState("")
  const [estado, setEstado] = useState("")
  const [cidade, setCidade] = useState("")
  const [cidCodigo, setCidCodigo] = useState(0)
  const [email, setEmail] = useState("")
  const [celular, setCelular] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [telFixo, setTelFixo] = useState("")

  // -- CPF
  const [dataNascimento, setDataNascimento] = useState("")
  const [sexo, setSexo] = useState("")
  const [profissao, setProfissao] = useState("")
  const [estadoCivil, setEstadoCivil] = useState("")
  const [identidade, setIdentidade] = useState("")
  const [orgaoEmissor, setOrgaoEmissor] = useState("")
  const [numCNH, setNumCNH] = useState("")
  const [categoriaCNH, setCategoriaCNH] = useState("")
  const [apelido, setApelido] = useState("")

  // -- CNPJ
  const [inscricaoEstadual, setInscricaoEstadual] = useState("")
  const [razaoSocial, setRazaoSocial] = useState("")
  const [nomeResponsavelLegal, setNomeResponsavelLegal] = useState("")
  const [cpfResponsavel, setCPFResponsavel] = useState("")
  const [identidadeResponsavel, setIdentidadeResponsavel] = useState("")
  const [emailResponsavel, setEmailResponsavel] = useState("")
  const [celularResponsavel, setCelularResponsavel] = useState("")
  const [nascimentoResponsavel, setNascimentoResponsavel] = useState("")


  // Dados do Veículo
  const [placa, setPlaca] = useState("")
  const [renavam, setRenavam] = useState("")
  const [chassi, setChassi] = useState("")
  const [categoria, setCategoria] = useState("PARTICULAR")
  const [anoFab, setAnoFab] = useState("")
  const [anoModelo, setAnoModelo] = useState("")
  const [cor, setCor] = useState("")
  const [alienado, setAlienado] = useState(false)
  const [estadoVeiculo, setEstadoVeiculo] = useState("")
  const [cidadeVeiculo, setCidadeVeiculo] = useState("")
  const [cidCodigoVeiculo, setCidCodigoVeiculo] = useState(0)
  const [documentoProprietario, setDocumentoProprietario] = useState("")
  const [nomeProprietario, setNomeProprietario] = useState("")
  const [marca, setMarca] = useState("")
  const [codMarca, setCodMarca] = useState("")
  const [modelo, setModelo] = useState("")
  const [codModelo, setCodModelo] = useState("")
  const [anoModeloCombustivel, setAnoModeloCombustivel] = useState("")
  const [codigoFipe, setCodigoFipe] = useState("")
  const [tipo, setTipo] = useState("")
  const [valorFipe, setValorFipe] = useState("")

  // Dados da Vistoria
  const [tipoVistoria, setTipoVistoria] = useState("FILIAÇÃO")
  const [vendedor, setVendedor] = useState("")
  const [observacao, setObservacao] = useState("")


  // -- JSON -- //

  // ---- DADOS PLANOS ---- //

  const dadosPlanos = {
    "PLANO_ESCOLHIDO": planoEscolhido,
    "ADICIONAIS": adicionaisPlanos
  }

  // ---- DADOS PESSOAIS ---- //

  const dadosCPF = {
    "IDENTIDADE": identidade,
    "DATA_DE_NASCIMENTO": dataNascimento,
    "SEXO": sexo,
    "PROFISSAO": profissao,
    "ESTADO_CIVIL": estadoCivil,
    "ORGAO_EMISSOR": orgaoEmissor,
    "NUMERO_CNH": numCNH,
    "CATEGORIA_CNH": categoriaCNH,
    "NOME_FANTASIA": apelido
  }
  
  const dadosCNPJ = {
    "INSCRICAO_ESTADUAL": inscricaoEstadual,
    "RAZAO_SOCIAL": razaoSocial,
    "NOME_RESPONSAVEL_LEGAL": nomeResponsavelLegal,
    "CPF_RESPONSAVEL": cpfResponsavel,
    "IDENTIDADE_RESPONSAVEL": identidadeResponsavel,
    "EMAIL_RESPONSAVEL": emailResponsavel,
    "CELULAR_RESPONSAVEL": celularResponsavel,
    "NASCIMENTO_RESPONSAVEL": nascimentoResponsavel,
  }

  const objetoDadosCPFouCNPJ = () => {
    if (cpfCliente.length < 14) {
      return dadosCPF
    } else {
      return dadosCNPJ
    }
  }

  const dadosPessoais = {
    "NOME": nomeCliente,
    "CPF_CNPJ": cpfCliente,
    "CEP": cep,
    "ENDERECO": endereco,
    "NUMERO_CASA": numero,
    "BAIRRO": bairro,
    "ESTADO": estado,
    "CIDADE": cidade,
    "CID_CODIGO": cidCodigo,
    "COMPLEMENTO": complemento,
    "EMAIL": email,
    "NUMERO_CELULAR": celular,
    "NUMERO_WHATSAPP": whatsapp,
    "TELEFONE_FIXO": telFixo,
    "DADOS_CPF_CNPJ": objetoDadosCPFouCNPJ(),
  }

  // ---- DADOS DO VEÍCULO ---- //

  function VEI_SEGMENTO() {
    if (tipo === "CARROS") {
      var seg = "1"
      return seg
    } else if (tipo === "MOTOS") {
      var seg = "2"
      return seg
    } else if (tipo === "CAMINHOES") {
      var seg = "3"
      return seg
    }
  }

  const dadosVeiculo = {
    "PLACA": placa,
    "RENAVAM": renavam,
    "CHASSI": chassi,
    "MARCA": marca,
    "MODELO": modelo,
    "ANO_MODELO": anoModelo,
    "CODIGO_MARCA": codMarca,
    "CODIGO_MODELO": codModelo,
    "ANO_FABRICACAO": anoFab,
    "ANO_MODELO_COMBUSTIVEL": anoModeloCombustivel,
    "CATEGORIA_VEICULO": categoria,
    "SEGMENTO": VEI_SEGMENTO(),
    "CODIGO_TABELA_FIPE": codigoFipe,
    "VALOR_FIPE": valorFipe,
    "COR": cor,
    "ALIENADO": alienado,
    "ESTADO_VEICULO": estadoVeiculo,
    "CIDADE_VEICULO": cidadeVeiculo,
    "DOCUMENTO_PROPRIETARIO": documentoProprietario,
    "NOME_PROPRIETARIO": nomeProprietario
  }

  // ---- DADOS DA VISTORIA ---- //

  const dadosVistoria = {
    "TIPO_VISTORIA": tipoVistoria,
    "VISTORIADOR_RESPONSAVEL": vendedor,
    "OBSERVACAO": observacao
  }

  // ---- TODOS OS DADOS (RESUMO E API) ---- //

  const dadosGerais = {
    "DADOS_PLANOS": dadosPlanos,
    "DADOS_PESSOAIS": dadosPessoais,
    "DADOS_VEICULO": dadosVeiculo,
    "DADOS_VISTORIA": dadosVistoria
  }


  return (
    <VistoriaContext.Provider value={{
      // PROVIDER PLANOS
      planoEscolhido, setPlanoEscolhido,
      adicionaisPlanos, setAdicionaisPlanos,

      // PROVIDER DADOS PESSOAIS
      nomeCliente, setNomeCliente,
      cpfCliente, setCPFCliente,
      cep, setCEP,
      endereco, setEndereco,
      numero, setNumero,
      complemento, setComplemento,
      bairro, setBairro,
      estado, setEstado,
      cidade, setCidade,
      cidCodigo, setCidCodigo,
      email, setEmail,
      celular, setCelular,
      whatsapp, setWhatsapp,
      telFixo, setTelFixo,

      // PROVIDER DADOS PESSOAIS || CPF
      dataNascimento, setDataNascimento,
      sexo, setSexo,
      profissao, setProfissao,
      estadoCivil, setEstadoCivil,
      identidade, setIdentidade,
      orgaoEmissor, setOrgaoEmissor,
      numCNH, setNumCNH,
      categoriaCNH, setCategoriaCNH,
      apelido, setApelido,

      // PROVIDER DADOS PESSOAIS || CNPJ
      inscricaoEstadual, setInscricaoEstadual,
      razaoSocial, setRazaoSocial,
      nomeResponsavelLegal, setNomeResponsavelLegal,
      cpfResponsavel, setCPFResponsavel,
      identidadeResponsavel, setIdentidadeResponsavel,
      emailResponsavel, setEmailResponsavel,
      celularResponsavel, setCelularResponsavel,
      nascimentoResponsavel, setNascimentoResponsavel,

      // PROVIDER VEICULO
      placa, setPlaca,
      renavam, setRenavam,
      chassi, setChassi,
      categoria, setCategoria,
      anoFab, setAnoFab,
      anoModelo, setAnoModelo,
      cor, setCor,
      alienado, setAlienado,
      estadoVeiculo, setEstadoVeiculo,
      cidadeVeiculo, setCidadeVeiculo,
      cidCodigoVeiculo, setCidCodigoVeiculo,
      documentoProprietario, setDocumentoProprietario,
      nomeProprietario, setNomeProprietario,
      marca, setMarca,
      modelo, setModelo,
      anoModeloCombustivel, setAnoModeloCombustivel,
      codigoFipe, setCodigoFipe,
      tipo, setTipo,
      valorFipe, setValorFipe,
      codMarca, setCodMarca,
      codModelo, setCodModelo,

      // PROVIDER VISTORIA
      tipoVistoria, setTipoVistoria,
      vendedor, setVendedor,
      observacao, setObservacao,

      // JSONs 
      dadosPlanos,
      dadosPessoais,
      dadosVeiculo,
      dadosVistoria,
      dadosGerais
    }}>
      {children}
    </VistoriaContext.Provider>
  )
}

export default VistoriaContextProvider