import React, { useRef, useState, useEffect } from 'react';
import ImgAvatar from '../../pages/Home/assets/icon-avatar-preferences.svg';
import ModalUserMenu from './ModalUserMenu';

function UserMenu() {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showUserMenuDefault, setShowUserMenuDefault] = useState(false);
  const popoverRef = useRef();

  const handleActivateUserMenu = () => setShowUserMenu(!showUserMenu);
  const handleActivateUserMenuDefault = () =>
    setShowUserMenuDefault(!showUserMenuDefault);

  useEffect(() => {
    function handler(event) {
      if (!popoverRef.current?.contains(event.target) && showUserMenu) {
        handleActivateUserMenu();
      }

      if (!popoverRef.current?.contains(event.target) && showUserMenuDefault) {
        handleActivateUserMenuDefault();
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [showUserMenu, showUserMenuDefault]);

  return (
    <li className="UserMenu" ref={popoverRef} onClick={handleActivateUserMenu}>
      <img src={ImgAvatar} onClick={handleActivateUserMenu} />
      {showUserMenu && <ModalUserMenu />}
    </li>
  );
}

export default UserMenu;
