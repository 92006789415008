import React, { useState, createContext } from "react";
// import axios from "axios";

export const CadastroAssociado = createContext();

const CadastroAssociadoProvider = ({ children }) => {

    const [CLI_NOME, setCLI_NOME] = useState("");
    const [CLI_CPF, setCLI_CPF] = useState("");
    const [CLI_TELEFONE, setCLI_TELEFONE] = useState("");
    const [CLI_EMAIL, setCLI_EMAIL] = useState("");


    return (
        <CadastroAssociado.Provider value={{CLI_NOME, CLI_CPF, CLI_TELEFONE, CLI_EMAIL, setCLI_NOME, setCLI_CPF, setCLI_TELEFONE, setCLI_EMAIL}}>
            {children}
        </CadastroAssociado.Provider>
    )
}

export default CadastroAssociadoProvider