import axios from "axios";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
  BackgroundModalConfirmacao,
  ModalBoxConfirmar,
  ModalHeader,
  X,
} from "../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import {
  ButtonSalvarSenha,
  Line,
} from "../../Home/HomeRoutes/Perfil/styledPerfil";
import Loading from "../../Loading/Loading";

function ConfirmarDesarquivar({
  closeModal,
  USR_ID,
  USR_LOGIN,
  USR_ATIVO,
  alteracaoFeita,
}) {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(false);

  const handleArquivar = () => {
    axios
      .post(
        "https://kaminocrm.com.br:5001/usuario/arquivarUsuario",
        { USR_ID, ARQUIVAR: USR_ATIVO },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        closeModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 700);
        alteracaoFeita();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function fecharModalBtn() {
    closeModal(false);
  }

  return (
    <BackgroundModalConfirmacao>
      {loading ? <Loading /> : null}
      <ModalBoxConfirmar>
        <ModalHeader>
          <h2>Deseja desarquivar?</h2>
          <X
            onClick={() => {
              fecharModalBtn();
            }}
          >
            <FaTimes size={17} />
          </X>
        </ModalHeader>

        <div className="modalBody">
          <p style={{ margin: "10px 0 20px 0", fontSize: "15px" }}>
            Gostaria de desarquivar o acesso de <strong>{USR_LOGIN}</strong>?
            Este usuário poderá voltar a usar a plataforma normalmente após esta
            ação.
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
            gap: "0.5rem",
          }}
        >
          <button
            className="btnClose"
            style={{ padding: "7px 25px", fontSize: "14px", marginBottom: "0" }}
            onClick={() => {
              fecharModalBtn();
            }}
          >
            Cancelar
          </button>
          <ButtonSalvarSenha
            style={{ marginBottom: "0" }}
            onClick={() => {
              setLoading(true);
              handleArquivar();
            }}
          >
            Desarquivar
          </ButtonSalvarSenha>
        </div>
      </ModalBoxConfirmar>
    </BackgroundModalConfirmacao>
  );
}

export default ConfirmarDesarquivar;
