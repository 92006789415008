import Cookie from "js-cookie";

const setCookie = (cookiename, usrin) => {
    Cookie.set(cookiename, usrin,{
        expires: 9999,
        secure: true,
        sameSite: 'strict',
        path: '/'
    })
}

export default setCookie