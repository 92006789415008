export const handleVistoriasId = (id) => {
    switch (id) {
        case '02c57c0e-069c-445d-896c-d60d21dc8380':
            return 'REPROVADA';
        case '9a97adeb-c43c-45a1-bfcb-02a277dd9e5f':
            return 'EM RESTRIÇÃO';
        case '8fbbc1f0-5b0f-4716-abf8-680ac0eb59ae':
            return 'APROVADA';
        case '1b4e63ee-c14c-491a-99dc-3fee31ad2867':
            return 'EM ANÁLISE';
        default: 
            return '';
    }
}