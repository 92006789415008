import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IoMdClose } from 'react-icons/io';
import { BiCheckbox, BiSolidCheckboxChecked } from 'react-icons/bi';
import {
  BackgroundModal,
  ModalBoxPlaca,
  BtnSubmit,
  ModalHeader,
  CloseLink,
  BtnVoltar,
} from './style/styledModal';
import Loading from '../../../../Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { CadastroVeiculo } from '../../../../../context/CadastroVEIContext';
import Implementos from './Implementos/Implementos';

function ModalImplementos() {
  const navigate = useNavigate();
  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');
  const VEI_SEGMENTO = sessionStorage.getItem('VEI_SEGMENTO');
  const { implementos, setImplementos, setValorTotalImplementos } =
    useContext(CadastroVeiculo);

  function somarValores(array, chave) {
    let soma = 0;
    if (array.length != 0) {
      for (const objeto of array) {
        let valor = objeto[chave];
        valor = valor.replace('R$', '').replace('.', '');
        if (valor.includes(',')) {
          valor = valor.replace(',', '.');
        }
        valor = Number(valor);

        soma += valor;
      }
    } else {
      soma = 0
    }
    console.log(soma);
    setValorTotalImplementos(soma);
    return soma;
  }

  function handleImplementos() {
    if (implementos.length > 0) {
      axios
        .post(
          'https://kaminocrm.com.br:7778/veiculos/criarImplementoVeiculo',
          implementos,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          console.log(res);
          somarValores(implementos, 'IMP_MENSALIDADE');
          navigate('/home/planos-veiculo');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate('/home/planos-veiculo');
    }
  }

  if (VEI_SEGMENTO === 'CAMINHOES') {
    return (
      <BackgroundModal>
        <ModalBoxPlaca>
          <ModalHeader>
            <h2>Implementos do veículo</h2>

            <CloseLink
              to='/home/oportunidades'
              onClick={() => setImplementos([])}
            >
              <IoMdClose size={17} />
            </CloseLink>
          </ModalHeader>
          <Implementos />

          <div
            className='modalFooter'
            style={{
              marginTop: '20px',
            }}
          >
            <BtnVoltar
              type='button'
              style={{ margin: '0 20px' }}
              onClick={() => navigate('/home/oportunidades/cadastro-cliente')}
            >
              Voltar
            </BtnVoltar>

            <BtnSubmit type='button' onClick={() => handleImplementos()}>
              Avançar
            </BtnSubmit>
          </div>
        </ModalBoxPlaca>
      </BackgroundModal>
    );
  } else {
    navigate('/home/planos-veiculo');
  }
}

export default ModalImplementos;
