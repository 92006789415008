import React, { useState, useContext, useEffect } from 'react';
import ImgLogo from './assets/kamino_logo.svg';
import ImgAvatarFemale from './assets/avatar-female.png';
import './style/styles.css';
import axios from 'axios';
import './style/LoginForm.css';
import { AuthContext } from '../../context/auth';
import logoIMG from './assets/kamino_logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../Loading/Loading';
import { VersionFooter } from '../Home/style/styledHomePage';
import getCookie from '../../hooks/cookies/getCookie';
import { DivErro } from '../Home/HomeRoutes/Perfil/styledPerfil';
import { useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Login() {
  const {
    authenticated,
    login,
    loading,
    setLoading,
    versaoSistema,
    erroUserArquivado,
    setErroUserArquivado,
    erroLoginIncorreto,
    setErroLoginIncorreto,
    erroEmpresa,
    setErroEmpresa,
  } = useContext(AuthContext);
  const [ASS_COD, setASS_COD] = useState('');
  const [LOGIN, setLOGIN] = useState('');
  const [SENHA, setSENHA] = useState('');
  const [bgImg, setBgImg] = useState('');

  const [empFalse, setEmpFalse] = useState(false);

  const MySwal = withReactContent(Swal);

  const { empresaCodigo } = useParams();
  // const { pathname } = useLocation();

  let cookieAssociacao = getCookie('ASS_COD');

  useEffect(async () => {
    setLoading(true);
    await axios
      .get(
        `https://kaminocrm.com.br:4021/empresa/validarEmpresa?EMP_COD=${empresaCodigo}`
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setEmpFalse(true);
        // MySwal.fire({
        //   title: "Atenção!",
        //   text: "Código da associação não encontrado.",
        //   icon: "warning",
        //   confirmButtonText: "Ok",
        // }).then((res) => {
        //   console.log(res);
        // });
      });
  }, []);

  useEffect(() => {
    if (cookieAssociacao) {
      setASS_COD(cookieAssociacao);
    }
    setLoading(true);

    axios
      .get('https://kaminocrm.com.br:2051/buscarBackground')
      .then((res) => {
        setBgImg(res.data.CAMINHO_FOTO);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(empresaCodigo, LOGIN, SENHA); //integração com o contexto e a api ;)
    setLoading(true);
  };

  return (
    <div className='Login__RouterContainer'>
      {loading && <Loading />}
      <ToastContainer />
      <header className='Onboarding__AnimaDown'>
        <img src={ImgLogo} alt='logo kamino' className='Login__Logo' />
      </header>

      <main className='LoginCredentials__Container'>
        <section className='LoginCredentials__Formulario'>
          <div className='LoginCredentials__Formulario--Header'>
            <h1 className='LoginCredentials__Formulario--Title Onboarding__AnimaLeft'>
              Login
            </h1>
            <p className='LoginCredentials__Formulario--Subtitle Onboarding__AnimaLeft'>
              Informe seu Usuário e Senha para acessar a Plataforma:
            </p>
          </div>

          <div className='LoginCredentials__FormularioBox'>
            <div className='ImageContainer'>
              <img
                src={ImgAvatarFemale}
                alt='imagem de uma mulher piscando com o olho esquerdo'
              />
            </div>

            <form
              className='LoginCredentials__Form Onboarding__AnimaLeft'
              onSubmit={handleSubmit}
            >
              <div className='LoginCredentials__Form--Field'>
                <input
                  required={true}
                  autoFocus={cookieAssociacao ? true : false}
                  type='text'
                  name='LOGIN'
                  value={LOGIN}
                  //    style={{ textTransform: "uppercase" }}
                  onChange={(e) => {
                    setLOGIN(e.target.value);
                    setErroUserArquivado(false);
                    setErroLoginIncorreto(false);
                    setErroEmpresa(false);
                  }}
                  onFocus={() => {
                    setErroUserArquivado(false);
                    setErroLoginIncorreto(false);
                    setErroEmpresa(false);
                  }}
                  disabled={empFalse}
                />
              </div>

              <div className='LoginCredentials__Form--Field'>
                <input
                  required={true}
                  type='password'
                  name='SENHA'
                  value={SENHA}
                  onChange={(e) => {
                    setSENHA(e.target.value);
                    setErroUserArquivado(false);
                    setErroLoginIncorreto(false);
                    setErroEmpresa(false);
                  }}
                  onFocus={() => {
                    setErroUserArquivado(false);
                    setErroLoginIncorreto(false);
                    setErroEmpresa(false);
                  }}
                  disabled={empFalse}
                />
              </div>

              <button type='submit' disabled={empFalse}>
                Entrar
              </button>
              {erroEmpresa && <DivErro>Associação não encontrada!</DivErro>}
              {erroUserArquivado && (
                <DivErro>Usuário com acesso bloqueado!</DivErro>
              )}
              {erroLoginIncorreto && (
                <DivErro>Usuário ou senha incorretos!</DivErro>
              )}
              {empFalse && (
                <DivErro>Código de associação não encontrado!</DivErro>
              )}
            </form>
          </div>
        </section>
        <section style={{ background: '#0049c4' }}></section>
      </main>
    </div>
  );
}

export default Login;
