import React from 'react';
import { Editor } from 'primereact/editor';
import TagsList from './TagsList/TagsList';


function ContentMessage({ template, setTemplate, tagList }) {
  const { TIPO_NAME } = template;

  const renderHeader = () => {
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold'></button>
        <button className='ql-italic' aria-label='Italic'></button>
      </span>
    );
  };

  const header = renderHeader();

  return (
    <div className='content_container'>
      <span style={{ fontSize: '1rem' }}>Criar modelo de mensagem</span>
      <div className='text_editor_container'>
        <div className='inputs_templates'>
          <input
            type='text'
            className='input_template_title'
            placeholder='Título do Template'
            value={template.MENSAGEM_TITULO}
            onChange={(e) => {
              setTemplate((prev) => ({
                ...prev,
                MENSAGEM_TITULO: e.target.value,
              }));
            }}
          />
          <input
            type='text'
            className='input_template_subtitle'
            placeholder='Descrição'
            value={template.MENSAGEM_DESCRICAO}
            onChange={(e) => {
              setTemplate((prev) => ({
                ...prev,
                MENSAGEM_DESCRICAO: e.target.value,
              }));
            }}
          />
        </div>
        <div className='editor__tags'>
          {TIPO_NAME === 'SMS' ? (
            <textarea
              className='textarea_sms'
              maxLength={160}
              placeholder='Conteúdo da mensagem... (Máx. 160 caracteres)'
              onChange={(e) => {
                setTemplate((prev) => ({
                  ...prev,
                  FORMATACAO: e.target.value,
                }));
              }}
            ></textarea>
          ) : (
            <div className='editor_container'>
            <Editor
              value={template.FORMATACAO}
              onTextChange={(e) => {
                setTemplate((prev) => ({
                  ...prev,
                  FORMATACAO: e.htmlValue,
                }));
              }}
              style={{ height: "400px" }}
              headerTemplate={TIPO_NAME === 'WHATSAPP' ? header : ''}
            />
            </div>
          )}

          <TagsList tagList={tagList} />
        </div>
      </div>
    </div>
  );
}

export default ContentMessage;
