export const COLORS = {
    primary: '#1768e4',
    primaryHover: '#0D32D3',
    dark: '#000000',
    light: '#FFFFFF',
    lightGray: '#CCCCCC',
    gray: '#AAAAAA',
    labelDark: '#534545',
    perdida: '#dc1c4c',
    pendente: '#237bcf',
    convertida: '#00B065',
    vistoria: '#572A8A',
    aprovada: "#1d0aad",
    semContato: "#5CA9B2",
}