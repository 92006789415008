import styled from "styled-components";
import { COLORS } from "../../../../../colors";

export const Container = styled.div`
  width: auto;
  height: 13rem;
  max-height: 13rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px;
  margin: 1rem;
  position: relative;

  .open {
    animation: showUp 0.5s forwards;
  }

  @keyframes showUp {
    from {
      width: 3rem;
    }
    to {
      width: 40%;
    }
  }
`;

export const Possibilidades = styled.div`
  padding: 10px;
   h3{
    margin: 0;
   }
   ul li{
    margin-bottom: 0.5rem;
   }
  `

export const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  /* padding: 1rem; */
`;

export const AddStep = styled.div`
  width: 3rem;
  height: 100%;
  flex-direction: column;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 20rem;
  max-width: 20rem; */
  height: 100%;
  flex-basis: 12rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const StepProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 15%;

  .add__button {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: grid;
    place-content: center;
    background-color: #19ca95;
    border: none;
    color: #fff;
    :hover {
      background-color: #0f9e8a;
      cursor: pointer;
    }
  }

  .arrows {
    position: absolute;
    z-index: 4;
    width: 30%;
    display: flex;
    top: 29px;

    .able {
      padding: 1px;
      display: flex;
      align-items: center;
      background-color: ${COLORS.primary};
      border: 2px solid #fff;
      color: #fff;
      border-radius: 10px;

      :hover {
        background-color: ${COLORS.primaryHover};
      }     
    }

    .not_able{
      padding: 1px;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 2px solid transparent;
      color: transparent;
      border-radius: 10px;
      }
  }

  .ball {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;

    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: grid;
    place-content: center;
    background-color: skyblue;

    /* ::before {
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: #fff;
    }

    :checked::before {
      transform: scale(1);
    } */
  }

  .line {
    height: 3px;
    width: 100%;
    background-color: skyblue;
    position: relative;
  }
  .line_button {
    height: 3px;
    width: 5px;
    background-color: skyblue;
    position: relative;
  }
`;

export const StepBox = styled.div`
  width: 80%;
  padding: 1rem 4rem 0 0.35rem;

  .titulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    p {
      display: inline;
      font-weight: 600;
      text-transform: capitalize;
      margin: 0;
    }

    
  }

  .acoes {
    width: 100%;
    .editar {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-weight: 600;
      background-color: #dee2e6;
       font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      border: none;
      padding: 0.3rem 0.4rem;
      border-radius: 0.4rem;

      :disabled{
        opacity: 0.999;
        cursor: not-allowed;
      }

      /* :hover {
        background-color: #b4bdcb;
      } */

      
    }

    .btn_excluir {
      display: flex;
      align-items: center;
      background-color: #ec707f;
      color: #fff;
      border: none;
      padding: 0.3rem 0.4rem;
      text-align: center;
      border-radius: 0.4rem;

      :hover {
        background-color: #a83667;
      }
    }
  }

  input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 85%;
    :focus {
      outline: none;
    }
  }

  .btn_salvar {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-weight: 600;
    background-color: #18AA8A;
    color: #fff;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    border: none;
    padding: 0.4rem 0.5rem;
    border-radius: 0.4rem;
    :hover {
      background-color: #0B8E7F;
    }
  }

  .adicionar {
    padding: 0.5rem;
    background-color: #a6b9d0;
    border: none;
    border-radius: 4px;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 600;
    color: #fff;

    :hover {
      background-color: #7993ba;
    }
  }

  .acoes {
    display: flex;
    gap: 0.5rem;
  }

  .poss{
    font-size: 12px;
    font-weight: 600;
    color: ${COLORS.primary};
    text-decoration: underline;

    :hover{
      cursor: pointer;
      color: ${COLORS.primaryHover};
    }
  }
`;
