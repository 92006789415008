import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
} from "../../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { IoMdClose } from "react-icons/io";
import Loading from "../../../Loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonSalvarSenha } from "../../../Home/HomeRoutes/Perfil/styledPerfil";
import { FunilWrapper } from "../../Funis/styledFunis";

function Novo({ openModal }) {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(false);
  const [nomeOrigem, setNomeOrigem] = useState("");
  const [erroNome, setErroNome] = useState(false);

  function feedback(msg, type) {
    if (type === "success") {
      toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === "error") {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  // loading, toast, erro geral, erro nome existente
  async function criarOrigem() {
    await axios
      .post(
        "https://kaminocrm.com.br:7778/parametros/criarOrigem",
        { ORG_DESCRICAO: nomeOrigem },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log(res);
        feedback("Origem criada com sucesso!", "success");
        setTimeout(function () {
          setLoading(false);
        }, 1000);
        if (!loading) {
          window.location.reload();
        }
      })
      .catch((err) => {
        const { data } = err.response;
        if (data.statusCode === 400) {
          setErroNome(true);
        } else {
          feedback("Algo deu errado!", "error");
        }
        setTimeout(function () {
          setLoading(false);
        }, 1000);
      });
  }

  return (
    <BackgroundModal>
      <ToastContainer />
      {loading && <Loading />}
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Registrar origem de lead</h2>
          <X onClick={() => openModal(false)}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>
        <FunilWrapper>
          <div className="novo_funil">
            <label htmlFor="origem">Título da origem de lead</label>
            <input
              type="text"
              name="origem"
              id="origem"
              value={nomeOrigem}
              onChange={(e) => {
                setNomeOrigem(e.target.value);
              }}
              onFocus={() => {
                if (erroNome) {
                  setErroNome(false);
                }
              }}
            />
            {erroNome ? (
              <span
                style={{ color: "red", fontSize: "12px", fontWeight: "600" }}
              >
                Nome já utilizado!
              </span>
            ) : (
              ""
            )}
            <div className="novo_funil-footer">
              <ButtonSalvarSenha
                onClick={async () => {
                  await criarOrigem();
                  setLoading(true);
                }}
                disabled={nomeOrigem.length === 0 ? true : false}
              >
                Salvar
              </ButtonSalvarSenha>
            </div>
          </div>
        </FunilWrapper>
      </ModalBoxCliente>
    </BackgroundModal>
  );
}

export default Novo;
