import React, { useState, useEffect } from "react";
import axios from "axios";
import { ButtonSalvarSenha } from "../../Home/HomeRoutes/Perfil/styledPerfil";
import Loading from "../../Loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { COLORS } from "../../../colors";
import { MdUnarchive, MdEdit } from "react-icons/md";
import { TableButton } from "../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable";
import ModalAlterar from "./ModalAlterar/ModalAlterar";
import ConfirmarDesarquivar from "./ConfirmarDesarquivar/ConfirmarDesarquivar";
import { FunilWrapper } from "../Funis/styledFunis";
 
import { BtnSubmit } from "../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal";
import { FaPlus } from "react-icons/fa";
import Novo from "./Novo/Novo";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";


function Origem() {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(false);

  const [nomeOrigem, setNomeOrigem] = useState("");
  const [origensCriadas, setOrigensCriadas] = useState([]);
  const [openModalAlterar, setOpenModalAlterar] = useState(false);
  const [openModalDesarquivar, setOpenModalDesarquivar] = useState(false);
  const [idOrigem, setIdOrigem] = useState("");
 
  const [modalNovo, setModalNovo] = useState(false)


  useEffect(() => {
    axios
      .get("https://kaminocrm.com.br:7778/parametros/listarOrigens", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setOrigensCriadas(res.data.origens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function feedback(msg, type) {
    if (type === "success") {
      toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === "error") {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function checarStatus(ORG_ATIVO) {
    if (ORG_ATIVO) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: "2px 10px",
            borderRadius: "10px",
            color: "white",
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          ATIVA
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: "2px 7px",
            borderRadius: "10px",
            color: "white",
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          ARQUIVADA
        </span>
      );
    }
  }
  return (
    <>
      {/* <ToastContainer /> */}

      {modalNovo && <Novo openModal={setModalNovo} />}

      <FunilWrapper>
        {openModalAlterar && (
          <ModalAlterar openModal={setOpenModalAlterar} ORG_ID={idOrigem} ORG_NOME={nomeOrigem} />
        )}
        {openModalDesarquivar && <ConfirmarDesarquivar openModal={setOpenModalDesarquivar} ORG_ID={idOrigem} />}
        {loading && <Loading />}

        <div className="criar">
          <BtnSubmit onClick={() => setModalNovo(true)}>
          <FaPlus size={11}/> <span>Adicionar origem de lead</span> 
          </BtnSubmit>
          </div>
       

        <h3>Gerenciar origens de lead</h3>
        <div className="funis_existem">
          <table>
            <thead>
              <tr>
                <th className="col_nome">Nome</th>
                <th className="col_status">Status</th>
                <th className="col_acoes">Ações</th>
              </tr>
            </thead>
            <tbody>
              {origensCriadas.map((i) => {
                return (
                  <tr key={i.ORG_ID}>
                    <td className="col_nome">{i.ORG_DESCRICAO}</td>
                    <td className="col_status">{checarStatus(i.ORG_ATIVO)}</td>
                    <td className="col_acoes">
                    {i.ORG_ATIVO ? (
                      <Tippy
                      content="Editar origem de lead"
                      delay="275"
                      placement="bottom"
                    > 
                        <TableButton
                          onClick={() => {
                            setOpenModalAlterar(true);
                            setIdOrigem(i.ORG_ID);
                            setNomeOrigem(i.ORG_DESCRICAO)
                            window.scrollTo(0, 0);
                          }}
                        >
                          <MdEdit size={20} />
                        </TableButton>
                        </Tippy>

                      ) : (
                        <Tippy
                        content="Desarquivar origem de lead"
                        delay="275"
                        placement="bottom"
                      > 
                        <TableButton
                        onClick={() => {
                          setOpenModalDesarquivar(true)
                          setIdOrigem(i.ORG_ID)
                          window.scrollTo(0, 0);
                        }}>
                          <MdUnarchive size={22} />
                        </TableButton>
                        </Tippy>

                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </FunilWrapper>
    </>
  );
}

export default Origem;
