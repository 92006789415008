import React, { createContext, useState } from "react";

export const CadastroVeiculo = createContext();

const CadastroVeiculoProvider = ({ children }) => {
  const [VEI_MARCA, setVEI_MARCA] = useState();
  const [VEI_MODELO, setVEI_MODELO] = useState();
  const [VEI_ANO, setVEI_ANO] = useState();
  const [zeroKM, setZeroKM] = useState(false);
  const [noFipe, setNoFipe] = useState(false);
  const [implementos, setImplementos] = useState([]);
  const [valorTotalImplementos, setValorTotalImplementos] = useState(0);

  return (
    <CadastroVeiculo.Provider
      value={{
        VEI_MARCA,
        VEI_MODELO,
        VEI_ANO,
        setVEI_MARCA,
        setVEI_MODELO,
        setVEI_ANO,
        zeroKM,
        setZeroKM,
        noFipe,
        setNoFipe,
        implementos,
        setImplementos,
        valorTotalImplementos, 
        setValorTotalImplementos
      }}
    >
      {children}
    </CadastroVeiculo.Provider>
  );
};

export default CadastroVeiculoProvider;
