import styled from "styled-components";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { COLORS } from "../../../../../../colors";
// AMBOS
export const BackgroundModal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
 

  @media only screen and (max-width: 610px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 6em 0 6em 0;
  }

  @media only screen and (max-width: 324px) {
    width: 300vw;
    height: 5000vh;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 20em 0 50em 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #d7d7d7;

  h2 {
    align-items: flex-start;
    margin: 0;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
`;

export const X = styled.a`
  background-color: transparent;
  padding: 0;
  border: none;
  color: rgb(73, 73, 73);

  :hover {
    cursor: pointer;
    color: rgb(14, 10, 10);
  }
`;

export const CloseLink = styled(Link)`
  background-color: transparent;
  padding: 0;
  border: none;
  color: rgb(73, 73, 73);

  :hover {
    cursor: pointer;
    color: rgb(14, 10, 10);
  }
`;

export const BtnSubmit = styled.button`
  text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 0.5rem 1rem;
  background-color: ${COLORS.primary};
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: ${COLORS.primaryHover};
  }

  :disabled {
    background-color: #1769e499;
    cursor: not-allowed;
  }
`;

export const BtnAgendar = styled.button`
  text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 7px 25px;
  background-color: #0b985b;
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: #0b6745;
  }
`;

export const BtnRecusar = styled.button`
  text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 7px 20px;
  background-color: #b10d19;
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: #7b0b1a;
  }
`;

export const BtnVoltar = styled.button`
  background: none;
  border: none;
  margin-bottom: 20px;
  padding: 0;
  color: #aaa;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;

  :hover {
    cursor: pointer;
    color: rgb(73, 73, 73);
  }
`;

// MODAL CLIENTE

export const ModalBoxCliente = styled.div`
  background-color: ${COLORS.light};
  margin: 7% auto 0 auto;
  padding: 25px;
  width: 32%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1440px) {
    width: 42%;
  }

  @media only screen and (max-width: 610px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
  }
`;

export const InputTEL = styled(InputMask)`
  display: inline-block;
  height: 25px;
  padding: 4px 7px;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 4px;
  margin: 10px 0px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 896px) {
    width: 93%;
  }

  @media only screen and (max-width: 622px) {
    width: 80%;
  }
`;

export const InputCPF = styled(InputMask)`
  display: inline-block;
  height: 25px;
  padding: 4px 7px;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 4px;
  margin: 10px 0px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;

  :focus {
    outline: none;
  }

  @media only screen and (max-width: 896px) {
    width: 93%;
  }

  @media only screen and (max-width: 622px) {
    width: 80%;
  }
`;

//MODAL PLACA

export const ModalBoxPlaca = styled.div`
  background-color: ${COLORS.light};
  margin: 7% auto 0 auto;
  padding: 25px;
  width: 32%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1440px) {
    display: flex;
    width: 45%;
    max-height: fit-content;
  }
  @media only screen and (max-width: 800px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
  }
`;

export const ModalBoxPlacaFIPE = styled.div`
  background-color: ${COLORS.light};
  margin: 7% auto 0 auto;
  padding: 25px;
  width: 32%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1440px) {
    display: flex;
    width: 45%;
    max-height: fit-content;
  }

  @media only screen and (max-width: 800px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
  }
`;

export const LinkManual = styled(Link)`
  color: ${COLORS.primary};
  font-weight: 300;
  font-size: 13px;

  :hover {
    color: ${COLORS.primaryHover};
  }
`;

export const BotaoZeroKM = styled.button`
  display: flex;
  align-items: center;
  padding: 0 4px;
  background-color: #fff;
  border: 0;
  gap: .24rem;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: #33353B;
`

export const BtnPesquisa = styled.button`
  background-color: ${COLORS.light};
  color: ${COLORS.lightGray};
  border: none;
  border-radius: 4px;
  padding: 10px;

  :hover {
    color: ${COLORS.gray};
    cursor: pointer;
  }
`;

export const Select = styled.select`
  margin: auto;
  padding: 10px;
  width: 99%;
  border: 1px solid #ccc;
  border-radius: 4px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  background-color: white;
  color: #000;
  -webkit-appearance: none;
  background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white;


  &:focus {
    outline: none;
  }
`;

//MODAL PLANOS

export const ModalBoxPlanos = styled.div`
  background-color: ${COLORS.light};
  margin: 7% auto 0 auto;
  padding: 25px;
  min-height: fit-content;
  width: 55%;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1440px) {
    display: flex;
    width: 60%;
    max-height: fit-content;
  }
  @media only screen and (max-width: 1270px) {
    display: flex;
    width: 90%;
    max-height: fit-content;
  }
`;

export const AccordionsWrapper = styled.div`
  padding: 20px;
  width: auto;
  /* margin: 0 auto; */
  border-radius: 7px;
  border: 1px solid #ddd;
  height: fit-content;
  flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 55%;

  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .accordion-itens {
    padding: 15px;
    height: 25rem;
    max-height: fit-content;
    overflow-y: scroll;
  }
`;

export const SelectPlanos = styled.select`
  margin: 2px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  &:focus {
    outline: none;
  }
`;

export const VL = styled.div`
  height: 40px;
  border-left: 1px solid #ccc;
  display: inline-block;
  /* margin: 5px;  */
`;

export const BotaoAdicionarPlano = styled.button`
  margin: 5px;
  background-color: white;
  display: inline-block;
  padding: 1px;
  border: none;
  color: #31303f;
  :hover {
    color: #000;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  display: block;
  width: 95%;
  height: 25px;
  padding: 1px 7px;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 4px;
  margin: 10px 0px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  :focus {
    outline: none;
  }

  :disabled {
    border: 1px solid #ccc;
    background-color: #eee;
  }

`;

export const DoubleInput = styled.div`
display: flex;
width: 100%;
gap: 0.75rem;

input{
  width: 94%;
}

@media only screen and (max-width: 625px) {
    display: block;
  }

.placa{
  width: 27%;
  @media only screen and (max-width: 625px) {
    width: 100%;
  }
}
.chassi{
  width: 77%;
  /* background-color: red; */

  @media only screen and (max-width: 625px) {
    width: 100%;
  }
}

.geral{
  width: 50%;
  @media only screen and (max-width: 625px) {
    width: 100%;
  }
}
`

export const SelectFipe = styled.select`
  margin: auto;
  padding: 10px 0;
  width: 96%;
  border: 1px solid #ccc;
  border-radius: 4px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  /* text-align: center; */
  text-align: -webkit-center;
  text-align-last: center;
  background-color: white;
  color: #000;
  -webkit-appearance: none;
  background: url(https://i.imgur.com/pz0fzEP.png) no-repeat right white;

  &:focus {
    outline: none;
  }
`;

export const InfoPlano = styled.div`
  ul {
    padding: 0;
    list-style-type: none;
  }
  ul li {
    text-align: left;
    padding: 0;
    list-style-type: none;
  }
`;

export const PlanosContainer = styled.div`
  width: auto;
  max-height: fit-content;
  /* overflow-y: scroll; */
  padding: 5px;
`;

export const ItensPlanoContainer = styled.div`
  margin: 5px;
  max-height: 80%;
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #d4d4d4;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.gray};
  }
  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }
  ul li {
    margin: 4px;
  }
`;

export const PlanoCard = styled.div`
  padding: 20px 10px;
  margin: 7px 0px;
  border-radius: 4px;
  background-color: ${COLORS.light};
  box-shadow: 1px 0px 4px ${COLORS.lightGray};
`;

export const PlanosEscolhidos = styled.div`
  /* margin: 0 auto; */
  /* margin-top: 0.5rem; */
  padding: 35px;
  max-height: fit-content;
  border-radius: 4px;
  border-radius: 7px;
  border: 1px solid #ddd;
  flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 45%;
 
  h3 {
    margin: 0;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 810px){
    margin-top: 1rem;
  }
`;

export const PlanoDetalhes = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.light};
  border-radius: 4px;
  box-shadow: 0px 1px 7px rgba(158, 149, 149, 0.1);
  margin: 5px 0px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #eee;
  border-left: 6px solid #22aad3;
  max-height: fit-content;
`;

export const BotaoDeletarPlano = styled.button`
  background-color: white;
  padding: 1px;
  border: none;
  color: #b10d19;
  :hover {
    cursor: pointer;
    color: #7b0b1a;
  }
`;

export const BtnAddPlano = styled.button`
  text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 7px 10px;
  background-color: #5067b6;
  border: none;
  color: white;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.3s;

  :hover {
    cursor: pointer;
    background-color: #3b4283;
  }
  :disabled {
    background-color: rgba(80, 104, 182, 0.6);
    cursor: not-allowed;
  }
`;
export const BtnAdicionais = styled.button`
  text-align: center;
  display: inline-block;
  margin: 5px;
  padding: 7px 10px;
  background-color: #fff;
  border: 1px solid ${COLORS.primary};
  color: ${COLORS.primary};
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.3s;

  :hover {
    cursor: pointer;
    border: 1px solid ${COLORS.primaryHover};
    color: ${COLORS.primaryHover};
    background-color: #e4f2fa;
  }
  :disabled {
    background-color: #f0f0f0;
    border: 1px solid #b6bbc6;
    color: #b6bbc6;
    cursor: not-allowed;
  }
`;

// // MODAL ADICIONAIS

export const ModalBoxAdicionais = styled.div`
  background-color: ${COLORS.light};
  margin: 9% auto 0 auto;
  padding: 25px;
  width: 60%;
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1000px) {
    display: flex;
    width: 90%;
    max-height: fit-content;
  }
`;

export const AdicionaisFlexWrapper = styled.div`
  display: flex;
  padding: 15px;

  @media only screen and (max-width: 610px) {
    display: block;
  }

  .adicionais,
  .valores {
    padding: 10px;
  }

  .valores {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 25%;

    h1,
    h3 {
      text-align: end;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    h3 {
      font-size: 13px;
      font-weight: 700;
    }

    .titulo {
      text-align: end;
      margin: 0;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
    }

    .confirmar-adicionais {
      text-align: center;
      display: inline-block;
      padding: 7px 25px;
      background-color: #18aa8a;
      border: none;
      color: #fff;
       font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 14px;
      font-weight: 700;
      border-radius: 4px;

      :hover {
        cursor: pointer;
        color: #fff;
        background-color: #156166;
      }
      :disabled {
        background-color: #28888fa2;
        color: #fff;
        cursor: not-allowed;
      }
    }

    @media only screen and (max-width: 610px) {
      display: block;
      width: 100%;
      padding-top: 25px;
    }
  }
  .adicionais {
    min-height: 14rem;
    max-height: 20rem;
    overflow-y: scroll;
    width: 75%;

    .adicionais_linha {
      margin-bottom: 0.75rem;
      display: block;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #ccc;

      input {
        margin: 0;
        margin-right: 0.4rem;
      }

      @media only screen and (max-width: 610px) {
        font-size: 0.7rem;
      }

      .itens_valor {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media only screen and (max-width: 610px) {
      display: block;
      width: 100%;
    }
  }
`;

//MODAL CADASTRO

export const SelectPerfil = styled.select`
  margin: auto;
  display: inline;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 610px) {
    width: 300px;
    margin-right: 40px;
  }
`;

//MODAL RESUMO

export const ModalBoxResumo = styled.div`
  background-color: ${COLORS.light};
  margin: 5% auto 0 auto;
  padding: 25px;
  width: 700px;
  max-width: fit-content;
  min-height: fit-content;
  max-height: fit-content;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 610px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
    padding: 40px;
  }
`;

export const BodyResumo = styled.div`
  height: 500px;
  width: auto;
  max-height: 650px;
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
  scroll-behavior: smooth;

  /* width */
  ::-webkit-scrollbar {
    width: 2px;
    display: none;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;
    display: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    display: none;
  }

  @media only screen and (max-width: 590px) {
    overflow-y: hidden;
    height: 580px;
    padding: 0px;
  }
`;

export const ResumoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;

  @media only screen and (max-width: 490px) {
    display: block;
  }
`;

export const GridWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;

  @media only screen and (max-width: 490px) {
    display: block;
  }

  div:nth-child(1) {
    max-width: 290px;
  }

  div:nth-child(2) {
    max-width: 190px;
  }
`;
export const ResumoPlanos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  max-height: 300px;
  width: auto;

  @media only screen and (max-width: 590px) {
    display: none;
    overflow: hidden;
  }
`;
export const ResumoParagrafo = styled.p`
  margin-top: -1px;
`;

export const ResumoTituloGrid = styled.h3`
  font-size: 17px;
  margin-bottom: 0.5rem;
`;

export const BottomResumo = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn-scroll {
    @media only screen and (max-width: 590px) {
      display: none;
    }
  }
`;

export const CardTodosPlanos = styled.div`
  display: inline-block;
  position: relative;
  padding: 7px 10px 0 10px;
  min-height: fit-content;
  width: 100%;
  margin-top: 10px;

  @media only screen and (max-width: 1200px) {
    height: 50%;
  }

  @media only screen and (max-width: 680px) {
    display: block;
    height: 40%;
  }
`;

export const CardTituloPlanos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  max-width: 75%;
  height: 85px;
  max-height: 85px;
  top: -5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  border-radius: 10px;

  background-color: ${COLORS.primary};
  color: #fff;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;

    @media only screen and (max-width: 710px) {
      text-align: center;
      margin: 0 auto;
    }
  }

  #titulo_plano {
    font-size: 12px;
    display: block;
  }

  #mensalidade {
    font-size: 20px;
    margin-top: 5px;
    display: block;
    font-weight: bolder;
  }

  span {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }
`;

export const CardValorPlano = styled.div`
  padding: 70px 10px 0px 10px;
  min-height: fit-content;
  /* max-height: 100%; */
  border: 1px solid ${COLORS.primary};
  border-radius: 10px;

  ul {
    min-height: fit-content;
    max-height: 100%;
    list-style-type: none;
    padding-inline-start: 0;
    overflow-y: scroll;
    margin: 9px;

    /* width */
    ::-webkit-scrollbar {
      width: 2px;
      display: none;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #eee;
      display: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ddd;
      display: none;
    }
  }

  ul li {
    margin: 8px 3px 15px 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid #efefef;
    font-size: 13px;
  }
`;

//CONFIRMACAO

export const BackgroundModalConfirmacao = styled.div`
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  height: 80em;
  max-height: 100em;
  width: 100%;
  max-width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  z-index: 999;

  @media only screen and (max-width: 610px) {
    width: 100%;
    height: 100em;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 2px;
  }

  @media only screen and (min-width: 380px) {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }
`;

export const ModalBoxConfirmar = styled.div`
  background-color: ${COLORS.light};
  margin: 9% auto 0 auto;
  padding: 25px;
  width: 27%;
  max-height: 26%;
  border-radius: 7px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1045px) {
    display: flex;
    width: 70%;
    max-height: fit-content;
  }
`;

// MODAL ALTERAR SENHA

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

  section {
    width: 75%;
    margin-bottom: 0.75rem;
  }

  div label {
    display: block;
  }

  .senha-diferente {
    border: 1px solid #D84040;
  }

  div input {
    display: inline-block;
    min-width: 80%;
    max-width: 80%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
     font-family: -apple-system, BlinkMacSystemFont, sans-serif;

    @media only screen and (max-width: 1200px) {
      min-width: 70%;
      max-width: 70%;
    }

    @media only screen and (max-width: 570px) {
      min-width: 65%;
      max-width: 65%;
    }

    :focus {
      outline: none;
    }
  }

  div button {
    background-color: #fff;
    border: none;
    padding: 0.65rem;
    color: #a5a5a5;
    :hover {
      color: #353535;
    }
    :focus {
      outline: none;
    }
  }
`;
