import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MenuTemplate from './MenuTemplate';
import IconClose from '../../../Home/assets/icon-close.svg';
import './styles.css';
import TypesList from './TemplateSteps/TypesList/TypesList';
import ContentMessage from './TemplateSteps/ContentMessage/ContentMessage';
import { toast } from 'react-toastify';
import ConfirmarArquivar from '../ConfirmarArquivar/ConfirmarArquivar';

function CreateTemplate({ setOpenModal, template, setTemplate }) {
  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  const [step, setStep] = useState(1);
  const [tiposTemplates, setTiposTemplates] = useState([]);
  const [openModalArchive, setOpenModalArchive] = useState(false);
  const [tagList, setTagList] = useState([]);

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info('Internal server error', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  useEffect(() => {
    axios
      .get('https://kaminocrm.com.br:7778/parametros/buscarTiposDeMensagens/', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setTiposTemplates(res.data.dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get('https://kaminocrm.com.br:7778/parametros/buscarTagsTemplate/', {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // console.log('tags', res)
        setTagList(res.data.dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function handleTemplate() {
    const dataSend =
      template.MENSAGEM_ID !== ''
        ? {
            TIPO_ID: template.TIPO_ID,
            MENSAGEM_TITULO: template.MENSAGEM_TITULO,
            MENSAGEM_DESCRICAO: template.MENSAGEM_DESCRICAO,
            MENSAGEM_ID: template.MENSAGEM_ID,
            FORMATACAO: template.FORMATACAO,
            LOGO_URL: template.LOGO_URL,
          }
        : {
            TIPO_ID: template.TIPO_ID,
            MENSAGEM_TITULO: template.MENSAGEM_TITULO,
            MENSAGEM_DESCRICAO: template.MENSAGEM_DESCRICAO,
            FORMATACAO: template.FORMATACAO,
            LOGO_URL: template.LOGO_URL,
          };

    const requestUrl =
      template.MENSAGEM_ID !== ''
        ? 'https://kaminocrm.com.br:7778/parametros/editarMensagem/'
        : 'https://kaminocrm.com.br:7778/parametros/criarMensagem/';

    await axios
      .post(requestUrl, dataSend, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        feedback(res.data.mensagem, 'success');
        setOpenModal(false);
        setTemplate({
          TIPO_ID: '',
          TIPO_NAME: '',
          MENSAGEM_TITULO: '',
          MENSAGEM_DESCRICAO: '',
          MENSAGEM_ID: '',
          FORMATACAO: '',
          LOGO_URL: '',
        });
      })
      .catch((err) => {
        console.log(err);
        feedback('Ops! Algo deu errado.', 'error');
      });
  }

  const handleDisabled = () => {
    const { TIPO_NAME, MENSAGEM_TITULO, MENSAGEM_DESCRICAO, FORMATACAO } =
      template;

    if (step === 1) {
      return TIPO_NAME === '';
    } else {
      return (
        TIPO_NAME === '' ||
        MENSAGEM_TITULO.length <= 6 ||
        MENSAGEM_DESCRICAO.length <= 6 ||
        FORMATACAO?.length <= 10 ||
        !FORMATACAO
      );
    }
  };

  function renderSteps() {
    switch (step) {
      case 1:
        return (
          <TypesList
            tiposTemplates={tiposTemplates}
            template={template}
            setTemplate={setTemplate}
          />
        );
      case 2:
        return (
          <ContentMessage
            template={template}
            setTemplate={setTemplate}
            tagList={tagList}
          />
        );
      default:
        return 'Passo inválido';
    }
  }

  return (
    <div className='bg_add Main__Fade'>
      {openModalArchive && (
        <ConfirmarArquivar
          setOpenModalArchive={setOpenModalArchive}
          setOpenModalCreate={setOpenModal}
          MENSAGEM_ID={template.MENSAGEM_ID}
        />
      )}
      <div className='templates_create_container'>
        <div className='modal__templates'>
          <div className='close__modal'>
            <img
              src={IconClose}
              alt='close'
              onClick={() => {
                setOpenModal(false);
                setTemplate({
                  TIPO_ID: '',
                  TIPO_NAME: '',
                  MENSAGEM_ID: '',
                  MENSAGEM_TITULO: '',
                  MENSAGEM_DESCRICAO: '',
                  FORMATACAO: '',
                  LOGO_URL: '',
                });
              }}
            />
          </div>
          <div className='templates__content'>
            <MenuTemplate step={step} templateId={template.MENSAGEM_ID} />
            <div className='templates__box'>
              {renderSteps()}
              <div className='btn__container'>
                {step === 2 && (
                  <button className='btn_voltar' onClick={() => setStep(1)}>
                    Voltar
                  </button>
                )}
                {template.MENSAGEM_ID !== '' && (
                  <button
                    className='btn_arquivar'
                    onClick={() => {
                      setOpenModalArchive(true)
                    }}
                  >
                    Arquivar
                  </button>
                )}
                <button
                  className='btn_avancar'
                  disabled={handleDisabled()}
                  onClick={() => {
                    step === 1 ? setStep(2) : handleTemplate();
                  }}
                >
                  {step === 1 ? 'Avançar' : 'Salvar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTemplate;
