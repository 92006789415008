import React, { useState, useEffect, useContext } from "react";
import { CadastroPlano } from "../../../../../context/CadastroPLAContext";
import { CadastroVeiculo } from "../../../../../context/CadastroVEIContext";
import axios from "axios";

import { IoMdClose } from "react-icons/io";
import { HiTrash } from "react-icons/hi";
import { AiOutlinePlusCircle, AiFillEdit } from "react-icons/ai";
import { BiMessageSquareCheck, BiDollarCircle } from "react-icons/bi";
import { FaPlus, FaCheck } from "react-icons/fa";
import { MdOutlineBookmarkAdd } from "react-icons/md";

import {
  BackgroundModal,
  ModalBoxPlanos,
  ModalHeader,
  CloseLink,
  BtnSubmit,
  PlanosEscolhidos,
  PlanoDetalhes,
  BtnVoltar,
  BtnAddPlano,
  BtnAdicionais,
  BotaoDeletarPlano,
  AccordionsWrapper,
} from "./style/styledModal";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Accordion from "react-bootstrap/Accordion";
import "./style/styledAccordion.css";

import Loading from "../../../../Loading/Loading";
import Adicionais from "./Adicionais/Adicionais";
import { ToastContainer, toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { handleClose } from "../../../../../services/handleClose";

const ModalPlanos = () => {
  const navigate = useNavigate();

  const { setIdPlano, setValoresPlano, adicionais, setAdicionais } =
    useContext(CadastroPlano);
  const { implementos, valorTotalImplementos } = useContext(CadastroVeiculo);

  const [loading, setLoading] = useState(true);

  const VEI_SEGMENTO = sessionStorage.getItem("VEI_SEGMENTO");
  const VEI_VALOR_FIPE = sessionStorage.getItem("VEI_VALOR_FIPE");

  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");
  const VEN_ID = sessionStorage.getItem("VEN_ID");

  const DEFAULT_VALUES = {
    filiacao: 0,
    cota_participacao_1: 0,
    cota_participacao_2: 0,
    mensalidade: 0,
  };

  const DEFAULT_ERRORS = {
    filiacao: "",
    cota_participacao_1: "",
    cota_participacao_2: "",
    mensalidade: "",
  };

  const DEFAULT_EDIT = {
    filiacao: false,
    cota_participacao_1: false,
    cota_participacao_2: false,
    mensalidade: false,
  };

  const [planosDisponiveis, setPlanosDisponiveis] = useState([]);
  const [valorAdesao, setValorAdesao] = useState(0);
  const [valores, setValores] = useState(DEFAULT_VALUES);
  const [editToggle, setEditToggle] = useState(DEFAULT_EDIT);
  const [id, setId] = useState("");
  const [mensalidade, setMensalidade] = useState(0);
  const [novaMensalidade, setNovaMensalidade] = useState(0);
  const [planoArray, setPlanoArray] = useState([]);
  const [closeModal, setCloseModal] = useState(false);

  const [errors, setErrors] = useState(DEFAULT_ERRORS);

  const [permissions, setPermissions] = useState({});

  const MySwal = withReactContent(Swal);

  function sucesso(msg) {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function erro(msg) {
    toast.error(msg, {
      position: "top-right",
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  async function buscarPlanosVeiculo() {
    await axios
      .get(
        `https://kaminocrm.com.br:7778/planos/buscarPlanos?segmento=${VEI_SEGMENTO}&preco=${VEI_VALOR_FIPE}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
            ven_id: VEN_ID,
          },
        }
      )
      .then((res) => {
        // console.log(res)
        setPlanosDisponiveis(
          res.data.map((el) => {
            return {
              ...el,
              filiacao_inicial: el.plan_valor_adesao,
              mensalidade_inicial: el.plan_valor_mensalidade,
              plan_valor_mensalidade: valorTotalImplementos > 0 ? el.plan_valor_mensalidade + valorTotalImplementos : el.plan_valor_mensalidade
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        const { data } = err.response;
        let erroAPI = data.message;
        console.log(erroAPI);
        if (erroAPI === "Internal server error") {
          MySwal.fire({
            title: "Atenção!",
            text: "Serviço indisponível no momento. Por favor, tente mais tarde.",
            icon: "warning",
            confirmButtonText: "Ok",
          }).then((res) => {
            // console.log(res);
            if (res) {
              navigate("/home/oportunidades");
            } else {
              // not clicked
            }
          });
        }
      });
  }

  async function buscarPermissoesDescontos() {
    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/validarSeOVendedorTemDescontos?ven_id=${VEN_ID}&tipo_veiculo=${VEI_SEGMENTO}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        console.log('perm',res.data)
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    buscarPlanosVeiculo();
    buscarPermissoesDescontos();
    setAdicionais([]);
  }, []);

  function adicionarPlano(plano) {
    let planosConfig = plano ? plano : "";
    // planosConfig.plan_valor_adesao = valorAdesao
    //   ? valorAdesao
    //   : planosConfig.plan_valor_adesao;
    planosConfig.plan_valor_adesao = Number(planosConfig.plan_valor_adesao);
    if (planoArray?.length < 3) {
      var contains = planoArray.some((elem) => {
        return plano.plan_id === elem.plan_id;
      });
      if (contains) {
        MySwal.fire({
          title: "Atenção!",
          text: "Esse plano já foi adicionado.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        setPlanoArray([...planoArray, plano]);
      }
    } else {
      MySwal.fire({
        title: "Atenção!",
        text: "É permitido somente até 3 planos por oportunidade.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  }

  async function handleDescontoFiliacao(data, plan) {
    const { fil_ven_id, fil_tipo_veiculo, valor_proposta, valor_plano } = data;

    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/validarValorFiliacaoDesconto?fil_ven_id=${fil_ven_id}&fil_tipo_veiculo=${fil_tipo_veiculo}&valor_proposta=${valor_proposta}&valor_plano=${valor_plano}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
            ven_id: VEN_ID,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setEditToggle((prev) => ({
          ...prev,
          filiacao: false,
        }));
        novaAdesao(plan);
        setValorAdesao(plan.plan_valor_adesao);
      })
      .catch((err) => {
        console.log(err);
        setErrors((prev) => ({
          ...prev,
          filiacao: "Valor de desconto inválido.",
        }));
      });
  }

  async function handleDescontoCotaParticipacao(data, plan, number) {
    const { cot_venId, cot_tipo_veiculo, valor_acordado, porcentagem } = data;

    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/validarValorCotaDeParticipacaoService?cot_venId=${cot_venId}&cot_tipo_veiculo=${cot_tipo_veiculo}&valor_acordado=${valor_acordado}&porcentagem=${porcentagem}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
            ven_id: VEN_ID,
          },
        }
      )
      .then((res) => {
        if (number === 1) {
          setEditToggle((prev) => ({
            ...prev,
            cota_participacao_1: false,
          }));
          novaCota(plan, 1);
          setValores((prev) => ({
            ...prev,
            cota_participacao_1: plan.plan_1_franquia,
          }));
        } else {
          setEditToggle((prev) => ({
            ...prev,
            cota_participacao_2: false,
          }));
          novaCota(plan, 2);
          setValores((prev) => ({
            ...prev,
            cota_participacao_2: plan.plan_2_franquia,
          }));
        }
      })
      .catch((err) => {
        if (number === 1) {
          setErrors((prev) => ({
            ...prev,
            cota_participacao_1: err.response?.data.message,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            cota_participacao_2: err.response?.data.message,
          }));
        }
      });
  }

  async function handleDescontoMensalidade(data, plan) {
    const { men_venid, men_tipo_veiculo, valor_proposta, valor_mensalidade } =
      data;

    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/validarValorMensalidade?men_venid=${men_venid}&men_tipo_veiculo=${men_tipo_veiculo}&valor_proposta=${valor_proposta}&valor_mensalidade=${valor_mensalidade}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
            ven_id: VEN_ID,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setEditToggle((prev) => ({
          ...prev,
          mensalidade: false,
        }));
        novaMensalidadeEdit(plan);
        setValores((prev) => ({
          ...prev,
          mensalidade: plan.plan_valor_mensalidade,
        }));
      })
      .catch((err) => {
        console.log(err);
        setErrors((prev) => ({
          ...prev,
          mensalidade: err.response?.data.message,
        }));
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let planosEscolhidosIDS = [];
    for (const element of planoArray) {
      planosEscolhidosIDS.push(element.plan_id);
    }
    var arrFiltrado = adicionais.filter((element) => {
      return planosEscolhidosIDS.includes(element.PLAN_ID);
    });

    const mensalidadeAtual = (posicao) => {
      return adicionais[posicao].NOVA_MENSALIDADE;
    };

    let planoIDs = [];
    let planosValorAdesao = [];
    for (const element of planoArray) {
      planoIDs.push(element.plan_id);
      planosValorAdesao.push({
        plan_id: element.plan_id,
        plan_valor_mensalidade: adicionais.map((i) => { return i.PLAN_ID }).includes(element.plan_id)
          ? mensalidadeAtual(
              adicionais
                .map((i) => {
                  return i.PLAN_ID;
                })
                .indexOf(element.plan_id)
            )
          : element.plan_valor_mensalidade,
        plan_valor_adesao: element.plan_valor_adesao,
      });
    }

    sessionStorage.setItem("PLANOS", JSON.stringify(planoArray));
    setIdPlano(planoIDs);
    setValoresPlano(planosValorAdesao);
    setAdicionais(arrFiltrado);
    navigate("/home/oportunidade-resumo");
  };

  const novaAdesao = (plano) => {
    var objIndex = planosDisponiveis.findIndex(
      (obj) => obj.plan_id === plano.plan_id
    );
    planosDisponiveis[objIndex].plan_valor_adesao = Number(valorAdesao);
  };

  const novaCota = (plano, number) => {
    var objIndex = planosDisponiveis.findIndex(
      (obj) => obj.plan_id === plano.plan_id
    );
    if (number === 1) {
      planosDisponiveis[objIndex].plan_1_franquia = valores.cota_participacao_1;
    } else {
      planosDisponiveis[objIndex].plan_2_franquia = valores.cota_participacao_2;
    }
  };

  const novaMensalidadeEdit = (plano) => {
    var objIndex = planosDisponiveis.findIndex(
      (obj) => obj.plan_id === plano.plan_id
    );
    planosDisponiveis[objIndex].plan_valor_mensalidade = Number(
      valores.mensalidade
    );
  };

  return (
    <BackgroundModal>
      {closeModal ? (
        <Adicionais
          closeModal={setCloseModal}
          PLAN_ID={id}
          PLAN_VALOR_MENSALIDADE={mensalidade}
          adicionaisArray={adicionais}
          novaMensalidade={setNovaMensalidade}
          sucesso={sucesso}
          erro={erro}
        />
      ) : (
        ""
      )}

      <ToastContainer />

      {loading ? <Loading /> : ""}
      <ModalBoxPlanos>
        <ModalHeader>
          <h2>Planos disponíveis</h2>
          <CloseLink to="/home/oportunidades" onClick={() => handleClose()}>
            <IoMdClose size={17} />
          </CloseLink>
        </ModalHeader>

        <div className="modalBody flex-modalBody">
          <AccordionsWrapper>
            <h3>Selecione os planos desejados</h3>
            <div className="accordion-itens">
              <Accordion defaultActiveKey="0">
                {planosDisponiveis.map((plano, index) => {
                  let item = plano.itens;
                  var itensAdicionais = adicionais.map((i) => {
                    return i.ADICIONAIS;
                  });
                  const mensalidadeAtual = (posicao) => {
                    return adicionais[posicao].NOVA_MENSALIDADE;
                  };

                  var mensalidadeTotal = adicionais
                    .map((i) => {
                      return i.PLAN_ID;
                    })
                    .includes(plano.plan_id)
                    ? Number(
                        mensalidadeAtual(
                          adicionais
                            .map((i) => {
                              return i.PLAN_ID;
                            })
                            .indexOf(plano.plan_id)
                        )
                      )
                    : plano.plan_valor_mensalidade;

                  // var mensalidadeComImplementos = mensalidadeTotal + valorTotalImplementos;

                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header
                        onClick={() => setEditToggle(DEFAULT_EDIT)}
                        className={
                          planoArray
                            .map((i) => {
                              return i.plan_descricao;
                            })
                            .includes(plano.plan_descricao)
                            ? "selecionado"
                            : ""
                        }
                      >
                        <span>
                          {planoArray
                            .map((i) => {
                              return i.plan_descricao;
                            })
                            .includes(plano.plan_descricao) ? (
                            <span style={{ marginRight: "0.5rem" }}>
                              <FaCheck size={11} />
                            </span>
                          ) : (
                            ""
                          )}
                          {plano.plan_descricao}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <strong>Itens</strong>
                        {item.map((i) => {
                          return (
                            <span className="item" key={i.item_descricao}>
                              <span className="icon">
                                <BiMessageSquareCheck size={15} />{" "}
                              </span>{" "}
                              {i.item_descricao
                                ? i.item_descricao[0].toUpperCase() +
                                  i.item_descricao.slice(1).toLowerCase()
                                : ""}
                            </span>
                          );
                        })}
                        {itensAdicionais?.length > 0 && (
                          <strong>Adicionais</strong>
                        )}
                        {adicionais
                          .map((i) => {
                            return i.PLAN_ID;
                          })
                          .includes(plano.plan_id)
                          ? itensAdicionais.map((i) => {
                              return i.map((item) => {
                                return (
                                  <span className="item" key={i.item_descricao}>
                                    <span
                                      style={{ marginRight: "5px" }}
                                      className="icon"
                                    >
                                      <AiOutlinePlusCircle size={15} />{" "}
                                    </span>
                                    {item.item_nome
                                      ? item.item_nome[0].toUpperCase() +
                                        item.item_nome.slice(1).toLowerCase()
                                      : ""}
                                  </span>
                                );
                              });
                            })[
                              [
                                adicionais
                                  .map((i) => {
                                    return i.PLAN_ID;
                                  })
                                  .indexOf(plano.plan_id),
                              ]
                            ]
                          : ""}
                        {implementos?.length > 0 && <strong>Implementos</strong>}
                        {implementos?.map((i, index) => {
                          return (
                            <span className="item" key={index}>
                              <span className="icon">
                                <MdOutlineBookmarkAdd size={17} />
                              </span>
                              {i.IMP_TIPO.toUpperCase()} (+{i.IMP_MENSALIDADE.includes('R$') ? i.IMP_MENSALIDADE : `R$${i.IMP_MENSALIDADE}`})
                            </span>
                          );
                        })}
                        <strong>Valores</strong>
                        <span className="item">
                          <span className="icon">
                            <BiDollarCircle size={17} />
                          </span>
                          Cota de participação 1: {plano.plan_1_franquia}%
                          {permissions.cotaDeParticipacao && (
                            <Tippy
                              content="Editar valor de filiação"
                              delay="275"
                            >
                              <button
                                onClick={() => {
                                  setEditToggle((prev) => ({
                                    ...prev,
                                    cota_participacao_1:
                                      !editToggle.cota_participacao_1,
                                  }));
                                  setValores((prev) => ({
                                    ...prev,
                                    cota_participacao_1: plano.plan_1_franquia,
                                  }));
                                  setErrors(DEFAULT_ERRORS);
                                }}
                                className="botao-editar"
                              >
                                <AiFillEdit size={15} />
                              </button>
                            </Tippy>
                          )}
                        </span>

                        {/* //// INPUT COM VALOR COTA 1 /// */}

                        {editToggle.cota_participacao_1 && (
                          <>
                            <div className="edit__valor__filiacao">
                              <span className="novo-valor">
                                Nova cota de participação:
                              </span>
                              <span id="editar" className="item">
                                <span
                                  id="input-wrapper"
                                  className="percentage_input"
                                >
                                  <input
                                    type="number"
                                    value={valores.cota_participacao_1}
                                    min={0}
                                    className="input-valor"
                                    onChange={(e) => {
                                      var posicaoId = planosDisponiveis
                                        .map((i) => {
                                          return i.plan_id;
                                        })
                                        .indexOf(plano.plan_id);
                                      var posicaoValorOriginal =
                                        planosDisponiveis
                                          .map((i) => {
                                            return i.plan_valor_adesao;
                                          })
                                          .indexOf(plano.plan_valor_adesao);
                                      if (posicaoId === posicaoValorOriginal) {
                                        setValores((prev) => ({
                                          ...prev,
                                          cota_participacao_1: e.target.value,
                                        }));
                                      }
                                    }}
                                    onFocus={() => {
                                      setErrors((prev) => ({
                                        ...prev,
                                        cota_participacao_1: "",
                                      }));
                                    }}
                                  />
                                  %
                                </span>

                                <button
                                  onClick={() => {
                                    const data = {
                                      cot_venId: VEN_ID,
                                      cot_tipo_veiculo: VEI_SEGMENTO,
                                      valor_acordado: VEI_VALOR_FIPE,
                                      porcentagem: valores.cota_participacao_1,
                                    };

                                    handleDescontoCotaParticipacao(
                                      data,
                                      plano,
                                      1
                                    );
                                  }}
                                  className="btn-valor salvar"
                                >
                                  Salvar
                                </button>

                                <button
                                  onClick={() => {
                                    setEditToggle((prev) => ({
                                      ...prev,
                                      cota_participacao_1: false,
                                    }));
                                  }}
                                  className="btn-valor cancelar"
                                >
                                  Cancelar
                                </button>
                              </span>
                            </div>

                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              {errors.cota_participacao_1}
                            </p>
                          </>
                        )}

                        <span className="item">
                          <span className="icon">
                            <BiDollarCircle size={17} />
                          </span>
                          Cota de participação 2: {plano.plan_2_franquia}%
                          {permissions.cotaDeParticipacao && (
                            <Tippy
                              content="Editar valor de filiação"
                              delay="275"
                            >
                              <button
                                onClick={() => {
                                  setEditToggle((prev) => ({
                                    ...prev,
                                    cota_participacao_2:
                                      !editToggle.cota_participacao_2,
                                  }));
                                  setValores((prev) => ({
                                    ...prev,
                                    cota_participacao_2: plano.plan_2_franquia,
                                  }));
                                  setErrors(DEFAULT_ERRORS);
                                }}
                                className="botao-editar"
                              >
                                <AiFillEdit size={15} />
                              </button>
                            </Tippy>
                          )}
                        </span>

                        {/* //// INPUT COM VALOR COTA 2 /// */}

                        {editToggle.cota_participacao_2 && (
                          <>
                            <div className="edit__valor__filiacao">
                              <span className="novo-valor">
                                Nova cota de participação:
                              </span>
                              <span id="editar" className="item">
                                <span
                                  id="input-wrapper"
                                  className="percentage_input"
                                >
                                  <input
                                    type="number"
                                    value={valores.cota_participacao_2}
                                    min={0}
                                    className="input-valor"
                                    onChange={(e) => {
                                      var posicaoId = planosDisponiveis
                                        .map((i) => {
                                          return i.plan_id;
                                        })
                                        .indexOf(plano.plan_id);
                                      var posicaoValorOriginal =
                                        planosDisponiveis
                                          .map((i) => {
                                            return i.plan_valor_adesao;
                                          })
                                          .indexOf(plano.plan_valor_adesao);
                                      if (posicaoId === posicaoValorOriginal) {
                                        setValores((prev) => ({
                                          ...prev,
                                          cota_participacao_2: e.target.value,
                                        }));
                                      }
                                    }}
                                    onFocus={() => {
                                      setErrors((prev) => ({
                                        ...prev,
                                        cota_participacao_2: "",
                                      }));
                                    }}
                                  />
                                  %
                                </span>

                                <button
                                  onClick={() => {
                                    const data = {
                                      cot_venId: VEN_ID,
                                      cot_tipo_veiculo: VEI_SEGMENTO,
                                      valor_acordado: VEI_VALOR_FIPE,
                                      porcentagem: valores.cota_participacao_2,
                                    };

                                    handleDescontoCotaParticipacao(
                                      data,
                                      plano,
                                      2
                                    );
                                  }}
                                  className="btn-valor salvar"
                                >
                                  Salvar
                                </button>

                                <button
                                  onClick={() => {
                                    setEditToggle((prev) => ({
                                      ...prev,
                                      cota_participacao_2: false,
                                    }));
                                  }}
                                  className="btn-valor cancelar"
                                >
                                  Cancelar
                                </button>
                              </span>
                            </div>

                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              {errors.cota_participacao_2}
                            </p>
                          </>
                        )}

                        <span className="item">
                          <span className="icon">
                            <BiDollarCircle size={17} />
                          </span>
                          <span style={{ marginRight: "5px" }}>
                            Mensalidade:
                          </span>

                          {mensalidadeTotal.toLocaleString("pt-BR", {
                            currency: "BRL",
                            style: "currency",
                            minimumFractionDigits: 2,
                          })}

                          {/* {implementos.length === 0
                            ? mensalidadeTotal.toLocaleString("pt-BR", {
                                currency: "BRL",
                                style: "currency",
                                minimumFractionDigits: 2,
                              })
                            : mensalidadeComImplementos.toLocaleString(
                                "pt-BR",
                                {
                                  currency: "BRL",
                                  style: "currency",
                                  minimumFractionDigits: 2,
                                }
                              )} */}

                          {permissions.mensalidade && (
                            <Tippy
                              content="Editar valor de filiação"
                              delay="275"
                            >
                              <button
                                onClick={() => {
                                  setEditToggle((prev) => ({
                                    ...prev,
                                    mensalidade: !editToggle.mensalidade,
                                  }));
                                  setValores((prev) => ({
                                    ...prev,
                                    mensalidade: mensalidadeTotal,
                                  }));
                                  setErrors(DEFAULT_ERRORS);
                                }}
                                className="botao-editar"
                              >
                                <AiFillEdit size={15} />
                              </button>
                            </Tippy>
                          )}
                        </span>

                        {/* //// INPUT COM VALOR MENSALIDADE //// */}

                        {editToggle.mensalidade && (
                          <>
                            <div className="edit__valor__filiacao">
                              <span className="novo-valor">
                                Nova mensalidade:
                              </span>
                              <span id="editar" className="item">
                                <span
                                  id="input-wrapper"
                                  className="money_input"
                                >
                                  R$
                                  <input
                                    type="number"
                                    value={valores.mensalidade}
                                    min={0}
                                    className="input-valor"
                                    onChange={(e) => {
                                      var posicaoId = planosDisponiveis
                                        .map((i) => {
                                          return i.plan_id;
                                        })
                                        .indexOf(plano.plan_id);
                                      var posicaoValorOriginal =
                                        planosDisponiveis
                                          .map((i) => {
                                            return i.plan_valor_adesao;
                                          })
                                          .indexOf(plano.plan_valor_adesao);
                                      if (posicaoId === posicaoValorOriginal) {
                                        setValores((prev) => ({
                                          ...prev,
                                          mensalidade: e.target.value,
                                        }));
                                      }
                                    }}
                                    onFocus={() => {
                                      setErrors((prev) => ({
                                        ...prev,
                                        mensalidade: "",
                                      }));
                                    }}
                                  />
                                </span>

                                <button
                                  onClick={() => {
                                    const data = {
                                      men_venid: VEN_ID,
                                      men_tipo_veiculo: VEI_SEGMENTO,
                                      valor_mensalidade:
                                        plano.mensalidade_inicial,
                                      valor_proposta: valores.mensalidade,
                                    };

                                    handleDescontoMensalidade(data, plano);
                                  }}
                                  className="btn-valor salvar"
                                >
                                  Salvar
                                </button>

                                <button
                                  onClick={() => {
                                    setEditToggle((prev) => ({
                                      ...prev,
                                      mensalidade: false,
                                    }));
                                  }}
                                  className="btn-valor cancelar"
                                >
                                  Cancelar
                                </button>
                              </span>
                            </div>

                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              {errors.mensalidade}
                            </p>
                          </>
                        )}

                        <span className="item">
                          <span className="icon">
                            <BiDollarCircle size={17} />
                          </span>
                          <span style={{ marginRight: "5px" }}>
                            Valor de filiação:
                          </span>

                          {plano.plan_valor_adesao.toLocaleString("pt-BR", {
                            currency: "BRL",
                            style: "currency",
                            minimumFractionDigits: 2,
                          })}

                          {permissions.descontoFiliacao && (
                            <Tippy
                              content="Editar valor de filiação"
                              delay="275"
                            >
                              <button
                                onClick={() => {
                                  setEditToggle((prev) => ({
                                    ...prev,
                                    filiacao: !editToggle.filiacao,
                                  }));
                                  setValorAdesao(plano.plan_valor_adesao);
                                  setErrors(DEFAULT_ERRORS);
                                }}
                                className="botao-editar"
                              >
                                <AiFillEdit size={15} />
                              </button>
                            </Tippy>
                          )}
                        </span>

                        {/* //// INPUT COM VALOR FILIAÇÃO /// */}

                        {editToggle.filiacao && (
                          <>
                            <div className="edit__valor__filiacao">
                              <span className="novo-valor">
                                Novo valor de filiação:
                              </span>
                              <span id="editar" className="item">
                                <span
                                  id="input-wrapper"
                                  className="money_input"
                                >
                                  R$
                                  <input
                                    type="number"
                                    value={valorAdesao}
                                    min={0}
                                    className="input-valor"
                                    onChange={(e) => {
                                      var posicaoId = planosDisponiveis
                                        .map((i) => {
                                          return i.plan_id;
                                        })
                                        .indexOf(plano.plan_id);
                                      var posicaoValorOriginal =
                                        planosDisponiveis
                                          .map((i) => {
                                            return i.plan_valor_adesao;
                                          })
                                          .indexOf(plano.plan_valor_adesao);
                                      if (posicaoId === posicaoValorOriginal) {
                                        setValorAdesao(e.target.value);
                                      }
                                    }}
                                    onFocus={() => {
                                      setErrors((prev) => ({
                                        ...prev,
                                        filiacao: "",
                                      }));
                                    }}
                                  />
                                </span>

                                <button
                                  onClick={() => {
                                    const data = {
                                      fil_ven_id: VEN_ID,
                                      fil_tipo_veiculo: VEI_SEGMENTO,
                                      valor_plano:
                                        plano.filiacao_inicial.toString(),
                                      valor_proposta: valorAdesao,
                                    };

                                    handleDescontoFiliacao(data, plano);
                                  }}
                                  className="btn-valor salvar"
                                >
                                  Salvar
                                </button>

                                <button
                                  onClick={() => {
                                    setEditToggle((prev) => ({
                                      ...prev,
                                      filiacao: false,
                                    }));
                                  }}
                                  className="btn-valor cancelar"
                                >
                                  Cancelar
                                </button>
                              </span>
                            </div>

                            <p
                              style={{
                                color: "red",
                                fontSize: "14px",
                                fontWeight: "600",
                              }}
                            >
                              {errors.filiacao}
                            </p>
                          </>
                        )}

                        <div className="modalFooter">
                          <BtnAdicionais
                            type="button"
                            onClick={() => {
                              setCloseModal(true);
                              setId(plano.plan_id);
                              setMensalidade(plano.plan_valor_mensalidade);
                            }}
                          >
                            <FaPlus size={11} /> Adicionais
                          </BtnAdicionais>

                          <BtnAddPlano
                            type="button"
                            onClick={() => {
                              adicionarPlano(plano);
                            }}
                          >
                            <FaCheck size={11} /> Escolher este plano
                          </BtnAddPlano>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          </AccordionsWrapper>

          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <PlanosEscolhidos>
              <h3>Planos escolhidos</h3>
              {planoArray?.length > 0 ? (
                <>
                  {planoArray.map((i) => {
                    return (
                      <PlanoDetalhes key={i.plan_id}>
                        <p
                          style={{
                            margin: "5px 30px 5px 0px",
                            display: "inline-block",
                          }}
                        >
                          {i ? i.plan_descricao : 0}{" "}
                        </p>

                        <BotaoDeletarPlano
                          type="button"
                          onClick={() => {
                            if (
                              adicionais
                                .map((item) => {
                                  return item.PLAN_ID;
                                })
                                .includes(i.plan_id)
                            ) {
                              setAdicionais(
                                adicionais.filter(
                                  (item) => item.PLAN_ID !== i.plan_id
                                )
                              );
                            }
                            setPlanoArray(
                              planoArray.filter(
                                (item) => item.plan_id !== i.plan_id
                              )
                            );
                          }}
                          style={{
                            display: "inline-block",
                          }}
                        >
                          <HiTrash size={20} />
                        </BotaoDeletarPlano>
                      </PlanoDetalhes>
                    );
                  })}

                  <p
                    style={{
                      fontWeight: "500",
                      color: "#6F717A",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    Total adicionados: {planoArray?.length}
                  </p>
                </>
              ) : (
                <p
                  style={{
                    margin: "5px 10em 5px 0px",
                    width: "100%",
                    display: "block",
                    fontWeight: "500",
                    color: "#6F717A",
                  }}
                >
                  Nenhum plano adicionado.
                </p>
              )}
            </PlanosEscolhidos>

            <div className="modalFooter" style={{ flex: "0" }}>
              <BtnVoltar
                type="button"
                style={{ margin: "0 20px" }}
                onClick={() => navigate(-1)}
              >
                Voltar
              </BtnVoltar>

              <BtnSubmit
                type="submit"
                disabled={planoArray?.length === 0 ? true : false}
              >
                Avançar
              </BtnSubmit>
            </div>
          </form>
        </div>
      </ModalBoxPlanos>
    </BackgroundModal>
  );
};

export default ModalPlanos;
