import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { VistoriaContext } from "../../../../../../../context/VistoriaContext";
import {
  VistoriaBody,
  SectionForm,
  InputWrapper,
  BtnWrapper,
  InputComMascara,
  Line,
} from "../styles/styledVistoria";
import { BtnSubmit, BtnVoltar } from "../../../Modal/style/styledModal";
import Loading from "../../../../../../Loading/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import listaEstados from "../../../../../../../utils/listaEstados.js"

function DadosCliente({
  OPO_ID,
  CLI_NOME,
  CLI_CPF,
  CLI_EMAIL,
  CLI_TELEFONE,
  handleNext,
  handleBack,
  isVisible,
}) {
  const {
    // PROVIDER DADOS PESSOAIS
    nomeCliente,
    setNomeCliente,
    cpfCliente,
    setCPFCliente,

    cep,
    setCEP,
    endereco,
    setEndereco,
    numero,
    setNumero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    estado,
    setEstado,
    cidade,
    setCidade,
    cidCodigo, 
    setCidCodigo,
    email,
    setEmail,
    celular,
    setCelular,
    whatsapp,
    setWhatsapp,
    telFixo,
    setTelFixo,

    // PROVIDER DADOS PESSOAIS || CPF
    dataNascimento,
    setDataNascimento,
    sexo,
    setSexo,
    profissao,
    setProfissao,
    estadoCivil,
    setEstadoCivil,
    identidade,
    setIdentidade,
    orgaoEmissor,
    setOrgaoEmissor,
    numCNH,
    setNumCNH,
    categoriaCNH,
    setCategoriaCNH,
    apelido,
    setApelido,

    // PROVIDER DADOS PESSOAIS || CNPJ
    inscricaoEstadual,
    setInscricaoEstadual,
    razaoSocial,
    setRazaoSocial,
    nomeResponsavelLegal,
    setNomeResponsavelLegal,
    cpfResponsavel,
    setCPFResponsavel,
    identidadeResponsavel,
    setIdentidadeResponsavel,
    emailResponsavel,
    setEmailResponsavel,
    celularResponsavel,
    setCelularResponsavel,
    nascimentoResponsavel,
    setNascimentoResponsavel,

    dadosPessoais,
  } = useContext(VistoriaContext);

  const [camposCPF, setCamposCPF] = useState(true);
  const [camposCNPJ, setCamposCNPJ] = useState(false);
  const [numeroValidacao, setNumeroValidacao] = useState("");
  // const [estadosArray, setEstadosArray] = useState([]);
  const [cidadesArray, setCidadesArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [erroInput, setErroInput] = useState(false)

  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  const ASS_COD = sessionStorage.getItem("ASS_COD");

  useEffect(() => {
    setCPFCliente("");
    setEmail("");
    setEndereco("");
    setCEP("");
    setComplemento("");
    setNumero("");
    setBairro("");
    setEstado("");
    setCidade("");
    setEmail("");
    setCelular("");
    setTelFixo("");
    setWhatsapp("");

    setDataNascimento("");
    setSexo("");
    setProfissao("");
    setEstadoCivil("");
    setIdentidade("");
    setOrgaoEmissor("");
    setNumCNH("");
    setCategoriaCNH("");
    setApelido("");

    setInscricaoEstadual("");
    setRazaoSocial("");
    setNomeResponsavelLegal("");
    setCPFResponsavel("");
    setIdentidadeResponsavel("");
    setEmailResponsavel("");
    setCelularResponsavel("");
    setNascimentoResponsavel("");
  }, [OPO_ID]);

  useEffect(() => {
    setNomeCliente(CLI_NOME);
    setCelular(CLI_TELEFONE);
    setWhatsapp(CLI_TELEFONE);
    if (CLI_CPF) {
      setCPFCliente(CLI_CPF);
    }
    if (CLI_EMAIL) {
      setEmail(CLI_EMAIL);
    }

    // axios
    //   .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
    //   .then((res) => {
    //     console.log(res.data);
    //     setEstadosArray(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

      setLoading(true)
      axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // setVistoria(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        MySwal.fire({
          title: "Atenção!",
          text: "Serviço indisponível no momento. Por favor, tente mais tarde.",
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((res) => {
          if (res) {
            navigate("/home/oportunidades");
            isVisible(false)
          } else {
            // not clicked
          }
        });
      });
  }, []);

  // MÁSCARA CPF/CPNJ
  const mascaraCPFCNPJ = (v) => {
    v = v.replace(/\D/g, "");

    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2");
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
      v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
  };

  function mascaraCel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  }

  function localizarCEP(cep) {
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        console.log(res.data);
        setEndereco(res.data.logradouro);
        setBairro(res.data.bairro);
        setComplemento(res.data.complemento);
        setEstado(res.data.uf);
        // setCidade(res.data.localidade.toUpperCase());
        verCidades(res.data.uf);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // CAMPOS DESABILITADOS SE CPF ESTIVER VAZIO
  function cpfDigitado() {
    if (cpfCliente.length > 10) {
      return false;
    } else {
      return true;
    }
  }

  const validarCamposPessoais = () => {
    if (cpfCliente.length === 11 || cpfCliente.length === 14) {
      var data = dadosPessoais.DADOS_CPF_CNPJ;
      const v1 = () => {
        for (var key in data) {
          if (data[key] == "") {
            return false;
          }
        }

        // VALIDAÇÃO DA CATEGORIA CNH
        var cnh = categoriaCNH.toUpperCase();
        if (
          !["A", "B", "C", "D", "E", "AB", "AC", "AD", "AE"].includes(cnh) &&
          cpfCliente.length === 11
        ) {
          return false;
        }

        // VALIDAÇÃO NÚMEROS DE CELULAR E WHATSAPP
        function numeroValido() {
          function mask(v) {
            v = v.replace(/\D/g, "");
            v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
            v = v.replace(/(\d)(\d{5})$/, "$1-$2");
            return v.replace(/\s/g, "");
          }
          var numCel = mask(celular);
          var numWpp = mask(whatsapp);
          var regex = new RegExp(
            "^\\([0-9]{2}\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$"
          );

          // if (!regex.test(numCel) || !regex.test(numWpp)) {
          //   console.log("false")
          //   return false
          // }

          return true;
        }

        // VERIFICAR SE É MAIOR DE IDADE
        function maiorDeIdade() {
          var anoAtual = new Date().getFullYear();
          var mesAtual = new Date().getMonth() + 1;
          var diaAtual = new Date().getDate();
          var anoDigitado = dataNascimento.split("/")[2];
          var mesDigitado = dataNascimento.split("/")[1];
          var diaDigitado = dataNascimento.split("/")[0];

          if (anoAtual - anoDigitado < 18) {
            return false;
          }

          var idade = anoAtual - anoDigitado;
          if (
            mesAtual < mesDigitado ||
            (mesAtual == mesDigitado && diaAtual < diaDigitado)
          ) {
            idade--;
            if (idade < 18) {
              return false;
            }
          }
          return true;
        }

        if (!numeroValido() || !maiorDeIdade()) {
          return false;
        }

        return true;
      };
      const v2 = () => {
        var dataGeral = dadosPessoais;
        for (var field in dataGeral) {
          if (field != "TELEFONE_FIXO" && dataGeral[field] == "") {
            // console.log(field + " VAZIO");
            return false;
          }
        }
        return true;
      };

      if (v1() && v2()) {
        var desabilitado = false;
      } else {
        var desabilitado = true;
      }

      return desabilitado;
    } else {
      return true;
    }
  };

  var estados = listaEstados.sort(function compare(a, b) {
    if (a.nome < b.nome) {
      return -1;
    }
    if (a.nome > b.nome) {
      return 1;
    }
    return 0;
  });

  function verCidades(estado) {
    axios
      .get(`https://kaminocrm.com.br:7778/naboo/cidades?uf=${estado}`)
      .then((res) => {
        setLoading(false);
        setCidadesArray(res.data)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <VistoriaBody>
      {loading && <Loading />}

      {/* //// --- CPF/CNPJ, NOME --- //// */}
      <SectionForm>
        <InputWrapper>
          <label htmlFor="cpf">CPF/CNPJ</label>
          <InputComMascara
            type="text"
            value={mascaraCPFCNPJ(cpfCliente)}
            name="cpf"
            id="cpf"
            maxLength={18}
            className={erroInput && "error_input"}
            onChange={(e) => {
              setCPFCliente(e.target.value.replace(/\D/g, ""));
            }}
            onBlur={() => {
              if (cpfCliente.length === 11) {
                setCamposCPF(true);
                setCamposCNPJ(false);
              } else if (cpfCliente.length === 14) {
                setCamposCPF(false);
                setCamposCNPJ(true);
              } else if(cpfCliente.length < 11){
                setErroInput(true)
              }
            }}
            onFocus={() => {
              setErroInput(false)
              if (cpfCliente.length === 11) {
                setCamposCPF(true);
                setCamposCNPJ(false);
              }
              if (cpfCliente.length === 14) {
                setCamposCPF(false);
                setCamposCNPJ(true);
              }
            }}
          />
          {erroInput && <span className="error">Número de caracteres inválido!</span>}
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="nome-cliente">Nome do Associado | Empresa</label>
          <input
            type="text"
            id="nome-cliente"
            name="nome-cliente"
            disabled={cpfDigitado()}
            value={nomeCliente}
            onChange={(e) => setNomeCliente(e.target.value)}
          />
        </InputWrapper>
      </SectionForm>

      <Line></Line>

      {/* //// --- CAMPOS CPF --- //// */}
      {camposCPF && (
        <>
          <SectionForm>
            <InputWrapper>
              <label htmlFor="data-de-nascimento">Data de nascimento</label>
              <InputComMascara
                type="text"
                name="data-de-nascimento"
                id="data-de-nascimento"
                mask="99/99/9999"
                disabled={cpfDigitado()}
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="sexo">Sexo</label>
              <select
                name="sexo"
                id="sexo"
                disabled={cpfDigitado()}
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
              >
                <option value="">Selecione...</option>
                <option value="Masculino">MASCULINO</option>
                <option value="Feminino">FEMININO</option>
              </select>
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="">Profissão</label>
              <input
                type="text"
                name="profissao"
                id="profissao"
                disabled={cpfDigitado()}
                value={profissao}
                onChange={(e) => setProfissao(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="estado-civil">Estado Civil</label>
              <select
                name="estado-civil"
                id="estado-civil"
                disabled={cpfDigitado()}
                onChange={(e) => setEstadoCivil(e.target.value)}
              >
                <option value="">Selecione...</option>
                <option value="SOLTEIRO">SOLTEIRO</option>
                <option value="CASADO">CASADO</option>
                <option value="DIVORCIADO">DIVORCIADO</option>
                <option value="VIUVO">VIÚVO</option>
                <option value="UNIAO_ESTAVEL">UNIÃO ESTÁVEL</option>
              </select>
            </InputWrapper>
          </SectionForm>

          <SectionForm>
            <InputWrapper>
              <label htmlFor="identidade">Identidade</label>
              <input
                type="text"
                name="identidade"
                id="identidade"
                maxLength={14}
                disabled={cpfDigitado()}
                value={identidade}
                onChange={(e) =>
                  setIdentidade(e.target.value.replace(/\D/g, ""))
                }
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="orgao-emissor">Órgão emissor</label>
              <input
                type="text"
                name="orgao-emissor"
                id="orgao-emissor"
                disabled={cpfDigitado()}
                value={orgaoEmissor}
                onChange={(e) => setOrgaoEmissor(e.target.value)}
                maxLength={20}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="numero-cnh">Número da CNH</label>
              <input
                type="text"
                name="numero-cnh"
                id="numero-cnh"
                disabled={cpfDigitado()}
                maxLength={11}
                value={numCNH}
                onChange={(e) => setNumCNH(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="categoria-cnh">Categoria da CNH</label>
              <input
                type="text"
                name="categoria-cnh"
                id="categoria-cnh"
                disabled={cpfDigitado()}
                value={categoriaCNH}
                onChange={(e) => setCategoriaCNH(e.target.value)}
                maxLength={2}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="apelido">Como gostaria de ser chamado?</label>
              <input
                type="text"
                name="apelido"
                id="apelido"
                disabled={cpfDigitado()}
                value={apelido}
                onChange={(e) => setApelido(e.target.value)}
              />
            </InputWrapper>
          </SectionForm>

          <Line></Line>
        </>
      )}

      {/* //// --- CAMPOS CNPJ --- //// */}
      {camposCNPJ && (
        <>
          <SectionForm>
            <InputWrapper>
              <label htmlFor="inscricao-estadual">Inscrição Estadual</label>
              <input
                type="text"
                name="inscricao-estadual"
                id="inscricao-estadual"
                disabled={cpfDigitado()}
                value={inscricaoEstadual}
                onChange={(e) => setInscricaoEstadual(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="razao-social">Razão Social</label>
              <input
                type="text"
                name="razao-social"
                id="razao-social"
                disabled={cpfDigitado()}
                value={razaoSocial}
                onChange={(e) => setRazaoSocial(e.target.value)}
              />
            </InputWrapper>
          </SectionForm>

          <SectionForm>
            <InputWrapper>
              <label htmlFor="nome-responsavel-legal">
                Nome do Responsável Legal
              </label>
              <input
                type="text"
                name="nome-responsavel-legal"
                id="nome-responsavel-legal"
                disabled={cpfDigitado()}
                value={nomeResponsavelLegal}
                onChange={(e) => setNomeResponsavelLegal(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="cpf-responsavel">CPF do Responsável</label>
              <input
                type="text"
                name="cpf-responsavel"
                id="cpf-responsavel"
                maxLength={14}
                disabled={cpfDigitado()}
                value={mascaraCPFCNPJ(cpfResponsavel)}
                onChange={(e) =>
                  setCPFResponsavel(e.target.value.replace(/\D/g, ""))
                }
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="identidade-responsavel">
                Identidade do Responsável
              </label>
              <input
                type="text"
                name="identidade-responsavel"
                id="identidade-responsavel"
                disabled={cpfDigitado()}
                value={identidadeResponsavel}
                onChange={(e) => setIdentidadeResponsavel(e.target.value)}
              />
            </InputWrapper>
          </SectionForm>

          <SectionForm>
            <InputWrapper>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email-responsavel"
                id="email-responsavel"
                disabled={cpfDigitado()}
                value={emailResponsavel}
                style={{ textTransform: "lowercase" }}
                onChange={(e) => setEmailResponsavel(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="celular-responsavel">
                Telefone Celular / Whatsapp
              </label>
              <input
                type="text"
                name="celular-responsavel"
                id="celular-responsavel"
                disabled={cpfDigitado()}
                value={mascaraCel(celularResponsavel)}
                maxLength={15}
                onChange={(e) => {
                  setCelularResponsavel(e.target.value.replace(/\D/g, ""));
                  console.log(e.target.value);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <label htmlFor="nascimento-responsavel">
                Nascimento do Responsável
              </label>
              <InputComMascara
                type="text"
                mask="99/99/9999"
                name="nascimento-responsavel"
                id="nascimento-responsavel"
                disabled={cpfDigitado()}
                value={nascimentoResponsavel}
                onChange={(e) => setNascimentoResponsavel(e.target.value)}
              />
            </InputWrapper>
          </SectionForm>

          <Line></Line>
        </>
      )}

      {/* //// --- ENDEREÇOS --- //// */}
      <SectionForm>
        <InputWrapper>
          <label htmlFor="cep">CEP</label>
          <InputComMascara
            type="text"
            name="cep"
            id="cep"
            mask="99.999-999"
            disabled={cpfDigitado()}
            value={cep}
            onChange={(e) => {
              setCEP(e.target.value);
            }}
            onBlur={(e) => {
              if (cep.length >= 8) {
                localizarCEP(e.target.value.replace(/\D/g, ""));
                setLoading(true);
              }
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="endereco">Endereço</label>
          <input
            type="text"
            name="endereco"
            id="endereco"
            disabled={cpfDigitado()}
            value={endereco}
            onChange={(e) => setEndereco(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="numero">Número</label>
          <input
            type="number"
            name="numero"
            id="numero"
            disabled={cpfDigitado()}
            value={numero}
            onChange={(e) => setNumero(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="complemento">Complemento</label>
          <input
            type="text"
            name="complemento"
            id="complemento"
            disabled={cpfDigitado()}
            value={complemento}
            onChange={(e) => setComplemento(e.target.value)}
          />
        </InputWrapper>
      </SectionForm>

      <SectionForm>
        <InputWrapper>
          <label htmlFor="bairro">Bairro</label>
          <input
            type="text"
            name="bairro"
            id="bairro"
            disabled={cpfDigitado()}
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="estado">Estado (UF)</label>
          <select
            name="estado"
            disabled={cpfDigitado()}
            value={estado}
            onChange={(e) => {
              setEstado(e.target.value);
              setLoading(true);
              verCidades(e.target.value);
            }}
          >
            <option value="null">Selecione...</option>
            {estados.map((i) => {
              return (
                <option key={i.id} value={i.sigla}>
                  {i.nome.toUpperCase()} ({i.sigla})
                </option>
              );
            })}
          </select>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="cidade">Cidade</label>
          <select
            name="cidade"
            disabled={cpfDigitado()}
            onChange={(e) => {
              const { value } = e.target
              var dados = JSON.parse(value)
              setCidade(dados.nome)
              setCidCodigo(dados.codigo)
            }}
          >
            <option value="null">Selecione a cidade...</option>
            {cidadesArray.map((i) => {
              return (
                <option key={i.CID_CODIGO} value={JSON.stringify({nome: i.CID_NOME.toUpperCase(), codigo: i.CID_CODIGO})}>
                  {i.CID_NOME.toUpperCase()}
                </option>
              );
            })}
          </select>
        </InputWrapper>
      </SectionForm>

      <SectionForm>
        <InputWrapper>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            disabled={cpfDigitado()}
            value={email}
            style={{ textTransform: "lowercase" }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="celular">Celular</label>
          <input
            type="text"
            name="celular"
            id="celular"
            disabled={cpfDigitado()}
            value={mascaraCel(celular)}
            maxLength={15}
            onChange={(e) => {
              setCelular(e.target.value.replace(/\D/g, ""));
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="telefone-whatsapp">Telefone Celular / Whatsapp</label>
          <input
            type="text"
            name="telefone-whatsapp"
            id="telefone-whatsapp"
            disabled={cpfDigitado()}
            maxLength={15}
            value={mascaraCel(whatsapp)}
            onChange={(e) => {
              setWhatsapp(e.target.value.replace(/\D/g, ""));
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="telefone-fixo">Telefone fixo</label>
          <input
            type="text"
            name="telefone-fixo"
            id="telefone-fixo"
            disabled={cpfDigitado()}
            value={mascaraCel(telFixo)}
            maxLength={14}
            onChange={(e) => {
              setTelFixo(e.target.value.replace(/\D/g, ""));
            }}
          />
        </InputWrapper>
      </SectionForm>

      <SectionForm style={{ justifyContent: "flex-end" }}>
        <BtnWrapper>
          <BtnVoltar
            style={{ margin: "0 20px" }}
            onClick={() => {
              handleBack();
            }}
          >
            Voltar
          </BtnVoltar>

          <BtnSubmit
            onClick={() => {
              handleNext();
              console.log(dadosPessoais);
            }}
            disabled={validarCamposPessoais()}
            // disabled={false}
          >
            Próximo
          </BtnSubmit>
        </BtnWrapper>
      </SectionForm>
    </VistoriaBody>
  );
}

export default DadosCliente;
