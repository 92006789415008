import React, { useState, useContext, useEffect } from 'react'
import axios from "axios"
import { VistoriaContext } from '../../../../../../../context/VistoriaContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    VistoriaBody,
    VistoriaSectionForm,
    SectionForm,
    InputWrapper,
    BtnWrapper,
    InputComMascara,
    Line,
} from '../styles/styledVistoria'
import { FaCarAlt, FaUserAlt } from "react-icons/fa"
import { BtnSubmit, BtnVoltar } from '../../../Modal/style/styledModal'
import ModalToken from './ModalToken/ModalToken'
import Loading from '../../../../../../Loading/Loading';



function Resumo({ OPO_ID, handleBack, isVisible }) {
    const {
        dadosGerais
    } = useContext(VistoriaContext)

    const LOGIN = sessionStorage.getItem("LOGIN");
    const JWT_TOKEN = sessionStorage.getItem("JWT");
    const ASS_COD = sessionStorage.getItem("ASS_COD");
    const VEN_ID = sessionStorage.getItem("VEN_ID");

    const DADOS_GERAIS = {
        "VEN_ID": VEN_ID,
        "OPO_ID": OPO_ID,
        "DADOS_GERAIS": dadosGerais
    }

    const [modalToken, setModalToken] = useState(false)
    // const [resposta, setResposta] = useState(Boolean)
    const [token, setToken] = useState("")
    const [loading, setLoading] = useState(false)

    function sucessoVistoria() {
        toast.success('Vistoria criada com sucesso!', {
            position: "top-right",
            autoClose: 2200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function erroVistoria() {
        toast.error('Ops, algo deu errado!', {
            position: "top-right",
            autoClose: 2200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function enviarDados() {
        axios.post("https://kaminocrm.com.br:7778/vistoria/criar-vistoria", DADOS_GERAIS, {
            headers: {
                Authorization: `Bearer ${JWT_TOKEN}`,
                user_login: LOGIN,
                ass_cod: ASS_COD,
            },
        })
            .then((res) => {
                setToken(res.data.TOKEN)
                setModalToken(true)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                erroVistoria()
            })
    }

    const mascaraCPFCNPJ = (doc) => {
        doc = doc.replace(/\D/g, "")

        if (doc.length <= 11) {
            doc = doc.replace(/(\d{3})(\d)/, "$1.$2")
            doc = doc.replace(/(\d{3})(\d)/, "$1.$2")
            doc = doc.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
            doc = doc.replace(/^(\d{2})(\d)/, "$1.$2")
            doc = doc.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            doc = doc.replace(/\.(\d{3})(\d)/, ".$1/$2")
            doc = doc.replace(/(\d{4})(\d)/, "$1-$2")
        }

        return doc
    }

    return (
        <>
        {loading && <Loading />}
        <ToastContainer/>
        {modalToken && <ModalToken isVisible={isVisible} setModalToken={setModalToken} token={token} />}
            <VistoriaBody>
                <VistoriaSectionForm>
                    <VistoriaSectionForm className="card-resumo">
                        <span><FaUserAlt size={28} /></span>
                        <div>
                            <p><strong>{dadosGerais.DADOS_PESSOAIS.NOME}</strong></p>
                            <p>{mascaraCPFCNPJ(dadosGerais.DADOS_PESSOAIS.CPF_CNPJ)}</p>
                        </div>

                    </VistoriaSectionForm>
                    <VistoriaSectionForm className="card-resumo">
                        <span><FaCarAlt size={31} /></span>
                        <div>
                            <p><strong>{dadosGerais.DADOS_VEICULO.PLACA}</strong></p>
                            <p>{dadosGerais.DADOS_VEICULO.MODELO}</p>
                        </div>
                    </VistoriaSectionForm>
                </VistoriaSectionForm>

                <SectionForm style={{justifyContent: "flex-end"}}>
                    <BtnWrapper>
                        <BtnVoltar
                            style={{ margin: "0 20px" }}
                            onClick={() => {
                                handleBack()
                            }}>Voltar</BtnVoltar>

                        <BtnSubmit
                            onClick={() => {
                                setLoading(true)
                                enviarDados()
                                console.log(dadosGerais)
                            }}
                        >Salvar</BtnSubmit>
                    </BtnWrapper>
                </SectionForm>
            </VistoriaBody>
        </>
    )
}

export default Resumo