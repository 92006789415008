import React from 'react';
// import './styles-menu.css'

function MenuTemplate({ step, automacaoId }) {
  return (
    <aside className='CompaniesMenuModal__Container'>
      <header>
        <span className='CompaniesMenuModal__Title'>{automacaoId !== '' ? 'Edição' : 'Cadastro'} de Automação</span>
        <span className='CompaniesMenuModal__Subtitle'>
          Siga o passo a passo para {automacaoId !== '' ? 'editar uma' : 'cadastrar uma nova'} Automação.
        </span>
      </header>

      <div className='CompaniesMenuModal__TimeLine'>
        <ul>
          <li className={step === 1 ? `CompaniesMenuModal__TimeLine--selected` : 'CompaniesMenuModal__TimeLine--passed'}>Escolha de Funil</li>
          <li className={step === 2 ? `CompaniesMenuModal__TimeLine--selected` : ''}>Configurar Automações</li>
        </ul>
      </div>
    </aside>
  );
}

export default MenuTemplate;
