import React, { useState, useContext, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import "./style/ModalStyle.css";
import { useNavigate } from "react-router-dom";
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  BtnSubmit,
  X,
  InputTEL,
} from "./style/styledModal";
import { Input, Select } from "../../../../Login/style/styledLogin";

import { CadastroAssociado } from "../../../../../context/CadastroASSContext";
import Loading from "../../../../Loading/Loading.jsx";
import { handleClose } from "../../../../../services/handleClose";
import { CadastroVeiculo } from "../../../../../context/CadastroVEIContext";

const ModalCliente = ({ closeModal }) => {
  const navigate = useNavigate();

  const {
    CLI_NOME,
    // CLI_CPF,
    CLI_TELEFONE,
    CLI_EMAIL,
    setCLI_NOME,
    // setCLI_CPF,
    setCLI_TELEFONE,
    setCLI_EMAIL,
  } = useContext(CadastroAssociado);

  const { setZeroKM } = useContext(CadastroVeiculo)

  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  // const [validarCPF, setValidarCPF] = useState(false);
  const [loading, setLoading] = useState(false);
  const [origens, setOrigens] = useState([]);
  const [origemCliente, setOrigemCliente] = useState("");

  useEffect(() => {
    setCLI_NOME("");
    setCLI_TELEFONE("");
    setCLI_EMAIL('');
    // setCLI_CPF('');
    setZeroKM(false)
  }, [closeModal]);

  useEffect(() => {
    axios
      .get("https://kaminocrm.com.br:7778/oportunidades/listarTiposOrigens/", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // console.log(res);
        setOrigens(res.data.origens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const LOGIN = sessionStorage.getItem("LOGIN");
    const JWT_TOKEN = sessionStorage.getItem("JWT");
    const ASS_COD = sessionStorage.getItem("ASS_COD");
    const VEN_ID = sessionStorage.getItem("VEN_ID");
    const FUN_ID = sessionStorage.getItem("FUN_ID");

    const DADOS = {
      CLI_NOME: CLI_NOME,
      CLI_CPF: "",
      CLI_TELEFONE: CLI_TELEFONE,
      CLI_EMAIL: CLI_EMAIL,
      VEN_ID: VEN_ID,
      FUN_ID: FUN_ID,
      ORG_ID: origemCliente,
    };

    axios
      .post("https://kaminocrm.com.br:7778/clientes/cadastrar-cliente", DADOS, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // console.log(res.data);
        sessionStorage.setItem("CLI_ID", res.data.DADOS.CLI_ID);
        navigate("/home/cadastro-placa");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  };

  // const checkCPF = () => {
  //   const LOGIN = sessionStorage.getItem("LOGIN");
  //   const JWT_TOKEN = sessionStorage.getItem("JWT");
  //   const ASS_COD = sessionStorage.getItem("ASS_COD");

  //   axios
  //     .get(`https://kaminocrm.com.br:7778/clientes/existe/${CLI_CPF}/cpf`, {
  //       headers: {
  //         Authorization: `Bearer ${JWT_TOKEN}`,
  //         user_login: LOGIN,
  //         ass_cod: ASS_COD,
  //       },
  //     })
  //     .then((res) => {
  //       sessionStorage.setItem("CLI_ID", res.data.CLI_ID);
  //       setCLI_NOME(res.data.CLI_NOME);
  //       setCLI_TELEFONE(res.data.CLI_TELEFONE);
  //       setZeroKM(false)
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       setLoading(false);
  //     });
  // };

  const handleDisabled = () => {
    var CLI_CEL = CLI_TELEFONE.replace(/[^a-zA-Z0-9 ]/g, '');
    return !CLI_NOME || CLI_CEL.length < 11 || !origemCliente
  }

  return (
    <BackgroundModal>
      {loading && <Loading />}

      <ModalBoxCliente>
        <ModalHeader>
          <h2>Dados do Proponente</h2>

          <X onClick={() => {
            closeModal(false)
            handleClose()
            }}>
            <IoMdClose size={17} />
          </X>
        </ModalHeader>

        <div className="modalBody">
          <form onSubmit={handleSubmit}>
            <label htmlFor="CLI_NOME">
              Nome do Proponente: <span style={{ color: "red" }}>*</span>
            </label>
            <Input
              type="text"
              id="CLI_NOME"
              name="CLI_NOME"
              value={CLI_NOME}
              onChange={(e) => setCLI_NOME(e.target.value)}
              style={{
                height: "20px",
                width: "97%",
                textTransform: 'uppercase'
              }}
            />

            <label htmlFor="CLI_TELEFONE">
              Telefone: <span style={{ color: "red" }}>*</span>
            </label>
            <InputTEL
              type="text"
              name="CLI_TELEFONE"
              id="CLI_TELEFONE"
              value={CLI_TELEFONE}
              mask="(99)99999-9999"
              onChange={(e) => setCLI_TELEFONE(e.target.value)}
              // onBlur={() => checkCPF(CLI_TELEFONE)}
              style={{
                height: "20px",

                width: "97%",
              }}
            />

            <label htmlFor="origem">
              Origem: <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name="origem"
              id="origem"
              onChange={(e) => setOrigemCliente(e.target.value)}
            >
              <option value="null">Selecione...</option>
              {origens.map((i) => {
                return <option value={i.ORG_ID}>{i.ORG_DESCRICAO}</option>;
              })}
            </Select>

            {/* <label htmlFor="CLI_CPF">CPF:</label>
            <InputCPF
              type="text"
              name="CLI_CPF"
              id="CLI_CPF"
              value={CLI_CPF}
              mask="999.999.999-99"
              onChange={(e) => setCLI_CPF(e.target.value)}
              onBlur={() => checkCPF(CLI_CPF)}
              style={{
                height: "20px",

                width: "97%",
              }}
            />
            {validarCPF && (
              <span
                style={{
                  color: "red",
                  display: "block",
                  marginTop: "-2px",
                  marginBottom: "5px",
                  fontSize: "12px",
                  fontWeight: "bolder",
                }}
              >
                CPF inválido!
              </span>
            )}

            <label htmlFor="CLI_EMAIL">E-mail:</label>
            <Input
              type="email"
              id="CLI_EMAIL"
              name="CLI_EMAIL"
              value={CLI_EMAIL}
              onChange={(e) => setCLI_EMAIL(e.target.value)}
              style={{
                height: "20px",
                display: "inline-block",
                width: "97%",
              }}
            /> */}
             <label htmlFor="CLI_EMAIL">E-mail:</label>
            <Input
              type="email"
              id="CLI_EMAIL"
              name="CLI_EMAIL"
              value={CLI_EMAIL}
              onChange={(e) => setCLI_EMAIL(e.target.value)}
              style={{
                height: "20px",
                display: "inline-block",
                width: "97%",
              }}
            /> 
            <div
              className="modalFooter"
              style={{
                marginTop: "40px",
              }}
            >
              <button
                className="btnClose"
                type="button"
                onClick={() => closeModal(false)}
              >
                Fechar
              </button>
              <BtnSubmit type="submit" disabled={handleDisabled()} onClick={() => setLoading(true)}>
                Avançar
              </BtnSubmit>
            </div>
          </form>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  );
};

export default ModalCliente;
