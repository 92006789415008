import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 55px 7em 55px;
`;

export const Carousel = styled.div`
  display: block;
  max-width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  overflow-x: auto;
  scroll-behavior: smooth;

  @media only screen and (max-width: 990px) {
    display: -webkit-box;
  }
`;

// DIVS DE DENTRO DOS CARDS

export const LineH = styled.div`
  padding: 10px;
  /* width: 96%; */
  background-color: rgba(9, 7, 18, 0.14);
  border-radius: 4px 4px 0px 0px;
`;

export const Titles = styled.div`
  padding: 6px 11px 11px 11px;

  span {
    display: block;
  }

  .title {
    font-weight: 300;
    font-size: 16px;
  }

  @media only screen and (max-width: 990px) {
    display: block;
  }
`;
export const Numero = styled.div`
  display: flex;
  text-align: right;
  font-size: 2.5rem;
  font-weight: 500;
  padding: 0 11px 11px 11px;

  @media only screen and (max-width: 990px) {
    display: block;
  }

  @media only screen and (max-width: 310px) {
    font-size: 2rem;
  }
`;

// CARDS

export const Card = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 25%;
  min-height: 4em;
  max-height: fit-content;
  padding: 15px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 7px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

  div {
    display: block;
  }

  @media only screen and (max-width: 990px) {
    width: 55%;
    // display: inline;
  }
`;

export const EstatsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 857px) {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
`;
export const GraficosContainer = styled.div`
  width: 15%;
  display: inline-block;
  margin-bottom: 7rem;

  @media only screen and (max-width: 1480px) {
    width: 40%;
    display: block;
    /* margin: 0 auto; */
  }

  @media only screen and (max-width: 1380px) {
    width: 30%;
    display: block;
    /* margin: 0 auto; */
  }
  @media only screen and (max-width: 857px) {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
  @media only screen and (max-width: 757px) {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
`;

export const MetricasContainer = styled.div`
  width: 17%;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0 5px 7rem 5px;
`;

export const MetricasContainerUm = styled.div`
  width: 32%;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0 5px 7rem 5px;
`;

export const MetricasContainerDois = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const CardMetrica = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0px 1px 3px #ccc;
  width: 100%;
  height: 100%;

  h3 {
    margin: 0;
  }
`;

export const TaxasMedias = styled.div`
  display: inline-block;
  height: 92%;
  width: 13%;
  margin-right: 4px;
  background-color: #fff;
  vertical-align: top;

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }

  ul li {
    margin: 6px 0;
  }

  /* ul li{
    display: flex;
    flex-direction: column;
  }

  ul li div{
    font-size: 13px;
  } */

  @media only screen and (max-width: 857px) {
    width: 90%;
    display: block;
    margin: 0 auto;

    .um,
    .container-card {
      width: 100%;
      margin: 15px 0;
      display: block;
    }

    .container-card {
      padding: 10px;
    }
  }
`;

export const MCR = styled.div`
  display: inline-block;
  height: 92%;
  width: 12%;
  margin-right: 4px;
  background-color: #fff;
  vertical-align: top;

  @media only screen and (max-width: 857px) {
    width: 90%;
    display: block;
    margin: 0 auto;

    .um,
    .container-card {
      width: 100%;
      margin: 15px 0;
      display: block;
    }

    .container-card {
      padding: 10px;
    }
  }
`;

export const ChartBar = styled.div`
  display: inline-block;
  height: 92%;
  width: 32%;
  margin-right: 4px;
  background-color: #fff;
  vertical-align: top;

  @media only screen and (max-width: 857px) {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
`;

export const LTVeTicket = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 12%;
  padding: 20px;
  height: 60%;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(204, 204, 204, 0.5);
  border-radius: 7px;
  margin: 3px;

  ul {
    list-style-type: none;
    padding-inline-start: 0px;
  }

  ul li {
    margin: 6px 0;
  }

  @media only screen and (max-width: 857px) {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
`;

export const ChartPie = styled.div`
  display: inline-block;
  height: 92%;
  width: 99%;
  margin-right: 4px;
  background-color: #fff;
  vertical-align: top;

  @media only screen and (max-width: 857px) {
    width: 90%;
    display: block;
    margin: 0 auto;

    .um,
    .container-card {
      width: 100%;
      margin: 15px 0;
      display: block;
    }

    .container-card {
      padding: 10px;
    }
  }
`;

export const SepLine = styled.div`
  height: 1px;
  // border-radius: 20px;
  display: block;
  width: 100%;
  background-image: linear-gradient(to right, #ccc, white);
  margin: 8px 0;
`;

///////////////////////////////////

export const BlocoEstatisticas = styled.section`
  display: inline-block;
  width: 47%;
  height: 100%;
  /* background-color: blue; */
  vertical-align: top;
  @media only screen and (max-width: 857px) {
    width: 95%;
    display: block;
    margin: 5px 0 0 0;
  }
`;
export const BlocoEstatisticas2 = styled.section`
  display: inline-block;
  width: 53%;
  height: 100%;
  /* background-color: blue; */
  vertical-align: top;
  @media only screen and (max-width: 857px) {
    width: 95%;
    display: block;
    margin: 5px 0 0 0;
  }
`;

export const EstatiticasContainer = styled.div`
  display: flex;
  /* align-items: stretch; */
  flex-direction: row;
  width: 99%;
  margin: 5px 0;
  /* background-color: red; */

  .mini_card {
    margin-right: 5px;
  }

  @media only screen and (max-width: 857px) {
    width: 95%;
    display: block;
    margin: 5px 0 0 0;
  }
`;
export const EstatiticasContainer2 = styled.div`
  display: flex;
  /* align-items: stretch; */
  flex-direction: column;
  /* background-color: red; */
  width: 99%;
  margin: 5px 0;
  @media only screen and (max-width: 857px) {
    width: 95%;
    display: block;
    margin: 5px 0 0 0;
  }
`;

export const DognutChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: white;
  vertical-align: top;
  margin-right: 4px;
  padding: 30px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px #ccc;

  h3 {
    margin: 0 0 5px 0;
  }
  @media only screen and (max-width: 857px) {
    width: 93%;
    display: block;
    margin: 5px 0 0 0;
  }
`;

export const TaxasCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  width: 60%;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px #ccc;

  h3 {
    margin: 0 0 5px 0;
  }

  p {
    margin: 9px 0;
    font-size: 13px;
  }
  @media only screen and (max-width: 857px) {
    width: 93%;
    display: block;
    margin: 5px 0 0 0;
  }
`;
export const LineCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  /* padding: 30px 33px 30px 30px; */
  width: 93%;
  height: fit-content;
  max-height: 550px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px #ccc;
  h3 {
    margin: 0 0 5px 0;
  }
  p {
    margin: 9px 0;
  }
  .chart {
    height: 90%;
    @media only screen and (max-width: 857px) {
      width: 88%;
      display: block;
      margin: 5px 0 0 0;
    }
  }
  @media only screen and (max-width: 857px) {
    width: 93%;
    display: block;
    margin: 5px 0 0 0;
  }
`;

export const GraphCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  width: 96%;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 1px 3px #ccc;
  h3 {
    margin: 0 0 5px 0;
  }
  p {
    margin: 9px 0;
  }
  .chart {
    height: 95%;
    @media only screen and (max-width: 1000px) {
      width: 93%;
      display: block;
      margin: 5px 0 0 0;
      height: 95%;
    }
  }
  @media only screen and (max-width: 857px) {
    width: 93%;
    display: block;
    margin: 5px 0 0 0;
    height: 400px !important;
  }
`;
