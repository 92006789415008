import React from 'react';
import Select from 'react-select';

function FunilSelect({ funis, automacao, setAutomacao }) {
  function handleTypeTrigger(value) {
    setAutomacao((prev) => ({
      ...prev,
      AUT_TRIGGER: value,
      INFOS: [],
    }));
  }
  return (
    <div className='initial_data_container'>
      <span className='step__title'>Cadastre os dados iniciais</span>

      <div className='initial_data_form'>
        <div className='group_form'>
          <div className='item_group'>
            <label htmlFor='nome_aut'>Título da Automação</label>
            <input
              type='text'
              value={automacao.AUT_NAME}
              onChange={(e) => {
                setAutomacao((prev) => ({
                  ...prev,
                  AUT_NAME: e.target.value.toUpperCase(),
                }));
              }}
            />
          </div>
          <div className='item_group'>
            <label htmlFor='fun_aut'>Funil da Automação</label>
            <Select
              options={funis}
              placeholder='Selecione...'
              value={funis.find(el => el.value === automacao.FUN_ID)}
              onChange={(e) => {
                setAutomacao((prev) => ({
                  ...prev,
                  FUN_ID: e.value,
                }));
              }}
            />
          </div>
        </div>
        <div className='group_form'>
          <div className='item_group'>
            <label htmlFor='type_aut'>Quando houver alteração por:</label>
            <div className='types_list'>
              <div
                className={`${
                  automacao.AUT_TRIGGER === 'OPORTUNIDADE'
                    ? 'selected_item type_item'
                    : 'type_item'
                }`}
                id='OPORTUNIDADE'
                onClick={(e) => {
                  handleTypeTrigger(e.target.id);
                }}
              >
                Oportunidade
              </div>
              <div
                className={`${
                  automacao.AUT_TRIGGER === 'STATUS'
                    ? 'selected_item type_item'
                    : 'type_item'
                }`}
                id='STATUS'
                onClick={(e) => {
                  handleTypeTrigger(e.target.id);
                }}
              >
                Status
              </div>
              <div
                className={`${
                  automacao.AUT_TRIGGER === 'ETAPA'
                    ? 'selected_item type_item'
                    : 'type_item'
                }`}
                id='ETAPA'
                onClick={(e) => {
                  handleTypeTrigger(e.target.id);
                }}
              >
                Etapas
              </div>
              <div
                className={`${
                  automacao.AUT_TRIGGER === 'TAREFA'
                    ? 'selected_item type_item'
                    : 'type_item'
                }`}
                id='TAREFA'
                onClick={(e) => {
                  handleTypeTrigger(e.target.id);
                }}
              >
                Tarefa
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FunilSelect;
