import React, { useState, useContext, useEffect } from 'react'
import axios from "axios"
import { VistoriaContext } from '../../../../../../../context/VistoriaContext'
import {
  VistoriaBody,
  SectionForm,
  VistoriaSectionForm,
  InputWrapper,
  BtnWrapper,
  InputComMascara,
  Line,
} from '../styles/styledVistoria'
import { BtnSubmit, BtnVoltar } from '../../../Modal/style/styledModal'

function DadosVistoria({ handleNext, handleBack }) {

  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const {
    tipoVistoria, setTipoVistoria,
    vendedor, setVendedor,
    observacao, setObservacao,
    dadosVistoria
  } = useContext(VistoriaContext)

  const [arrayVendedores, setArrayVendedores] = useState([])

  useEffect(async() => {
    await axios.get("https://kaminocrm.com.br:7778/endor/listar-vistoriadores", {
      headers: {
        Authorization: `Bearer ${JWT_TOKEN}`,
        user_login: LOGIN,
        ass_cod: ASS_COD,
      }})
    .then((res) => setArrayVendedores(res.data))
    .catch((err) => console.log(err))
  })

  return (
    <VistoriaBody>
      <VistoriaSectionForm>
        <InputWrapper className='vist-ven'>
          <label htmlFor="tipo-vistoria">Tipo de Vistoria</label>
          <input type="text" name="tipo-vistoria" value={tipoVistoria} disabled />
          <label htmlFor="vendedor">Vistoriador</label>
          <select name="vendedor" value={vendedor} onChange={(e) => setVendedor(e.target.value)} >
            <option value="null">Selecione...</option>
            {arrayVendedores.map((i) => {
              return(
                <option value={i.VTO_ID}>{i.VTO_NOME}</option>
              )
            })}
          </select>
        </InputWrapper>

        <InputWrapper>
          <label htmlFor="observacao">Observação</label>
          <textarea name="observacao" id="observacao" cols="50" rows="4" value={observacao} onChange={(e) => setObservacao(e.target.value)}></textarea>
        </InputWrapper>
      </VistoriaSectionForm>

      <SectionForm style={{justifyContent: "flex-end"}}>
        <BtnWrapper>
          <BtnVoltar
            style={{ margin: "0 20px" }}
            onClick={() => {
              handleBack()
            }}>Voltar</BtnVoltar>

          <BtnSubmit
            onClick={() => {
              handleNext()
            }}
            disabled={vendedor === "null" || vendedor === "" ? true : false}
          >Próximo</BtnSubmit>
        </BtnWrapper>
      </SectionForm>
    </VistoriaBody>
  )
}

export default DadosVistoria