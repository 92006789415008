import { createContext, useState } from "react";

export const EmpresaCadastroContext = createContext();

const EmpresaCadastroContextProvider = ({ children }) => {
    const [empresaData, setEmpresaData] = useState({
        "EMP_COD": '', 
        "EMP_CNPJ": '', 
        "EMP_VENDEDORES_URL": '', 
        "EMP_ENDOR": '', 
        "EMP_PLANOS": '', 
        "NABOO_URL": '', 
        "EMP_ADICIONAIS": '', 
        "EMP_NOME": '', 
        "EMP_FONE": '',
        "USR_PASS": '',
        "USR_EMAIL": '',
        "USR_LOGIN": ''
    })

    return (
        <EmpresaCadastroContext.Provider value={{ empresaData, setEmpresaData }}>
            {children}
        </EmpresaCadastroContext.Provider>
    )
}

export default EmpresaCadastroContextProvider

