import React, { useEffect, useRef, useState } from 'react';
import ArrowDown from '../../../../../Home/assets/icon_arrow_down_xl.svg';
import ArrowRight from '../../../../../Home/assets/icon_arrow_right_xl.svg';
import './styles.css';

function AccordionTemplate(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState('0px');

//   useEffect(() => {
//     if (props.title === 'USUÁRIOS' && height !== '0px') {
//       setHeight(`${content.current.scrollHeight}px`);
//     }
//   }, [props.children]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? '0px' : `${content.current.scrollHeight}px`);
  }

  return (
    <div className="accordion__section">
      <div
        className={`accordion__alt ${active ? 'active' : ''}`}
        onClick={toggleAccordion}
      >
        <div className='accordion__arrowContainer'>
          {active ? <img src={ArrowDown} /> : <img src={ArrowRight} />}
          <p className="accordion__title__alt">{props.title}</p>
        </div>

        <span style={{ marginLeft: '20px' }}>
        <p className="accordion__subtitle__alt">{props.subtitle}</p>
        </span>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        // className="accordion__container__alt"
        className={`accordion__container__alt ${active ? 'active_container' : ''}`}

      >
        <div className="accordion__content__alt">{props.children}</div>
      </div>
    </div>
  );
}

export default AccordionTemplate;
