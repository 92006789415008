import React, { useState, useEffect } from 'react';
import { FunilWrapper } from '../Funis/styledFunis';
import { BtnSubmit } from '../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal';
import { FaPlus } from 'react-icons/fa';
import Loading from '../../Loading/Loading';
import { MdUnarchive, MdEdit } from 'react-icons/md';
import axios from 'axios';
import { COLORS } from '../../../colors';
import Tippy from '@tippyjs/react';
import { TableButton } from '../../Home/HomeRoutes/Oportunidades/Data Table/styles/styledDataTable';
import CreateAutomacao from './CreateAutomacao/CreateAutomacao';
import ConfirmarDesarquivar from './ConfirmarDesarquivar/ConfirmarDesarquivar';

function Automacoes() {
  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');
  const VEN_ID = sessionStorage.getItem('VEN_ID');

  const DEFAULT_AUTOMACAO = {
    AUT_ID: '',
    AUT_NAME: '',
    FUN_ID: '',
    AUT_TRIGGER: '',
    INFOS: [],
  };
  // const DEFAULT_INFO = {
  //   AUT_TRIGGER: '', // STATUS, ETAPA, TAREFA
  //   AUT_EVENT: '', // CREATE, MOVE, ALTER, DELETE
  //   AUT_FROM: '', // TAREFA && null
  //   AUT_TO: '', // TAREFA && null
  //   AUT_ACTIONS: [],
  // };
  // const DEFAULT_ACTIONS = {
  //   ACTION_TIPO: '', // MESSAGE (mensagem), MOVE (mover entre colunas), ALTER (mudar status)
  //   MENSAGEM_ID: '',
  //   COLUNA_ID: '',
  //   STATUS_NOME: '',
  // };

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalArchive, setOpenModalArchive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [funis, setFunis] = useState([]);
  const [automacao, setAutomacao] = useState(DEFAULT_AUTOMACAO);
  const [automacoesList, setAutomacoesList] = useState([]);
  const [autId, setAutId] = useState('');

  useEffect(() => {
    (async () => {
      await axios
        .get(
          `https://kaminocrm.com.br:7778/oportunidades/listarFunisOportunidade?VEN_ID=${VEN_ID}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          setFunis(
            res.data.funis.map((e) => {
              return {
                value: e.FUN_ID,
                label: e.FUN_NOME,
              };
            })
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await axios
        .get('https://kaminocrm.com.br:7778/parametros/listarAutomacoes', {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        })
        .then((res) => {
          console.log(res.data);
          setAutomacoesList(res.data?.dados);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    })();
  }, [openModalCreate, openModalArchive]);

  function checarStatus(AUT_ACTIVE) {
    if (AUT_ACTIVE) {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.pendente}`,
            padding: '2px 10px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ATIVO
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: `${COLORS.perdida}`,
            padding: '2px 7px',
            borderRadius: '10px',
            color: 'white',
            fontWeight: '600',
            fontSize: '12px',
          }}
        >
          ARQUIVADO
        </span>
      );
    }
  }

  return (
    <>
      {openModalCreate && (
        <CreateAutomacao
          setOpenModal={setOpenModalCreate}
          funis={funis}
          automacao={automacao}
          setAutomacao={setAutomacao}
        />
      )}
      {openModalArchive && (
        <ConfirmarDesarquivar
          setOpenModalArchive={setOpenModalArchive}
          AUT_ID={autId}
          setAutId={setAutId}
        />
      )}
      {loading && <Loading />}
      <FunilWrapper>
        <div className='criar'>
          <BtnSubmit
            onClick={() => {
              setOpenModalCreate(true);
            }}
          >
            <FaPlus size={11} /> Nova Automação
          </BtnSubmit>
        </div>
        <h3>Gerenciar automações</h3>
        <div className='funis_existem'>
          <table>
            <thead>
              <tr>
                <th className='col_nome'>Nome</th>
                <th className='col_status'>Tipo</th>
                <th className='col_status'>Funil</th>
                <th className='col_status'>Status</th>
                <th className='col_acoes'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {automacoesList.map((i) => {
                return (
                  <tr key={i.AUT_ID}>
                    <td className='col_nome'>{i.AUT_NAME.toUpperCase()}</td>
                    <td className='col_status'>{i.TRIGGERS[0]?.AUT_TRIGGER}</td>
                    <td className='col_status'>{i.FUN_NOME}</td>
                    <td className='col_status'>{checarStatus(i.AUT_ACTIVE)}</td>
                    <td className='col_acoes'>
                      {i.AUT_ACTIVE ? (
                        <Tippy
                          content='Editar automação'
                          delay='275'
                          placement='bottom'
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalCreate(true);
                              setAutomacao({
                                AUT_ID: i.AUT_ID,
                                AUT_NAME: i.AUT_NAME,
                                FUN_ID: i.FUN_ID,
                                AUT_TRIGGER: i.TRIGGERS[0].AUT_TRIGGER,
                                INFOS: i.TRIGGERS.map((el) => {
                                  return {
                                    AUT_TRIGGER: el.AUT_TRIGGER,
                                    AUT_EVENT: el.AUT_EVENT,
                                    AUT_FROM: el.AUT_FROM,
                                    AUT_TO: el.AUT_TO,
                                    AUT_ACTIONS: el.AUT_ACTIONS,
                                  };
                                }),
                              });
                            }}
                          >
                            <MdEdit size={20} />
                          </TableButton>
                        </Tippy>
                      ) : (
                        <Tippy
                          content='Desarquivar automação'
                          delay='275'
                          placement='bottom'
                        >
                          <TableButton
                            onClick={() => {
                              setOpenModalArchive(true);
                              setAutId(i.AUT_ID)
                              // setAutomacao({})
                            }}
                          >
                            <MdUnarchive size={22} />
                          </TableButton>
                        </Tippy>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </FunilWrapper>
    </>
  );
}

export default Automacoes;
