import React, { useState, useEffect } from 'react';
import CardSelectVistoria from '../../../components/CardSelectVistoria/CardSelectVistoria';
import './styles.css';
import axios from 'axios';

function Vistorias() {
  const [vistoriaSelecionada, setVistoriaSelecionada] = useState('');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  useEffect(() => {
    (async () => {
      await axios.get('https://kaminocrm.com.br:7778/parametros/configurarVistoria/', {
        headers: {
          ass_cod: ASS_COD
        }
      })
      .then((res) => {
        console.log(res);
        setVistoriaSelecionada(res.data.type.toLowerCase())
      })
      .catch((err) => {
        console.log(err);
      })
    })();
  }, [])

  const endorCard = {
    icon: 'endor',
    title: 'ENDOR',
    content:
      'Selecione essa opção para realizar o processo de vistorias pelo aplicativo Endor Vistoria e Antifraude',
  };
  const driveCard = {
    icon: 'drive',
    title: 'GOOGLE DRIVE',
    content:
      'Selecione essa opção para realizar o processo de vistorias pela pasta do Google Drive da associação',
  };
  return (
    <div className='tabela_descontos--container'>
      <h3>Gerenciar vistorias</h3>
      <div className='vistoria__options'>
        <CardSelectVistoria
          cardContent={endorCard}
          vistoriaSelecionada={vistoriaSelecionada}
          setVistoriaSelecionada={setVistoriaSelecionada}
        />
        {/* <CardSelectVistoria
          cardContent={driveCard}
          vistoriaSelecionada={vistoriaSelecionada}
          setVistoriaSelecionada={setVistoriaSelecionada}
        /> */}
      </div>
    </div>
  );
}

export default Vistorias;
