import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingScreen } from '../../pages/Loading/styledLoading';
import { styled } from '@mui/material/styles';

export const LoadingNumbered = ({ progress }) => {

  const [number, setNumber] = useState(0)

  useEffect(() => {
    setNumber(progress)
  }, [progress])

  return (
    <LoadingScreen>
      <div
        style={{
          display: "block",
          margin: "10px",
        }}
      >
        <CircularProgress 
          variant='determinate' 
          value={number} 
          size={27}
          sx={{ color: "#fff" }}
        /> 
      </div>
      <p style={{
        display: "block"
      }}>Carregando... {number}%</p>
    </LoadingScreen>
  )
}
