import styled from "styled-components";
import { COLORS } from "../../../colors";

export const TopNavbar = styled.div`
  display: flex;
  padding: 0;
  /* background-color: #7d7c83; */
  background-color: ${COLORS.primary};
  border-bottom: 1px solid #e0dfe9;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  height: 25px;

  h5{
    display: flex;
    align-items: center;
  }

  ul li {
    list-style-type: none;
    display: inline-block;
  }
`;

export const Notificacao = styled.a`
  padding-right: 10px;
  color: ${COLORS.light};

  :hover {
    color: ${COLORS.primary};
    cursor: pointer;
  }
  :focus{
    outline: none;
  }

 
`;

export const Configuracao = styled.button`
  margin-right: 10px;
  background-color: transparent;
  border: none;
  color: ${COLORS.light};

  :hover {
    color: ${COLORS.primaryHover};
    cursor: pointer;
  }
  :focus{
    outline: none;
  }

  @media only screen and (max-width: 400px){
    padding: 0;
  }
`;

export const LogOut = styled.a`
  margin-right: 10px;
  color: ${COLORS.light};
  background-color: none;

  :hover {
    color: ${COLORS.primaryHover};
    cursor: pointer;
  }
  :focus{
    outline: none;
  }
`;

export const BottomNavbar = styled.div`
  display: flex;
  background-color: ${COLORS.light};
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.103);
  text-align: right;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;

  ul {
    display: flex;
    margin: 0;
    padding-right: 10px;
    align-items: center;
    padding-bottom: 20px;
    list-style-type: none;

    @media (max-width: 700px) {
    display: none;
  }
  }

  ul li {
    /* display: flex; */
    padding: 20px 10px 0px 10px;
    flex-wrap: wrap;
    flex-shrink: 1;
  
  }

  ul li a {
    text-decoration: none;
    font-weight: 900;
    color: ${COLORS.dark};
    font-size: 16px;
    /* text-transform: uppercase; */
    /* color: #57546b; */
    /* text-shadow: 0px 0px 2px #57546b41; */
  }

  ul li a:hover {
    color: ${COLORS.primary};
  }

  span img {
    /* height: 70px;
    padding-top: 5px;
    margin-left: 10px; */
    height: 2rem;
    padding-top: 5px;
    margin-left: 10px;
  }
`;

export const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: ${COLORS.dark};
  font-size: 35px;
  cursor: pointer;
  @media (min-width: 700px) {
    display: none;
  }
`

export const ResponsiveNav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 999;
  background-color: ${COLORS.light};
  /* background-color: salmon; */
  width: 100%;
  

  ul {
    display: flex;
    margin: 0;
    padding-right: 10px;
    align-items: center;
    padding-bottom: 20px;
    list-style-type: none;
    flex-direction: column;
  }
  

  ul li {
    /* display: flex; */
    padding: 20px 10px 0px 10px;
    flex-wrap: wrap;
    flex-shrink: 1;
        text-align: justify;

  }

  ul li a {
    text-decoration: none;
    font-weight: bold;
    color: ${COLORS.dark};
    font-size: 18px;
    /* text-transform: uppercase; */
    /* color: #57546b; */
    /* text-shadow: 0px 0px 2px #57546b41; */
  }

  ul li a:hover {
    color: ${COLORS.primary};
  }

  @media (min-width: 700px) {
    display: none;
  }
`

export const VersionFooter = styled.div`
  position: fixed;
  text-align: left;
  font-size: 12px;
  font-weight: bolder;
  left: 0;
  bottom: 0;
  width: 7%;
  padding: 0 5px;
  background-color: white;
  color: ${COLORS.labelDark};

  @media (max-width: 700px) {
    position: static;
    width: 40%;
  }
`