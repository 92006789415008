import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TarefaOportunidade,
  CriarNovaTarefa,
  FormTarefa,
} from "./styledNovaTarefa";
import { FaPlus } from "react-icons/fa";
import { BsCalendarWeek } from "react-icons/bs";
import Loading from "../../../../../../Loading/Loading.jsx";
import Calendar from "react-calendar";
import "./calendar.css";
import {
  ButtonSalvarSenha,
  ButtonConfirmar,
} from "../../../../Perfil/styledPerfil";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { BiCheck } from "react-icons/bi";
import moment from "moment";

function NovaTarefa({ OPO_ID }) {
  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(true);
  const [formTarefa, setFormTarefa] = useState(false);
  const [calendario, setCalendario] = useState(false);
  const [tiposTarefa, setTiposTarefa] = useState([]);

  //DADOS
  const [tarefaId, setTarefaId] = useState("");
  const [tarefaDescricao, setTarefaDescricao] = useState("");
  const [data, setData] = useState(new Date());
  const [horaMarcada, setHoraMarcada] = useState("00");
  const [minutoMarcado, setMinutoMarcado] = useState("00");
  const [obs, setObs] = useState("");

  const [tarefasExistem, setTarefasExistem] = useState([]);
  const [foo, setFoo] = useState(false);

  const horas = [...Array(24).keys()];
  const minutos = [...Array(60).keys()];

  useEffect(() => {
    axios
      .get("https://kaminocrm.com.br:7778/oportunidades/listarTiposTarefas", {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setTiposTarefa(res.data.tarefas);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(async () => {
    await axios
      .get(
        `https://kaminocrm.com.br:7778/oportunidades/listar-oportunidade/${OPO_ID}`,
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        },
        { timeout: 5 * 1000 }
      )
      .then((res) => {
        setTarefasExistem(res.data[0].TAREFAS);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [foo]);

  function feedback(msg, type) {
    if (type === "success") {
      toast.success(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === "error") {
      toast.error(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if(type === "warn"){
      toast.warn(`${msg}`, {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.info("Internal server error, X-121_06", {
        position: "top-right",
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function criarTarefa() {
    setLoading(true);
    axios
      .post(
        "https://kaminocrm.com.br:7778/oportunidades/criarTarefaOportunidade",
        {
          OPO_ID: OPO_ID,
          TAREFA_ID: tarefaId,
          DATA_MARCADA: `${data.toLocaleDateString(
            "en-US"
          )} ${horaMarcada}:${minutoMarcado}`,
          TAREFA_DESCRICAO: tarefaDescricao,
          TAREFA_OBSERVACAO: obs,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        feedback("Tarefa criada com sucesso!", "success");
        setTimeout(function () {
          setLoading(false);
        }, 1800);
        if (!loading) {
          setFormTarefa(false);
          setFoo(!foo);
        }
      })
      .catch((err) => {
        console.log(err);
        feedback("Ops! Algo deu errado.", "error");
        setTimeout(function () {
          setLoading(false);
        }, 1400);
      });
  }

  function realizarTarefa(task, status) {
    setLoading(true);
    axios
      .post(
        "https://kaminocrm.com.br:7778/oportunidades/registarTarefaRealizada",
        {
          TAREFAOPO_ID: task,
          STATUS: status,
        },
        {
          headers: {
            Authorization: `Bearer ${JWT_TOKEN}`,
            user_login: LOGIN,
            ass_cod: ASS_COD,
          },
        }
      )
      .then((res) => {
        if(status == "NAO REALIZADA"){
        feedback("Tarefa alterada para não realizada.", "warn");
      } else if(status === "REALIZADA"){
        feedback("Tarefa realizada com sucesso!", "success");
      }
        setTimeout(function () {
          setLoading(false);
        }, 1800);
        if (!loading) {
          setFormTarefa(false);
          setFoo(!foo);
        }
      })
      .catch((err) => {
        console.log(err);
        feedback("Ops! Algo deu errado.", "error");
        setTimeout(function () {
          setLoading(false);
        }, 1400);
      });
  }

  return (
    <TarefaOportunidade>
      <ToastContainer />
      {loading && <Loading />}
      <strong>Tarefas registradas:</strong>

      {tarefasExistem ? (
        tarefasExistem.map((i) => {
          return (
            <div className="card_tarefa" style={{ borderLeft: `4px solid ${i.STATUS === "NAO REALIZADO" || i.STATUS === "NAO REALIZADA" ? "#E8A325" : "#0FAB71"}` }}>
              {/* <div className="header">
                <input type="checkbox" name="realizada" id="realizada" /> <label htmlFor="realizada">Tarefa realizada</label>
              </div> */}

              <div className="body">
                <div style={{ fontSize: "15px" }}>
                  <strong>{i.TAREFA_DESCRICAO}</strong>
                </div>

                <div className="tarefa_item">
                  <strong>Tipo:</strong> <span>{i.TAREFA_NOME}</span> |{" "}
                  <strong>Status:</strong>
                  <span>
                    {" "}
                    {i.STATUS === "NAO REALIZADO" || i.STATUS === "NAO REALIZADA" ? "NÃO REALIZADA" : i.STATUS}
                  </span>
                </div>

                <div className="tarefa_item">
                  <strong>Data de criação:</strong>{" "}
                  <span>{moment(i.DATA_CRIADA).format("DD/MM/YYYY")}</span> |{" "}
                  <strong>Data marcada:</strong>{" "}
                  <span>{moment(i.DATA_MARCADA).format("DD/MM/YYYY")}</span>
                </div>

                <div className="tarefa_item">
                  <strong>Observações:</strong>{" "}
                  <span>
                    {i.TAREFA_OBSERVACAO ? i.TAREFA_OBSERVACAO : "--"}
                  </span>
                </div>
              </div>
              <div className="header">
                <input
                  type="checkbox"
                  checked={i.STATUS === "REALIZADA" ? true : false}
                  name="realizada"
                  id="realizada"
                  onChange={() => {
                    realizarTarefa(
                      i.TAREFAOPO_ID,
                      i.STATUS === "REALIZADA" ? "NAO REALIZADA" : "REALIZADA"
                    )
                  }}
                />{" "}
                <label htmlFor="realizada">Marcar como realizada</label>
              </div>
              {/* <div className="footer">
                  <ButtonConfirmar
                     onClick={() => {
                       realizarTarefa(i.TAREFAOPO_ID);
                    }}
                   >
                     <BiCheck size={20} />
                     Tarefa realizada
                   </ButtonConfirmar>
                </div>
              */}
            </div>
          );
        })
      ) : (
        <p>Você ainda não criou nenhuma tarefa para essa oportunidade.</p>
      )}

      <CriarNovaTarefa onClick={() => setFormTarefa(!formTarefa)}>
        <span className="icon">
          <FaPlus size={13} />
        </span>
        Criar nova tarefa
      </CriarNovaTarefa>

      {formTarefa && (
        <FormTarefa>
          <div className="input_flex">
            <div className="wrapper_1">
              <label htmlFor="tipo">Tipo</label>
              <select
                name="tipo"
                id="tipo"
                onChange={(e) => {
                  setTarefaId(e.target.value);
                }}
              >
                <option value="null">Selecione...</option>
                {tiposTarefa.map((i) => {
                  return (
                    <option key={i.TAREFA_ID} value={i.TAREFA_ID}>
                      {i.TAREFA_NOME}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="wrapper_2">
              <label htmlFor="descricao">Descrição</label>
              <input
                type="text"
                name="descricao"
                id="descricao"
                value={tarefaDescricao}
                onChange={(e) => {
                  setTarefaDescricao(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="input_flex">
            <div className="wrapper">
              <label htmlFor="data">Data</label>
              <div className="calendario-wrapper">
                <input
                  type="text"
                  className="calendario_input"
                  value={data.toLocaleDateString("pt-BR")}
                  readOnly
                />
                <button
                  className="button_calendario"
                  onClick={() => {
                    setCalendario(!calendario);
                  }}
                >
                  <BsCalendarWeek size={19} />
                </button>
              </div>

              {calendario && (
                <div className="calendario">
                  <Calendar
                    onChange={(v) => {
                      setData(v);
                      setCalendario(false);
                    }}
                    value={data}
                  />
                </div>
              )}
            </div>
            <div className="wrapper">
              <label htmlFor="hora">Hora</label>
              <div className="hora_wrapper">
                <select
                  name="hora"
                  id="hora"
                  onChange={(e) => {
                    setHoraMarcada(e.target.value);
                  }}
                >
                  {horas.map((i) => {
                    return (
                      <option
                        value={i.toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      >
                        {i.toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </option>
                    );
                  })}
                </select>
                <span>:</span>
                <select
                  name="minutos"
                  id="minutos"
                  onChange={(e) => {
                    setMinutoMarcado(e.target.value);
                  }}
                >
                  {minutos.map((i) => {
                    return (
                      <option
                        value={i.toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      >
                        {i.toLocaleString("en-US", {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="input_flex">
            <div className="wrapper_3">
              <label htmlFor="obs">Observações</label>
              <textarea
                name="obs"
                id="obs"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="footer">
            <ButtonSalvarSenha
              onClick={() => {
                criarTarefa();
              }}
            >
              Salvar tarefa
            </ButtonSalvarSenha>
          </div>
        </FormTarefa>
      )}
    </TarefaOportunidade>
  );
}

export default NovaTarefa;
