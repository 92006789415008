import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import {
  BackgroundModal,
  ModalBoxCliente,
  ModalHeader,
  X,
  BtnSubmit,
  Select as Select2,
} from '../../Home/HomeRoutes/Oportunidades/Modal/style/styledModal';
import { Input } from '../../Login/style/styledLogin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Loading from '../../Loading/Loading';

const CadastroUsuarios = ({ closeModal }) => {
  const INITIAL_VALUES = {
    nomeUser: '',
    loginUser: '',
    senhaUser: '',
    perId: '',
    usrMudarSenha: false,
    venId: null,
  };
  
  const [dataUser, setDataUser] = useState(INITIAL_VALUES);

  // erros
  const [erroNome, setErroNome] = useState(false);
  const [erroLogin, setErroLogin] = useState(false);
  const [erroSenha, setErroSenha] = useState(false);
  const [erroPerfil, setErroPerfil] = useState(false);
  const [erroNomeUserExiste, setErroNomeUserExiste] = useState(false);

  const [selectVendedores, setSelectVendedores] = useState(Boolean);

  const [loading, setLoading] = useState(false);

  function usuarioCriado() {
    toast.success('Usuário criado com sucesso!', {
      position: 'top-right',
      autoClose: 2200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function usuarioErro(msg) {
    toast.error(`${msg}`, {
      position: 'top-right',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Fui enviado!");

    setLoading(true);

    if (!dataUser.nomeUser) {
      setErroNome(true);
    }
    if (dataUser.loginUser.length > 10) {
      setErroLogin(true);
    }
    if (!dataUser.loginUser) {
      setErroLogin(true);
    }
    if (!dataUser.senhaUser) {
      setErroSenha(true);
    }
    if (!dataUser.perId) {
      setErroPerfil(true);
    }

    const LOGIN = sessionStorage.getItem('LOGIN');
    const JWT_TOKEN = sessionStorage.getItem('JWT');
    const ASS_COD = sessionStorage.getItem('ASS_COD');

    const DADOS = {
      USR_NOME: dataUser.nomeUser,
      USR_LOGIN: dataUser.loginUser,
      USR_SENHA: dataUser.senhaUser,
      PER_ID: dataUser.perId,
      USR_MUDAR_SENHA: dataUser.usrMudarSenha,
      VEN_ID: dataUser.venId,
    };

    axios
      .post('https://kaminocrm.com.br:5001/usuario/cadastrar', DADOS, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        // console.log(res, "Post enviado!");
        usuarioCriado();
        setTimeout(function () {
          setLoading(false);
        }, 2100);
        if (!loading) {
          window.location.reload();
        }
        // closeModal()
      })
      .catch((err) => {
        // usuarioErro()
        setLoading(false);
        const { data } = err.response;
        if (
          data.message ===
          `error: duplicate key value violates unique constraint "USUARIO_USR_LOGIN_key"`
        ) {
          setErroNomeUserExiste(true);
        } else if (data.statusPersonalizado == 401) {
          usuarioErro(data.message);
        }
      });
  };

  const [ven, setVen] = useState([]);

  useEffect(() => {
    axios
      .get(`https://kaminocrm.com.br:7778/vendedores/buscarVendedores`, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        var dataSelect = res.data.map((el) => {
          return {
            value: el.id,
            label: el.nome,
          };
        });
        setVen(dataSelect);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  function handleDisabled() {
    const { nomeUser, loginUser, senhaUser, perId, venId } = dataUser;

    if (
      nomeUser !== '' &&
      loginUser !== '' &&
      senhaUser !== '' &&
      perId !== ''
    ) {
      return perId === '0' && venId === null;
    }

    return true;
  }

  return (
    <BackgroundModal>
      {loading && <Loading />}
      <ToastContainer />
      <ModalBoxCliente>
        <ModalHeader>
          <h2>Cadastro de usuário</h2>

          <X onClick={() => closeModal(false)}>
            <FaTimes size={17} />
          </X>
        </ModalHeader>

        <div className='modalBody'>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <label htmlFor='USR_NOME'>Nome de usuário:</label>
            <Input
              type='text'
              name='USR_NOME_CAD'
              id='USR_NOME_CAD'
              value={dataUser.nomeUser}
              onChange={(e) => {
                setDataUser((prev) => ({
                  ...prev,
                  nomeUser: e.target.value,
                }));
              }}
              style={{
                width: '95%',
                // textTransform: "uppercase"
              }}
              autoComplete='false'
              placeholder='Nome completo do novo usuário...'
            ></Input>
            {erroNome && (
              <p
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  marginTop: '-4px',
                  fontSize: '12px',
                }}
              >
                Insira um nome válido!
              </p>
            )}

            <label htmlFor='USR_LOGIN'>Login:</label>
            <Input
              type='text'
              name='USR_LOGIN_CAD'
              id='USR_LOGIN_CAD'
              value={dataUser.loginUser.toUpperCase()}
              onChange={(e) => {
                setDataUser((prev) => ({
                  ...prev,
                  loginUser: e.target.value,
                }));
              }}
              style={{
                width: '95%',
              }}
              autoComplete='false'
              placeholder='Deve possuir menos que 10 caracteres...'
            ></Input>
            {erroLogin && (
              <p
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  marginTop: '-4px',
                  fontSize: '12px',
                }}
              >
                Insira um login válido! (Menos que 10 caracteres)
              </p>
            )}
            {erroNomeUserExiste && (
              <p
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  marginTop: '-4px',
                  fontSize: '12px',
                }}
              >
                Este nome de usuário já está em uso!
              </p>
            )}
            {/* senhas */}
            <div
              style={{
                display: 'block',
              }}
            >
              <label htmlFor='USR_SENHA'>Senha:</label>
              <Input
                type='password'
                name='USR_SENHA_CAD'
                id='USR_SENHA_CAD'
                value={dataUser.senhaUser}
                onChange={(e) => {
                  setDataUser((prev) => ({
                    ...prev,
                    senhaUser: e.target.value,
                  }));
                }}
                style={{
                  width: '95%',
                  // width: "318px"
                }}
              ></Input>

              {erroSenha && (
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    marginTop: '-4px',
                    fontSize: '12px',
                  }}
                >
                  Insira uma senha válida!
                </p>
              )}
            </div>

            {/* checkbox */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                paddingInline: '20px',
              }}
            >
              <input
                type='checkbox'
                name='USR_MUDAR_SENHA'
                id='USR_MUDAR_SENHA'
                value={dataUser.usrMudarSenha}
                onChange={() => {
                  setDataUser((prev) => ({
                    ...prev,
                    usrMudarSenha: !dataUser.usrMudarSenha,
                  }));
                }}
                style={{
                  marginRight: '5px',
                }}
              />
              <label
                htmlFor='USR_MUDAR_SENHA'
                style={{
                  fontWeight: 'none',
                  fontSize: '14px',
                }}
              >
                Mudar senha após primeiro login
              </label>
            </div>

            <div>
              <label
                style={{
                  marginRight: '5px',
                }}
              >
                Perfil de Usuário:
              </label>

              <input
                type='radio'
                value='0'
                name='PER_ID'
                onChange={(e) => {
                  setDataUser((prev) => ({
                    ...prev,
                    perId: e.target.value
                  }))
                  setSelectVendedores(true);
                }}
              />
              <label>VENDEDOR</label>

              <input
                type='radio'
                value='1'
                name='PER_ID'
                onChange={(e) => {
                  setDataUser((prev) => ({
                    ...prev,
                    perId: e.target.value,
                    venId: null
                  }))
                  setSelectVendedores(false);
                }}
              />
              <label>MASTER</label>

              {erroPerfil && (
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    marginTop: '3px',
                    fontSize: '12px',
                  }}
                >
                  Selecione o tipo de perfil do usuário!
                </p>
              )}

              {/* VENDEDORES */}

              {selectVendedores && (
                <>
                  <label
                    style={{
                      display: 'block',
                      marginTop: '30px',
                    }}
                  >
                    Vendedores disponíveis:
                  </label>
                  <Select
                    options={ven}
                    placeholder='Selecione...'
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue
                        ? 'Todas as opções foram selecionadas'
                        : 'Nenhum vendedor encontrado'
                    }
                    onChange={(e) =>
                      setDataUser((prev) => ({
                        ...prev,
                        venId: e.value
                      }))
                    }
                  />
                </>
              )}
            </div>

            <div
              className='modalFooter'
              style={{
                marginTop: '20px',
              }}
            >
              <button className='btnClose' onClick={() => closeModal(false)}>
                Fechar
              </button>
              <BtnSubmit type='submit' disabled={handleDisabled()}>
                Avançar
              </BtnSubmit>
            </div>
          </form>
        </div>
      </ModalBoxCliente>
    </BackgroundModal>
  );
};

export default CadastroUsuarios;
