import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ModalCliente from './Modal/ModalCliente';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaFilter } from 'react-icons/fa';
import { BsFillFilterCircleFill } from 'react-icons/bs';
import { RiBarChartHorizontalFill } from 'react-icons/ri';
import { MdTableRows } from 'react-icons/md';
import DataTable from './Data Table/DataTable';
import Kanban from './Kanban/Kanban';
import {
  Container,
  SwitchBotoes,
  FunisBotoes,
} from './styles/styledOportunidades';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './styles/ClienteOp.css';
import Loading from '../../../Loading/Loading';
import { BtnSubmit } from './Modal/style/styledModal';
import { AuthContext } from '../../../../context/auth';
import FilterModal from './FilterModal/FilterModal';
import { CadastroVeiculo } from '../../../../context/CadastroVEIContext';
import { handleClose } from '../../../../services/handleClose';

const Oportunidades = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const [funis, setFunis] = useState([]);
  const [funilNome, setFunilNome] = useState('');
  // const [funilAtual, setFunilAtual] = useState("");

  const navigate = useNavigate();

  const { PER_ID, FUN_ID, setFUN_ID } = useContext(AuthContext);
  const { setValorTotalImplementos, setImplementos } =
    useContext(CadastroVeiculo);
  const [filterStatus, setFilterStatus] = useState('TODAS');

  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');
  const VEN_ID = sessionStorage.getItem('VEN_ID');
  const FUN_NOME = sessionStorage.getItem('FUN_NOME');

  const MySwal = withReactContent(Swal);

  function checarStatus() {
    axios
      .get(`https://kaminocrm.com.br:7778/status/verificarStatus/`, {
        headers: {
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        setOpenModal(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        MySwal.fire({
          title: 'Atenção!',
          text: 'Serviço indisponível no momento. Por favor, tente mais tarde.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        }).then((res) => {
          if (res) {
            navigate('/home/oportunidades');
          } else {
            // not clicked
          }
        });
      });
  }

  const funilAtual = sessionStorage.getItem('FUN_ID');

  function getFunNome(arr) {
    const fun = arr.find(function (funil) {
      return funil.value === FUN_ID;
    });
    setFunilNome(fun?.label);
  }

  useEffect(() => {
    sessionStorage.setItem('FUN_ID', FUN_ID);
    if (!funilNome) {
      setFunilNome(FUN_NOME);
    } else {
      sessionStorage.setItem('FUN_NOME', funilNome);
    }
    // getFunNome();
  }, [FUN_ID]);

  useEffect(() => {
    (async () => {
      setValorTotalImplementos(0);
      setImplementos([]);
      handleClose();
      await axios
        .get(
          `https://kaminocrm.com.br:7778/oportunidades/listarFunisOportunidade?VEN_ID=${VEN_ID}`,
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          setFunis(
            res.data.funis.map((e) => {
              return {
                value: e.FUN_ID,
                label: e.FUN_NOME,
              };
            })
          );
          if (funilAtual == '' || !funilAtual) {
            setFUN_ID(res.data.funis[0].FUN_ID);
          } else if (FUN_ID == '') {
            setFUN_ID(funilAtual);
          }
          getFunNome(funis);
          if (!funilNome) {
            setFunilNome(FUN_NOME);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    })();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {openModal && <ModalCliente closeModal={setOpenModal} />}

      <Container>
        {/* <div className="opHeader" style={{ marginTop: "1rem" }}>
          <h2></h2>

          {PER_ID == 0 ? (
            <BtnSubmit
              to="cadastro-cliente"
              onClick={() => {
                checarStatus();
                setLoading(true);
              }}
              style={{ padding: "7px 10px" }}
              className="modalButton"
            >
              <FaPlus size={11} /> Nova Oportunidade
            </BtnSubmit>
          ) : (
            ""
          )}
        </div> */}

        {/* <div className="resOpHeader">
          {PER_ID == 0 ? (
            <BtnSubmit
              to="cadastro-cliente"
              onClick={() => {
                checarStatus();
                setLoading(true);
              }}
              style={{ padding: "7px 10px" }}
            >
              <FaPlus size={11} /> Nova Oportunidade
            </BtnSubmit>
          ) : (
            ""
          )}
        </div> */}

        {filterModal && (
          <FilterModal
            setFilterModal={setFilterModal}
            funis={funis}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            setFunilNome={setFunilNome}
          />
        )}

        <SwitchBotoes>
          <h2 className='Title__Opo'>FUNIS</h2>
          <button
            className={tableView ? 'active' : ''}
            onClick={() => {
              setTableView(true);
            }}
          >
            <span>
              <RiBarChartHorizontalFill
                size={13}
                style={{ transform: 'rotate(90deg)' }}
              />
            </span>
          </button>

          <button
            className={!tableView ? 'active' : ''}
            onClick={() => {
              setTableView(false);
            }}
          >
            <span>
              <MdTableRows size={13} />
            </span>
          </button>
        </SwitchBotoes>

        <FunisBotoes>
          <div className='container'>
            <button className='btn_filter' onClick={() => setFilterModal(true)}>
              <BsFillFilterCircleFill size={18} />
            </button>
            <span onClick={() => setFilterModal(true)}>
              {funilNome ? funilNome : 'FUNIL PADRÃO'}
            </span>
            <span onClick={() => setFilterModal(true)}>
              {filterStatus === 'TODAS' || filterStatus === 'CONVERTENDO'
                ? filterStatus
                : `${filterStatus}S`}
            </span>
          </div>

          {PER_ID == 0 ? (
            <BtnSubmit
              to='cadastro-cliente'
              onClick={() => {
                checarStatus();
                setLoading(true);
              }}
              style={{ padding: '7px 10px' }}
              className='modalButton'
            >
              <FaPlus size={11} /> Nova Oportunidade
            </BtnSubmit>
          ) : (
            ''
          )}
        </FunisBotoes>

        {tableView ? (
          <Kanban FUN_ID={FUN_ID} filterStatus={filterStatus} />
        ) : (
          <DataTable FUN_ID={FUN_ID} filterStatus={filterStatus} />
        )}
      </Container>
    </>
  );
};

export default Oportunidades;
