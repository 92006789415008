import React, { useState } from "react";
import axios from "axios";
import {
  BackgroundModal,
  ModalBoxConfirmar,
  ModalHeader,
  X
} from "../../Modal/style/styledModal";
import Loading from "../../../../../Loading/Loading.jsx"
import { ButtonEncerrar } from "../../Data Table/ResumoTable/styledResumoTable";

import { IoMdClose } from "react-icons/io";

function ModalConfirmarPerdida({ closeModal, OPO_ID, OPO_STATUS }) {

  const LOGIN = sessionStorage.getItem("LOGIN");
  const JWT_TOKEN = sessionStorage.getItem("JWT");
  const ASS_COD = sessionStorage.getItem("ASS_COD");

  const [loading, setLoading] = useState(false)

  const encerrarOportunidade = () => {
    axios
      .post("https://kaminocrm.com.br:7778/oportunidades/update-oportunidade", {
        "OPO_ID": OPO_ID,
        "OPO_STATUS": OPO_STATUS
      }, {
        headers: {
          Authorization: `Bearer ${JWT_TOKEN}`,
          user_login: LOGIN,
          ass_cod: ASS_COD,
        },
      })
      .then((res) => {
        console.log(res)
        closeModal(false)
        window.location.reload();
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <Loading />}
      <BackgroundModal>
        <ModalBoxConfirmar>
          <ModalHeader>
            <h2>Converter status de oportunidade</h2>

            <X onClick={() => {
              closeModal(false)
            }}>
              <IoMdClose size={17} />
            </X>
          </ModalHeader>

          <div className="modalBody">
            <p>Ao confirmar esta ação, o status desta oportunidade será permanentemente definido para <strong style={{ color: "#dc1c4c" }}>PERDIDA</strong>.</p>
            <p>Deseja prosseguir?</p>

            <div className="modalFooter" style={{ gap: "0.5rem" }}>
              <button className="btnClose" onClick={() => {
                closeModal(false)
                setLoading(true)
                }}
                >
                Cancelar
              </button>
              <ButtonEncerrar onClick={() => encerrarOportunidade()}>
                Encerrar oportunidade
              </ButtonEncerrar>
            </div>
          </div>
        </ModalBoxConfirmar>
      </BackgroundModal>
    </>
  );
}

export default ModalConfirmarPerdida;
