import React, { useState, useEffect } from 'react';
import MenuTemplate from './MenuTemplate';
import IconClose from '../../../Home/assets/icon-close.svg';
import FunilSelect from './AutomacaoSteps/FunilSelect/FunilSelect';
import AutomacaoConfig from './AutomacaoSteps/AutomacaoConfig/AutomacaoConfig';
import './styles.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import ConfirmarArquivar from '../ConfirmarArquivar/ConfirmarArquivar';

function CreateAutomacao({ setOpenModal, funis, automacao, setAutomacao }) {
  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  const [step, setStep] = useState(1);
  const [columnsFunil, setColumnsFunil] = useState([]);
  const [openModalArchive, setOpenModalArchive] = useState(false);


  useEffect(() => {
    (async () => {
      if (automacao.FUN_ID !== '') {
        axios
          .get(
            `https://kaminocrm.com.br:7778/parametros/buscarDadosFunil/${automacao.FUN_ID}`,
            {
              headers: {
                Authorization: `Bearer ${JWT_TOKEN}`,
                user_login: LOGIN,
                ass_cod: ASS_COD,
              },
            }
          )
          .then((res) => {
            setColumnsFunil(
              res.data.map((el) => {
                return {
                  value: el.KANBAN_NOME,
                  label: el.KANBAN_NOME.toUpperCase(),
                };
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();
  }, [automacao.FUN_ID]);

  const optionsStatus = [
    {
      label: 'PENDENTE',
      value: 'PENDENTE',
    },
    {
      label: 'PERDIDA',
      value: 'PERDIDA',
    },
    {
      label: 'CONVERTENDO',
      value: 'CONVERTENDO',
    },
    {
      label: 'CONVERTIDA',
      value: 'CONVERTIDA',
    },
  ];

  function feedback(msg, type) {
    if (type === 'success') {
      toast.success(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'error') {
      toast.error(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (type === 'warn') {
      toast.warn(`${msg}`, {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error('Internal server error', {
        position: 'top-right',
        autoClose: 2200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function handleAutomacao() {
    const requestUrl = automacao.AUT_ID !== ''
        ? 'https://kaminocrm.com.br:7778/parametros/editarAutomacao/'
        : 'https://kaminocrm.com.br:7778/parametros/criarAutomacao/'

    await axios.post(requestUrl, automacao,
    {
      headers: {
        Authorization: `Bearer ${JWT_TOKEN}`,
        user_login: LOGIN,
        ass_cod: ASS_COD,
      },
    })
    .then((res) => {
      feedback(res.data.mensagem, 'success')
      setAutomacao({
        AUT_ID: '',
        AUT_NAME: '',
        FUN_ID: '',
        INFOS: [],
        AUT_TRIGGER: '',
      });
      setOpenModal(false)
    })
    .catch((error) => feedback(error.response.data.message, 'error'))
  }

  function renderSteps() {
    switch (step) {
      case 1:
        return (
          <FunilSelect
            funis={funis}
            automacao={automacao}
            setAutomacao={setAutomacao}
          />
        );
      case 2:
        return (
          <AutomacaoConfig
            columnsFunil={columnsFunil}
            optionsStatus={optionsStatus}
            automacao={automacao}
            setAutomacao={setAutomacao}
          />
        );
      default:
        return 'Passo inválido';
    }
  }

  const handleDisabled = () => {
    const { AUT_NAME, FUN_ID, AUT_TRIGGER, INFOS } = automacao;

    if (step === 1) {
      return (AUT_NAME === '' || FUN_ID === '' || AUT_TRIGGER === '');
    } else if (step === 2) {
      return INFOS.length <= 0
    }
  }

  return (
    <div className='bg_add Main__Fade'>
      {openModalArchive && (
        <ConfirmarArquivar
          setOpenModalArchive={setOpenModalArchive}
          setOpenModalCreate={setOpenModal}
          AUT_ID={automacao.AUT_ID}
        />
      )}
      <div className='templates_create_container'>
        <div className='modal__templates'>
          <div className='close__modal'>
            <img
              src={IconClose}
              alt='close'
              onClick={() => {
                setOpenModal(false);
                setColumnsFunil([]);
                setAutomacao({
                  AUT_ID: '',
                  AUT_NAME: '',
                  FUN_ID: '',
                  INFOS: [],
                  AUT_TRIGGER: '',
                });
              }}
            />
          </div>

          <div className='templates__content'>
            <MenuTemplate step={step} automacaoId={automacao.AUT_ID} />
            <div className='templates__box'>
              {renderSteps()}
              <div className='btn__container'>
                {step === 2 && (
                  <button className='btn_voltar' onClick={() => setStep(1)}>
                    Voltar
                  </button>
                )}
                {automacao.AUT_ID !== '' && (
                  <button
                    className='btn_arquivar'
                    onClick={() => {
                      setOpenModalArchive(true)
                    }}
                  >
                    Arquivar
                  </button>
                )}
                <button
                  className='btn_avancar'
                  disabled={handleDisabled()}
                  onClick={() => {
                    step === 1 ? setStep(2) : handleAutomacao();
                  }}
                >
                  {step === 1 ? 'Avançar' : 'Salvar'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAutomacao;
