import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';

function ActionItem({
  item,
  index,
  trigger,
  handleInfoAction,
  removeAction,
  columnsFunil,
  optionsStatus,
}) {
  const [action, setAction] = useState({
    ACTION_TIPO: item.ACTION_TIPO ? item.ACTION_TIPO : '', // MESSAGE (mensagem), MOVE (mover entre colunas), ALTER (mudar status)
    MESSAGE_ID: item.MESSAGE_ID ? item.MESSAGE_ID : '',
    COLUNA_NOME: item.COLUNA_NOME ? item.COLUNA_NOME : '',
    STATUS_NOME: item.STATUS_NOME ? item.STATUS_NOME : '',
  });
  const [optionsDestiny, setOptionsDestiny] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const LOGIN = sessionStorage.getItem('LOGIN');
  const JWT_TOKEN = sessionStorage.getItem('JWT');
  const ASS_COD = sessionStorage.getItem('ASS_COD');

  console.log('trigger', trigger);

  useEffect(() => {
    (async () => {
      await axios
        .get(
          'https://kaminocrm.com.br:7778/parametros/listarMensagemTemplates',
          {
            headers: {
              Authorization: `Bearer ${JWT_TOKEN}`,
              user_login: LOGIN,
              ass_cod: ASS_COD,
            },
          }
        )
        .then((res) => {
          setTemplatesList(
            res.data.dados.map((el) => {
              return {
                value: el.MENSAGEM_ID,
                label: `${el.TIPO_NAME} | ${el.MENSAGEM_TITULO}`,
              };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  useEffect(() => {
    if (trigger === 'STATUS') {
      setTypeOptions([
        {
          label: 'Enviar Mensagem',
          value: 'MESSAGE',
        },
        {
          label: 'Mover Oportunidade',
          value: 'MOVE',
        },
      ]);
    } else if (trigger === 'ETAPA') {
      setTypeOptions([
        {
          label: 'Enviar Mensagem',
          value: 'MESSAGE',
        },
        {
          label: 'Alterar Status',
          value: 'ALTER',
        },
      ]);
    } else if (trigger === 'OPORTUNIDADE') {
      setTypeOptions([
        {
          label: 'Enviar Mensagem',
          value: 'MESSAGE',
        },
      ]);
    } else {
      setTypeOptions([
        {
          label: 'Enviar Mensagem',
          value: 'MESSAGE',
        },
        {
          label: 'Mover Oportunidade',
          value: 'MOVE',
        },
        {
          label: 'Alterar Status',
          value: 'ALTER',
        },
      ]);
    }
  }, [trigger]);

  useEffect(() => {
    if (action.ACTION_TIPO === 'MESSAGE') {
      setOptionsDestiny(templatesList);
    } else if (action.ACTION_TIPO === 'MOVE') {
      setOptionsDestiny(columnsFunil);
    } else if (action.ACTION_TIPO === 'ALTER') {
      setOptionsDestiny(optionsStatus);
    }
  }, [action, templatesList]);

  function handleChange(name, value) {
    setAction((prev) => ({
      ...prev,
      [name]: value,
    }));

    const copyAction = {
      ...action,
      [name]: value,
    };

    handleInfoAction(index, copyAction);
  }

  function handleTypeSelected(action_tipo, value) {
    if (action_tipo === 'MESSAGE') {
      handleChange('MESSAGE_ID', value);
    } else if (action_tipo === 'MOVE') {
      handleChange('COLUNA_NOME', value);
    } else if (action_tipo === 'ALTER') {
      handleChange('STATUS_NOME', value);
    }
  }

  function handleValue(options, action_tipo) {
    if (action_tipo === 'MESSAGE') {
      return options.find((el) => el.value === action?.MESSAGE_ID);
    } else if (action_tipo === 'MOVE') {
      return options.find(
        (el) => el.value.toUpperCase() === action.COLUNA_NOME?.toUpperCase()
      );
    } else if (action_tipo === 'ALTER') {
      return options.find(
        (el) => el.value.toUpperCase() === action.STATUS_NOME?.toUpperCase()
      );
    }
  }

  return (
    <div className='action_item'>
      <div className='action_item_form'>
        <div className='group_action group1'>
          <span>Tipo de Ação</span>
          <Select
            placeholder='Selecione...'
            options={typeOptions}
            value={typeOptions.find(
              (el) =>
                el.value.toUpperCase() === action.ACTION_TIPO?.toUpperCase()
            )}
            onChange={(e) => {
              console.log(e);
              handleChange('ACTION_TIPO', e.value);
            }}
          />
        </div>
        <div className='group_action group2'>
          <span>Modelo / Coluna / Status</span>
          <Select
            placeholder='Selecione...'
            options={optionsDestiny}
            value={handleValue(optionsDestiny, action.ACTION_TIPO)}
            onChange={(e) => handleTypeSelected(action.ACTION_TIPO, e.value)}
          />
        </div>
      </div>
      <button onClick={() => removeAction(item)} className='remove_action'>
        <IoClose size={22} />
      </button>
    </div>
  );
}

export default ActionItem;
